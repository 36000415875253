<template>
	<div>
		<b-button class="btn btn-create btn-sm" style="background: #51C1AD; border: 1px; height: 35px;"
			@click="openModalBuyingDownPayment" v-can="'JOB_ORDER_BUYING_CREATE'">
			<i class="mdi mdi-plus mr-1"></i>
			New Transaction
		</b-button>

		<b-button class="btn btn-create btn-sm ml-2" style="background: #51C1AD; border: 1px; height: 35px;"
			@click="openModalFavBuyingDownPayment" v-can="'JOB_ORDER_FAVORITE_ENABLE'">
			<i class="mdi mdi-bookmark"></i>
		</b-button>

		<b-modal id="modalBuyingDownPayment" size="xl" title="Create New Transaction" style="max-width: 90% !important;"
			@ok="submitBuying" ok-title="Submit" no-close-on-backdrop>
			<div>
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-2">
								<label for="">Number </label>
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingDownPayment" v-model="dataBuyingDownPayment.number"
										:reduce="(option) => option.id" :options="listAutonumber" label="name"
										style="width: 81%; float: left;">
									</v-select>
									<input v-show="isManualNumberBuyingDownPayment" v-model="dataBuyingDownPayment.numberText" type="text"
										class="form-control form-control-sm" placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuying">
											<i v-show="!isManualNumberBuyingDownPayment" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingDownPayment" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Vendor</label>
									<v-select v-model="dataBuyingDownPayment.vendor" :options="listVendor" :reduce="(option) => option.id"
										label="name" @input="changeVendor">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Term</label>
									<v-select v-model="dataBuyingDownPayment.term" taggable :reduce="(option) => option.id"
										:options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Currency</label>
									<v-select v-model="dataBuyingDownPayment.currency" taggable :options="listCurrency"
										:reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd)</label>
								<date-picker v-model="dataBuyingDownPayment.date" :format="momentFormat" type="date" lang="en"
									style="height: 31px;" :placeholder="'Select Date'" @input="getTgl">
								</date-picker>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Invoice No</label>
									<input v-model="dataBuyingDownPayment.invoice" type="text" class="form-control form-control-sm"
										placeholder="Invoice No" required />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Down Payment</label>
									<input v-model="dataBuyingDownPayment.downPayment" type="text" class="form-control form-control-sm"
										placeholder="Down Payment" required />
								</div>
							</div>
							<div class="col-md-1">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="taxable" v-model="dataBuyingDownPayment.taxable" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="taxable">Taxable</label>
								</div>
							</div>
							<div class="col-md-2" v-show="dataBuyingDownPayment.taxable === '1'">
								<div class="form-group">
									<label for="">PPN</label>
									<v-select v-model="dataBuyingDownPayment.ppn" :reduce="(option) => option.id" :options="listPercentage"
										label="description" @input="changePpn">
									</v-select>
								</div>
							</div>
							<div class="col-md-2"
								v-show="(dataBuyingDownPayment.ppn !== undefined && dataBuyingDownPayment.ppn > 0 && dataBuyingDownPayment.taxable === '1')">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="inclusiveTax" v-model="dataBuyingDownPayment.inclusiveTax" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="inclusiveTax">Inclusive Tax</label>
								</div>
							</div>
							<div class="col-md-2" v-show="(dataBuyingDownPayment.ppn !== undefined && dataBuyingDownPayment.ppn > 0)">
								<div class="form-group">
									<label for="">Document Code</label>
									<v-select v-model="dataBuyingDownPayment.docCode" :options="listDocCode"
										:reduce="(option) => option.code" label="text" @input="changeDocCode" item-text="text"
										item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-3"
								v-show="(dataBuyingDownPayment.ppn !== undefined && dataBuyingDownPayment.ppn > 0 && showTaxType === true)">
								<div class="form-group">
									<label for="">Tax Type</label>
									<v-select v-model="dataBuyingDownPayment.taxType" :options="listTaxTypeBuying"
										:reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Commercial Rate</label>
									<input v-model="dataBuyingDownPayment.commercialRate" type="text" class="form-control form-control-sm"
										placeholder="Commercial Rate" required />
								</div>
							</div>
							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Fiscal Rate</label>
									<input v-model="dataBuyingDownPayment.fiscalRate" type="text" class="form-control form-control-sm"
										placeholder="Fiscal Rate" required />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Description</label>
									<textarea v-model="dataBuyingDownPayment.description" class="form-control form-control-sm"
										placeholder="Description" cols="30" rows="3"></textarea>
								</div>
							</div>
							<!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataBuyingDownPayment.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
						</div>
					</div>
				</div>
				<!-- <div class="mt-3">
          <div v-for="(isiDetail, d) in itemBuying" :key="d" class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Item Description</label>
                <input v-model="isiDetail.itemDescription" type="text" class="form-control form-control-sm" placeholder="Item Description" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
			</div>
		</b-modal>
		<b-modal id="modalBuyingCopy" size="xl" title="Copy Transaction" style="max-width: 90% !important;"
			@ok="submitBuyingCopy" ok-title="Submit" no-close-on-backdrop>
			<div>
				<div class="container-downpayment">
					<img src="@assets/images/bank.png" class="img-fluid ml-2" alt style="width: 20px" />
					<span class="ml-3" style="color: #A6A5A5;font-size: 12px;">Down Payment :</span>
					<span class="ml-2" style="color: #585858;font-size: 12px;font-weight: bold;">{{
						formatRupiah(((this.dataSingleBuyingDownPayment.grandTotal
							=== undefined ? 0 : this.dataSingleBuyingDownPayment.grandTotal)).toString(), '') }}</span>
					<span class="ml-3" style="color: #A6A5A5;font-size: 12px;">Date :</span>
					<span class="ml-2" style="color: #585858;font-size: 12px;font-weight: bold;">{{
						dateFormating(this.dataSingleBuyingDownPayment.date) }}</span>
				</div>
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-2">
								<label for="">Number</label>
								<div class="input-group">
									<v-select v-show="!isManualNumberBuying" v-model="dataBuyingCopy.number" :reduce="(option) => option.id"
										:options="listAutonumber" label="name" style="width: 81%; float: left;">
									</v-select>
									<input v-show="isManualNumberBuying" v-model="dataBuyingCopy.numberText" type="text"
										class="form-control form-control-sm" placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuying">
											<i v-show="!isManualNumberBuying" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuying" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Vendor</label>
									<v-select v-model="dataBuyingCopy.vendor" :options="listVendor" :reduce="(option) => option.id"
										label="name" @input="changeVendorCopy" :disabled="true">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Term</label>
									<v-select v-model="dataBuyingCopy.term" taggable :reduce="(option) => option.id" :options="listTerm"
										label="name" item-text="name" item-value="id" @input="changeTerm" :disabled="true">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Currency</label>
									<v-select v-model="dataBuyingCopy.currency" taggable :options="listCurrency"
										:reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency"
										:disabled="true">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd)</label>
								<date-picker v-model="dataBuyingCopy.date" :format="momentFormatBuyingCopy" type="date" lang="en"
									style="height: 31px;" :placeholder="'Select Date'" @input="getTgl">
								</date-picker>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Invoice No</label>
									<input v-model="dataBuyingCopy.invoice" type="text" class="form-control form-control-sm"
										placeholder="Invoice No" required />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">PPN</label>
									<v-select v-model="dataBuyingCopy.ppn" :reduce="(option) => option.id" :options="listPercentage"
										label="description" @input="changePpnCopy">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
								<div class="form-group">
									<label for="">Document Code</label>
									<v-select v-model="dataBuyingCopy.docCode" :options="listDocCode" :reduce="(option) => option.code"
										label="text" @input="changeDocCodeCopy" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2"
								v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0 && showTaxType === true)">
								<div class="form-group">
									<label for="">Tax Type</label>
									<v-select v-model="dataBuyingCopy.taxType" :options="listTaxTypeBuying"
										:reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Commercial Rate</label>
									<input v-model="dataBuyingCopy.commercialRate" type="text" class="form-control form-control-sm"
										placeholder="Commercial Rate" required />
								</div>
							</div>
							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Fiscal Rate</label>
									<input v-model="dataBuyingCopy.fiscalRate" type="text" class="form-control form-control-sm"
										placeholder="Fiscal Rate" required />
								</div>
							</div>
							<!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataBuyingCopy.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
							<div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="inclusiveTax" v-model="dataBuyingCopy.inclusiveTax" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="inclusiveTax">Inclusive Tax</label>
								</div>
							</div>


						</div>
					</div>
				</div>
				<div class="mt-3">
					<div v-for="(isiDetail, d) in itemBuyingCopy" :key="d" class="row">
						<!-- <div class="row"> -->

						<div class="col-md-3">
							<div class="form-group">
								<label for="">Item</label>
								<select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true"
									placeholder="Choose an Item" required>
									<option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
										{{ isi.item_name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Qty</label>
								<input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty"
									required />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Price</label>
								<input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price"
									required />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Item Description</label>
								<input v-model="isiDetail.itemDescription" type="text" class="form-control form-control-sm"
									placeholder="Item Description" required />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0"
									class="form-check-input"
									style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;"
									@change="cekIsTruck(d)">
								<label for="includeTax" style="margin-bottom: 4px;">
									Truck
								</label>
								<select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm"
									data-live-search="true" placeholder="Choose an Truck" required>
									<option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
										{{ isi.truck_no }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-1 pt-1">
							<br>
							<a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm"
								@click="removeDetail(d)"> <i class="fas fa-trash"></i>
							</a> &nbsp;
							<a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm"
								@click="addDetail('ok')"><i class="fas fa-plus"></i></a>
							&nbsp;
						</div>
					</div>
				</div>

				<!-- <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
			</div>
		</b-modal>
		<b-modal id="modalBuyingDownPaymentEdit" size="lg" title="Edit Buying Down Payment" style="max-width: 90% !important;"
			@ok="submitInfoBuying" ok-title="Save" no-close-on-backdrop>
			<div>
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-4">
								<label for="">Date (yyyy/mm/dd)</label>
								<date-picker v-model="dataSingleBuyingDownPayment.date" :format="momentFormatEdit" type="date" lang="en"
									style="height: 31px;" :placeholder="'Select Date'" @input="getTglEdit">
								</date-picker>
							</div>
							<div class="col-md-4">
								<label for="">Number</label>
								<div class="input-group">
									<input v-model="dataSingleBuyingDownPayment.number" type="text" class="form-control form-control-sm"
										placeholder="Number" />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Invoice No</label>
									<input v-model="dataSingleBuyingDownPayment.invoice_no" type="text" class="form-control form-control-sm"
										placeholder="Invoice No" required />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Term</label>
									<v-select v-model="dataSingleBuyingDownPayment.term" taggable :reduce="(option) => option.id"
										:options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
									</v-select>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Down Payment</label>
									<input v-model="dataSingleBuyingDownPayment.down_payment" type="text"
										class="form-control form-control-sm" placeholder="Down Payment" required />
								</div>
							</div>
							<div class="col-md-2">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="taxable" v-model="dataSingleBuyingDownPayment.taxable" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="taxable">Taxable</label>
								</div>
							</div>
							<div class="col-md-4" v-show="parseInt(dataSingleBuyingDownPayment.taxable) === 1">
								<div class="form-group">
									<label for="">PPN</label>
									<v-select v-model="dataSingleBuyingDownPayment.ppn" :reduce="(option) => option.id"
										:options="listPercentage" label="description" @input="changePpn">
									</v-select>
								</div>
							</div>
							<div class="col-md-2"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1)">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="inclusiveTax" v-model="dataSingleBuyingDownPayment.inclusive_tax" type="checkbox"
										true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="inclusiveTax">Inclusive Tax</label>
								</div>
							</div>
							<div class="col-md-4"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0)">
								<div class="form-group">
									<label for="">Document Code</label>
									<v-select v-model="dataSingleBuyingDownPayment.doc_code" :options="listDocCode"
										:reduce="(option) => option.code" label="text" @input="changeDocCodeEdit" item-text="text"
										item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-4"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0 && showTaxType === true)">
								<div class="form-group">
									<label for="">Tax Type</label>
									<v-select v-model="dataSingleBuyingDownPayment.tax_type" :taggable="false" :options="listTaxTypeBuying"
										:reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Description</label>
									<textarea v-model="dataSingleBuyingDownPayment.description" class="form-control form-control-sm"
										placeholder="Description" cols="30" rows="3"></textarea>
								</div>
							</div>
							<!-- <div class="col-md-4" v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSingleBuyingDownPayment.inclusive_tax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div> -->
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Currency</label>
									<input v-model="dataSingleBuyingDownPayment.symbol" type="text" class="form-control form-control-sm"
										placeholder="Currency" required disabled />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Commercial Rate</label>
									<input v-model="dataSingleBuyingDownPayment.price_kurs" type="text" class="form-control form-control-sm"
										placeholder="Commercial Rate" disabled />
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Fiscal Rate</label>
									<input v-model="dataSingleBuyingDownPayment.fiscal_kurs" type="text"
										class="form-control form-control-sm" placeholder="Fiscal Rate" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
		<b-modal id="modalFavBuyingDownPayment" size="lg" title="Favorite Job Order" style="max-width: 90% !important;"
			ok-title="Save" no-close-on-backdrop :hide-footer="true">
			<div class="mt-3">
				<div class="row">
					<div class="col-12 float-right">
						<b-button class="btn btn-create btn-sm float-right" style="background: #51C1AD; border: 1px; height: 35px;"
							@click="openModalAddFavDPBuying" v-can="'JOB_ORDER_FAVORITE_CREATE'">
							Add
						</b-button>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-9">
						<div class="float-left">
							<div class="form-inline">
								<label class="mb-2 mr-sm-2">Search :</label>
								<input v-model="filterText" type="text" class="form-control form-control-sm mb-2"
									placeholder="Search . . ." @keyup="doFilter" />
							</div>
						</div>
					</div>
				</div>
				<div class="table-responsive">
					<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
						:http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path
						class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable"
						@vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed"
						@vuetable:pagination-data="onPaginationData">
						<template slot="actions" slot-scope="props">
							<div class="table-button-container">
								<a href="javascript:void(0);" class="action-icon mr-1" @click="loadFav(props.rowData)">
									<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect width="28" height="24" rx="2" fill="#93EDC7" fill-opacity="0.2" />
										<g clip-path="url(#clip0)">
											<path
												d="M11.1376 10.2636C11.1734 10.2037 11.1929 10.1499 11.1929 10.1055C11.1929 5.66606 13.4608 4.64258 14.0054 4.30508C14.1 4.24969 14.0943 4.22662 13.9858 4.22662C10.4764 4.22662 8.3083 6.74778 8.3083 10.1055C8.3083 10.1604 8.3098 10.213 8.31259 10.2636H6.28906L9.75061 14.6955L13.2122 10.2636H11.1376Z"
												fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M20.7127 13.1482L17.2511 8.71625L13.7896 13.1482H15.8638C15.8283 13.2078 15.8088 13.2614 15.8088 13.3057C15.8088 17.7457 13.5409 18.7685 12.9963 19.106C12.9017 19.162 12.9075 19.1851 13.0159 19.1851C16.5253 19.1851 18.6934 16.6634 18.6934 13.3057C18.6934 13.251 18.6919 13.1986 18.6892 13.1482H20.7127V13.1482Z"
												fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round"
												stroke-linejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0">
												<rect width="15" height="19.4118" fill="white" transform="translate(6 2)" />
											</clipPath>
										</defs>
									</svg>
								</a>
								<a href="javascript:void(0);" class="action-icon  mr-1" @click="deleteFav(props.rowData)"
									v-can="'JOB_ORDER_FAVORITE_DELETE'">
									<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect width="28" height="24" rx="2" fill="#FFC7C2" />
										<path
											d="M18.654 8.75H8.34598C8.33535 8.87184 8.29707 8.22116 9.04341 18.9866C9.08273 19.5547 9.57769 20 10.1701 20H16.8298C17.4223 20 17.9173 19.5547 17.9566 18.9866C18.7036 8.21187 18.6644 8.86938 18.654 8.75ZM11.4652 17.9981C11.1766 18.0218 10.9269 17.8168 10.902 17.5434L10.382 11.7934C10.357 11.5181 10.5689 11.2759 10.8549 11.2519C11.1409 11.2281 11.3931 11.4316 11.4181 11.7066L11.9381 17.4566C11.9631 17.7319 11.7512 17.9741 11.4652 17.9981ZM14.02 17.5C14.02 17.7762 13.7873 18 13.5 18C13.2127 18 12.98 17.7762 12.98 17.5V11.75C12.98 11.4738 13.2127 11.25 13.5 11.25C13.7873 11.25 14.02 11.4738 14.02 11.75V17.5ZM16.6179 11.7934L16.098 17.5434C16.0746 17.8038 15.8474 18 15.5806 18C15.2733 18 15.0355 17.7474 15.0619 17.4566L15.5819 11.7066C15.6069 11.4316 15.8588 11.2281 16.1451 11.2519C16.4311 11.2759 16.643 11.5181 16.6179 11.7934ZM18.8704 5.75H16.356C16.3613 5.68422 16.3599 5.73338 16.3599 5.08594C16.3599 4.48719 15.8532 4 15.2306 4H11.7694C11.1467 4 10.6401 4.48719 10.6401 5.08594C10.6401 5.72088 10.6387 5.68397 10.644 5.75H8.12954C7.50686 5.75 7.0002 6.23719 7.0002 6.83594C7.0002 7.72916 6.99873 7.68681 7.00377 7.75H19.9962C20.0013 7.68584 19.9998 7.724 19.9998 6.83594C19.9998 6.23719 19.4931 5.75 18.8704 5.75ZM15.3199 5.66406C15.3199 5.71156 15.28 5.75 15.2306 5.75H11.7694C11.72 5.75 11.68 5.71156 11.68 5.66406V5.08594C11.68 5.03844 11.72 5 11.7694 5H15.2306C15.28 5 15.3199 5.03844 15.3199 5.08594V5.66406Z"
											fill="#EA4C40" />
									</svg>
								</a>
							</div>
						</template>
					</vuetable>
				</div>
				<!-- end table -->

				<!-- pagination -->
				<div class="row">
					<div class="col-md-5">
						<div class="row no-gutters">
							<div class="col-lg-3">
								<div class="form-group float-lg-left">
									<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
										<option v-for="opt in lengthMenu" :key="opt" :value="opt">
											{{ opt }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-8">
								<vuetable-pagination-info ref="paginationInfo" class="
                    justify-content-center justify-content-lg-start
                    text-center text-lg-left
                  ">
								</vuetable-pagination-info>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-7">
						<vuetable-pagination ref="pagination" :css="css.pagination" class="
                pagination pagination-rounded
                justify-content-center justify-content-lg-end
              " @vuetable-pagination:change-page="onChangePage">
						</vuetable-pagination>
					</div>
				</div>
			</div>
		</b-modal>
		<b-modal id="modalAddFavDPBuying" size="lg" title="Add Favorite Job Order" style="max-width: 90% !important;"
			ok-title="Save" no-close-on-backdrop @ok="submitFavBuying">
			<div class="mt-3">
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label for="">Name</label>
							<input v-model="nameFavorite" type="text" class="form-control form-control-sm"
								placeholder="Input your favorite Jobfile Name" />
						</div>
					</div>
				</div>
			</div>
		</b-modal>
		<div v-for="(item, k) in listBuyingDownPayment" :key="k" class="form-row">
			<!-- Is Not Copy -->
			<div class="col-md-12 mt-2" v-show="item.is_copied === 0">
				<div class="container-item pl-2 pr-2" style="background: #D8F8EB;">
					<div class="row m-0 mt-1">
						<div class="col-md-6" style="display: flex;justify-content: start;align-items: center;">
							<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M1 2C1 1.46957 1.21071 0.960859 1.58579 0.585786C1.96086 0.210714 2.46957 0 3 0H11C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V14C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12H6C5.73478 12 5.48043 12.1054 5.29289 12.2929C5.10536 12.4804 5 12.7348 5 13V15C5 15.2652 4.89464 15.5196 4.70711 15.7071C4.51957 15.8946 4.26522 16 4 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14V2ZM4 3H6V5H4V3ZM6 7H4V9H6V7ZM8 3H10V5H8V3ZM10 7H8V9H10V7Z"
									fill="#F9A707" />
							</svg>
							<span style="color: #585858;" class="ml-2">{{ item.vendor_name }}</span>
						</div>
						<div class="col-md-6" style="display: flex;justify-content: end;align-items: center;">
							<span style="color: #817F7F;" class="mr-2">Grand Total : </span>
							<span style="color: #0EA388;font-size: 20px;font-weight: 700;">{{ formatRupiah((item.grandTotal).toString(),
								item.symbol_default) }}</span>
						</div>
					</div>
					<div class="row m-0 mt-2">
						<div class="col-md-8">
							<div class="row" style="font-size: 12px;">
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">DP No : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.number }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Invoice No : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.invoice_no }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Date : </span>
									<span style="color: #585858;font-weight: 700;">{{ dateFormating(item.date) }}</span>
								</div>
								<div class="col-md-3">
								</div>
							</div>
							<div class="row" style="font-size: 12px;">
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Price : </span>
									<span style="color: #585858;font-weight: 700;">{{ formatRupiah((item.down_payment).toString(),
										item.symbol) }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">PPN : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.ppn }}% ({{
										formatRupiah((item.totalPPN).toString(),
											item.symbol_default) }})</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Total : </span>
									<span style="color: #585858;font-weight: 700;">{{ formatRupiah((item.grandTotal).toString(),
										item.symbol_default) }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Desc : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.description }}</span>
								</div>
							</div>
						</div>
						<div class="col-md-4" style="display: flex;justify-content: end;align-items: center;">
							<b-button class="btn btn-create btn-sm mr-2" style="background: #51C1AD; border: 1px; height: 30px;"
								@click="editInfoBuying(item.id)" v-can="'JOB_ORDER_BUYING_CREATE'">
								<i class="mdi mdi-pencil mr-1"></i>
								Edit
							</b-button>
							<b-button class="btn btn-create btn-sm mr-2" style="background: #EA4C40; border: 1px; height: 30px;"
								@click="deleteBuyingData(item.id)" v-can="'JOB_ORDER_BUYING_CREATE'">
								<i class="mdi mdi-delete mr-1"></i>
								Delete
							</b-button>
							<b-button class="btn btn-create btn-sm" style="background: #F9A707; border: 1px; height: 30px;"
								@click="openModalBuyingCopy(item.id)" v-can="'JOB_ORDER_BUYING_CREATE'" v-show="item.is_copied === 0">
								<i class="mdi mdi-clipboard mr-1"></i>
								Copy ?
							</b-button>
							<span v-show="item.is_copied === 1" style="color: #009FC2;font-weight: 700;font-size: 12px;">Copied !</span>
						</div>
					</div>
				</div>
			</div>
			<!-- Is Copy -->
			<div class="col-md-12 mt-2" v-show="item.is_copied === 1">
				<div class="container-item pl-2 pr-2" style="background: #F6F6F6;">
					<div class="row m-0 mt-1">
						<div class="col-md-6" style="display: flex;justify-content: start;align-items: center;">
							<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M1 2C1 1.46957 1.21071 0.960859 1.58579 0.585786C1.96086 0.210714 2.46957 0 3 0H11C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V14C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12H6C5.73478 12 5.48043 12.1054 5.29289 12.2929C5.10536 12.4804 5 12.7348 5 13V15C5 15.2652 4.89464 15.5196 4.70711 15.7071C4.51957 15.8946 4.26522 16 4 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14V2ZM4 3H6V5H4V3ZM6 7H4V9H6V7ZM8 3H10V5H8V3ZM10 7H8V9H10V7Z"
									fill="#F9A707" />
							</svg>
							<span style="color: #585858;" class="ml-2">{{ item.vendor_name }}</span>
						</div>
						<div class="col-md-6" style="display: flex;justify-content: end;align-items: center;">
							<span style="color: #817F7F;" class="mr-2">Grand Total : </span>
							<span style="color: #0EA388;font-size: 20px;font-weight: 700;">{{ formatRupiah((item.grandTotal).toString(),
								item.symbol_default) }}</span>
						</div>
					</div>
					<div class="row m-0 mt-2">
						<div class="col-md-8">
							<div class="row" style="font-size: 12px;">
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">DP No : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.number }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Invoice No : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.invoice_no }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Date : </span>
									<span style="color: #585858;font-weight: 700;">{{ dateFormating(item.date) }}</span>
								</div>
								<div class="col-md-3">
								</div>
							</div>
							<div class="row" style="font-size: 12px;">
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Price : </span>
									<span style="color: #585858;font-weight: 700;">{{ formatRupiah((item.down_payment).toString(),
										item.symbol) }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">PPN : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.ppn }}% ({{
										formatRupiah((item.totalPPN).toString(),
											item.symbol) }})</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Total : </span>
									<span style="color: #585858;font-weight: 700;">{{ formatRupiah((item.grandTotal).toString(),
										item.symbol) }}</span>
								</div>
								<div class="col-md-3">
									<span style="color: #817F7F;font-weight: 400;">Desc : </span>
									<span style="color: #585858;font-weight: 700;">{{ item.description }}</span>
								</div>
							</div>
						</div>
						<div class="col-md-4" style="display: flex;justify-content: end;align-items: center;">
							<b-button class="btn btn-create btn-sm mr-2" style="background: #51C1AD; border: 1px; height: 30px;"
								@click="editInfoBuying(item.id)" v-can="'JOB_ORDER_BUYING_CREATE'">
								<i class="mdi mdi-pencil mr-1"></i>
								Edit
							</b-button>
							<b-button class="btn btn-create btn-sm mr-2" style="background: #EA4C40; border: 1px; height: 30px;"
								@click="deleteBuyingData(item.id)" v-can="'JOB_ORDER_BUYING_CREATE'">
								<i class="mdi mdi-delete mr-1"></i>
								Delete
							</b-button>
							<b-button class="btn btn-create btn-sm" style="background: #F9A707; border: 1px; height: 30px;"
								@click="openModalBuyingDownPayment" v-can="'JOB_ORDER_BUYING_CREATE'" v-show="item.is_copied === 0">
								<i class="mdi mdi-clipboard mr-1"></i>
								Copy ?
							</b-button>
							<span v-show="item.is_copied === 1" style="color: #009FC2;font-weight: 700;font-size: 12px;">Copied !</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2'
// import DatePicker from 'vue2-datepicker'
// import Vue from 'vue'

// var url = '/quotation'

for (
	let i = 0;
	i < JSON.parse(localStorage.getItem('userPermissions')).length;
	i++
) {
	if (
		JSON.parse(localStorage.getItem('userPermissions'))[i] ===
		'CRM_ADMIN_ENABLE'
	) {
		// url = '/quotation?admin=true'
	}
}
// Vue.component('my-detail-row', DetailRow)

export default {
	components: {
		Vuetable,
		VuetablePagination,
		VuetablePaginationInfo,
		DatePicker
	},
	props: {
		showActions: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			// filterText: '',
			isLoadingTable: false,
			httpOptions: {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
			},
			apiUrl: this.$store.state.rootApi + "/FavoriteJobOrder/4",
			urlPdf: '#',
			items: [
				{
					text: 'Master',
					href: '/',
				},
				{
					text: 'Quotation',
					href: '/quotation',
				},
				{
					text: 'List',
					active: true,
				},
			],
			lengthMenu: [5, 10, 25, 50, 100],
			perPage: 5,
			fields: [
				{
					name: 'name',
					title: 'Name',
					// sortField: 'number',
				},
				{
					name: 'id_number',
					title: 'Job Order No',
					// sortField: 'subject',
				},
				{
					name: 'created_at_format',
					title: 'Created Date',
					// sortField: 'customer_id',
				},
				{
					name: 'actions',
					title: 'Actions',
					titleClass: 'text-center',
					dataClass: 'text-center',
				},
			],
			momentFormat: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatEdit: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatBuyingCopy: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingCopy.date).format('YYYY-MM-DD') : ''
				},
			},
			moreParams: {},
			filterText: '',
			deleteData: {},
			totalPrice: 0,
			dataBuyingCopy: {
				currency: '',
				term: '',
				number: '',
				numberText: '',
				invoice: '',
				vendor: '',
				date: '',
				ppn: '',
				docCode: '',
				taxType: '',
				commercialRate: '',
				fiscalRate: '',
				inclusiveTax: false,
				id_dp: 0
			},
			itemBuyingCopy: [{
				item: '',
				price: '',
				qty: '',
				itemDescription: '',
				truck: '',
				isTruck: 0
			}],
			dataBuyingDownPayment: {
				currency: '',
				term: '',
				number: '',
				numberText: '',
				invoice: '',
				vendor: '',
				date: '',
				ppn: '',
				docCode: '',
				taxType: '',
				commercialRate: '',
				fiscalRate: '',
				inclusiveTax: 0,
				downPayment: 0,
				taxable: 0
			},
			types: [{
				type: '',
				item: [{
					item: '',
					price: '',
					// qty: '',
					itemDescription: '',
					item_id: '',
					curr: '',
					currency_id: ''
				}]
			}],
			showTaxType: true,
			itemBuying: [{
				item: '',
				price: '',
				qty: '',
				itemDescription: '',
				truck: '',
				isTruck: 0
			}],
			newItemBuying: [{
				item: '',
				price: '',
				qty: '',
				itemDescription: '',
				truck: '',
				isTruck: 0
			}],
			inclusiveTax: 0,
			detail: [{
				item: '',
				price: '',
				qty: '',
				itemDescription: '',
				item_id: '',
				curr: ''
			}],
			isManualNumberBuyingDownPayment: false,
			isManualNumberBuying: false,
			isTruck: false,
			isCopyTransaction: "0",
			typeCopy: 'Buying',
			joborderCopy: '',
			numberCopy: '',
			remarksCopy: '',
			listJobOrderCopy: [],
			listNumberCopy: [],
			listType: [],
			// isLoadingTable: false,
			// httpOptions: {
			//   headers: {
			//     Authorization: 'Bearer ' + localStorage.getItem('access_token'),
			//   },
			// },
			// apiUrl: this.$store.state.rootApi + url,
			// urlPdf: '#',
			// items: [
			//   {
			//     text: 'Oprational',
			//     href: '/',
			//   },
			//   {
			//     text: 'job_order',
			//     href: '/job_order',
			//   },
			//   {
			//     text: 'List',
			//     active: true,
			//   },
			// ],
			// lengthMenu: [10, 25, 50, 100],
			// perPage: 10,
			// fields: [
			//   {
			//     name: 'no',
			//     title: '#',
			//   },
			//   {
			//     name: 'id',
			//     title: 'No',
			//   },
			//   {
			//     name: 'number',
			//     title: 'Job No.',
			//   },
			//   {
			//     name: 'subject',
			//     title: 'Customer',
			//   },
			//   {
			//     name: '-',
			//     title: 'BL Number',
			//   },
			//   {
			//     name: '-',
			//     title: 'DO Number',
			//   },
			//   {
			//     name: '-',
			//     title: 'Suffing',
			//   },
			//   {
			//     name: '-',
			//     title: 'Marketing',
			//   },
			//   {
			//     name: 'customer.number',
			//     title: 'Created By',
			//   },
			//   {
			//     name: 'status',
			//     title: 'Status',
			//   },
			//   {
			//     name: 'info',
			//     title: 'Info',
			//   },
			//   {
			//     name: 'action',
			//     title: 'Action',
			//   },
			// ],
			css: {
				pagination: {
					activeClass: 'btn-primary text-white',
					pageClass: 'btn btn-sm ',
					linkClass: 'btn btn-sm',
					icons: {
						prev: 'fas fa-arrow-left',
						next: 'fas fa-arrow-right',
					},
				},
			},
			momentFormatStartDateData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
				},
			},
			momentFormatEndDateData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
				},
			},
			year: null,
			createdBy: null,
			statusData: 'All',
			startDateData: null,
			endDateData: null,
			branchData: [],
			marketingData: [],
			rangeData: 'customData',
			topCustomerData: 'Select Number',
			isJobOrderNo: true,
			compareData: false,
			isManualTopCustData: false,
			monthData: '01',
			showRate: false,
			yearData: null,
			nameFavorite: '',
			formatYearData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.yearData).format('YYYY') : ''
				},
			},
			isLoading: false,
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			jobfileTypeData: 'All',
			customerData: [],
		}
	},
	computed: {
		...mapState('jobOrder', ['createdField']),
		...mapState('customer', ['listCustomerQuo']),
		...mapState('port', ['listPort']),
		...mapState('vendor', ['listVendor']),
		...mapState('truck', ['listTruck']),
		...mapState('autonumber', ['listAutonumber']),
		...mapState('currency', ['listCurrency', 'dataCurrency', 'dataDefaultCurrency']),
		...mapState('item', ['listItem']),
		...mapState('term', ['listTerm']),
		...mapState('company', ['companyIsAol']),
		...mapState('jobOrderBuyingDownPayment', ['listBuyingDownPayment', 'dataSingleBuyingDownPayment']),
		...mapState('jobOrderBuying', ['listDocCode', 'listTaxTypeBuying']),
		...mapState('percentage', ['listPercentage']),
	},
	mounted() {
		this.getCreatedField()
		this.getListCustomerQuo()
		this.getListPort()
		this.getListVendor()
		this.p_getDefaultCurrency()
		this.initCurrency()
		// this.getListTerm()
		// this.getListItem()
		this.getListPercentage()
		this.getListTruck()
		let idJoborder = window.location.href.split("/").splice(0, 6)[5];
		this.getListBuyingDownPayment(idJoborder)
		this.isAol();
	},
	methods: {
		...authMethods,
		errorAlertValidation: function (message = 'Terjadi Kesalahan') {
			this.$swal({
				title: message,
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
		},
		...mapActions('jobOrder', ['getCreatedField']),
		...mapActions('customer', ['getListCustomerQuo']),
		...mapActions('port', ['getListPort']),
		...mapActions('vendor', ['getListVendor']),
		...mapActions('truck', ['getListTruck']),
		...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod']),
		...mapActions('currency', ['getListCurrency', 'getDefaultCurrency']),
		...mapActions('item', ['getItemByPpn']),
		...mapActions('term', ['getListTerm']),
		...mapActions('quotation', ['saveQuotation']),
		...mapActions('percentage', ['getListPercentage']),
		...mapActions('company', ['isAol']),
		...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
		...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
		...mapActions('jobOrderBuyingDownPayment', ['saveBuyingDownPayment', 'getListBuyingDownPayment', 'getBuyingDownPayment', 'updateBuyingDownPayment', 'deleteBuyingDownPayment', 'submitAOL', 'saveBuyingCopy', 'deleteBuyingDownPaymentWithBuying']),
		...mapActions('jobOrderBuying', ['getListDocCode', 'getListTaxTypeBuying', 'getListBuying']),
		setJson(payload) {
			this.json = JSON.parse(payload)
		},
		firstCapital(payload) {
			return payload[0].toUpperCase() + payload.slice(1)
		},
		getTgl() {
			this.dataBuyingDownPayment.date = moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD')
		},
		getTglEdit() {
			this.dataSingleBuyingDownPayment.date = moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD')
		},
		// doFilter() {
		//   this.onFilterSet(this.filterText)
		// },
		// doFilterMultipleKeywords() {
		//   this.onFilterSetMultipleKeywords()
		// },
		// /**
		//  * Table Length Change
		//  *
		//  * @return  {[type]}  [return description]
		//  */
		// onChangeTableLength() {
		//   // this.$refs.vuetable.reload()
		//   this.$nextTick(() => this.$refs.vuetable.refresh())
		// },
		// /**
		//  * Pagination page change
		//  */
		// onChangePage(page) {
		//   this.$refs.vuetable.changePage(page)
		// },
		// /**
		//  * Pagination info and pagination show
		//  */
		// onPaginationData(paginationData) {
		//   this.$refs.pagination.setPaginationData(paginationData)
		//   this.$refs.paginationInfo.setPaginationData(paginationData)
		// },
		/**
		 * [onFilterSet description]
		 *
		 * @param   {[type]}  filterText  [filterText description]
		 *
		 * @return  {[type]}              [return description]
		 */
		// onFilterSet(filterText) {
		//   this.moreParams = {
		//     search: filterText,
		//   }
		//   this.$nextTick(() => this.$refs.vuetable.refresh())
		// },
		onFilterSetMultipleKeywords(filterText) {
			let tempStartDateData = ''
			let tempEndDateData = ''

			if (this.rangeData === 'customData') {
				tempStartDateData = this.startDateData
				tempEndDateData = this.endDateData
			} else if (this.rangeData === 'monthlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth(),
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth() + 1,
						1
					)
						.toISOString()
						.split('T')[0]
				}
			} else if (this.rangeData === 'yearlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() + '/01/01'
					)
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() +
						'/12/' +
						new Date(
							new Date(this.yearData.toString()).getFullYear(),
							12,
							0
						).getDate()
					)
				}
			}

			let strMarketing = []
			this.marketingData.forEach((data, idx) => {
				strMarketing.push(data.id_user)
			})

			let strCustomer = []
			this.customerData.forEach((data, idx) => {
				strCustomer.push(data.id)
			})

			if (this.jobfileType === '') {
				this.errorAlertValidation('Jobfile Type is required!')
			} else if (this.statusData === '') {
				this.errorAlertValidation('Status is required!')
			} else if (this.rangeData === '') {
				this.errorAlertValidation('Range is required!')
			} else if (this.rangeData !== '') {
				if (this.rangeData === 'customData') {
					if (this.startDateData === '' || this.startDateData == null) {
						this.errorAlertValidation('Start Date is required!')
					} else if (this.endDateData2 === '' || this.endDateData == null) {
						this.errorAlertValidation('End Date is required!')
					} else {
						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				} else if (this.rangeData === 'monthlyData') {
					if (this.monthData === '' || this.monthData == null) {
						this.errorAlertValidation('Month is required!')
					} else if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				} else if (this.rangeData === 'yearlyData') {
					if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				}
			}

			this.moreParams = {
				branch: localStorage.getItem('default_branch'),
				customer: strCustomer,
				jobfileType: this.jobfileTypeData,
				startDate: tempStartDateData,
				endDate: tempEndDateData,
				sales: strMarketing,
				status: this.statusData,
				perPage: 10,
				filter: true,
			}
			// console.log(this.moreParams, 'THIS PARAMSSS')
			// console.log(filterText)
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Show Table Loading
		 *
		 * @return  {[type]}  [return description]
		 */
		// loadingTable() {
		//   this.isLoadingTable = true
		// },

		/**
		 * Hide Loading if Success
		 *
		 * @return  {[type]}  [return description]
		 */
		// loadTableSuccess() {
		//   this.isLoadingTable = false
		// },
		/**
		 * Hide Loading if Failed
		 *
		 * @return  {[type]}  [return description]
		 */
		// loadTableFailed(error) {
		//   console.log(error)

		//   this.isLoadingTable = false
		//   this.$swal({
		//     title: 'Terjadi Kesalahan',
		//     type: 'error',
		//     timer: '2000',
		//     toast: true,
		//     position: 'bottom-end',
		//   })
		//   // }
		// },

		formatDate(type) {
			let tempStartDateData = ''
			let tempEndDateData = ''

			// console.log(this.monthData1.toString())
			// console.log(new Date(this.yearData2.toString()).getFullYear())

			if (this.rangeData === 'customData') {
				tempStartDateData = this.startDateData
				tempEndDateData = this.endDateData
			} else if (this.rangeData === 'monthlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth(),
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth() + 1,
						1
					)
						.toISOString()
						.split('T')[0]
				}
			} else if (this.rangeData === 'yearlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() + '/01/01'
					)
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() +
						'/12/' +
						new Date(
							new Date(this.yearData.toString()).getFullYear(),
							12,
							0
						).getDate()
					)
				}
			}

			if (tempStartDateData === null || tempEndDateData === null) {
				return ''
			}
			return (
				this.monthNames[tempStartDateData.getMonth()] +
				' ' +
				tempStartDateData.getFullYear() +
				' - ' +
				this.monthNames[tempEndDateData.getMonth()] +
				' ' +
				tempEndDateData.getFullYear()
			)
		},
		// getListTerm() {
		//   try {
		//     // let strBranch = ''
		//     // this.branchData1.forEach((data, idx) => {
		//     //   if (idx === 0) {
		//     //     strBranch += 'id_branch[]=' + data.id_branch
		//     //   } else {
		//     //     strBranch += '&id_branch[]=' + data.id_branch
		//     //   }
		//     // })
		//     this.getListTerm()
		//       .then((res) => {
		//         console.log(res, 'res user by branch')
		//         // this.user_id = res.result[1].id
		//       })
		//       .catch((err) => {
		//         console.log(err)
		//       })
		//   } catch (error) {
		//     console.log(error)
		//   }
		// },
		initCurrency() {
			try {
				this.getListCurrency()
					.then((res) => {
						// this.dataBuyingDownPayment.currency = 2
						// console.log(this.dataDefaultCurrency, 'This Data Default')
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		p_getDefaultCurrency() {
			try {
				this.getDefaultCurrency()
					.then((res) => {
						this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id
						// console.log(this.dataDefaultCurrency, 'This Data Default')
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		openModalCreated() {
			console.log('OKEEE')
			this.$bvModal.show('my-modal')
		},
		hideModalCreated() {
			this.$bvModal.hide('my-modal')
		},

		openModalSubJobCreated() {
			this.$bvModal.show('my-modal-subJob')
		},
		hideModalSubJobCreated() {
			this.$bvModal.hide('my-modal-subJob')
		},

		changeJobOrderNo() {
			this.isManualJobOrderNo = !this.isManualJobOrderNo
			this.isJobOrderNo = !this.isJobOrderNo
			if (this.isJobOrderNo) {
				this.autoJobOrderNo = 15
			}
		},

		onCellClicked(data, field, event) {
			console.log('cellClicked: ', field.customer.name)
			this.$refs.vuetable.toggleDetailRow(data.id)
		},

		onAction(action, rowData, rowIndex) {
			if (action === 'details') {
				this.$router.push({
					name: 'JobOrder Details',
					params: {
						id: rowData.id,
					},
				})
			}
		},
		openModalBuyingDownPayment() {
			this.getListDocCode()
			this.listAutonumber = []
			this.getListAutonumberFlexmod({
				id_flexmod: localStorage.getItem('flexmodActive'),
				id_module: 29
			})
			console.log('OKEEE')
			// this.dataBuyingDownPayment = {
			//   currency: '',
			//   term: '',
			//   number: '',
			//   numberText: '',
			//   po: '',
			//   customer: '',
			//   date: '',
			//   ppn: '',
			//   docCode: '',
			//   taxType: '',
			//   commercialRate: '',
			//   fiscalRate: '',
			//   inclusiveTax: false
			// }

			this.dataBuyingDownPayment.term = ''
			this.dataBuyingDownPayment.number = ''
			this.dataBuyingDownPayment.numberText = ''
			this.dataBuyingDownPayment.invoice = ''
			this.dataBuyingDownPayment.vendor = ''
			this.dataBuyingDownPayment.date = ''
			this.dataBuyingDownPayment.ppn = ''
			this.dataBuyingDownPayment.docCode = ''
			this.dataBuyingDownPayment.taxType = ''
			this.dataBuyingDownPayment.commercialRate = ''
			this.dataBuyingDownPayment.fiscalRate = ''
			this.dataBuyingDownPayment.downPayment = 0
			this.dataBuyingDownPayment.taxable = '0'
			this.dataBuyingDownPayment.description = ''
			this.dataBuyingDownPayment.inclusiveTax = false

			this.itemBuying = [
				{
					item: '',
					price: '',
					qty: '',
					itemDescription: '',
					truck: '',
					isTruck: 0,
				}
			]

			this.$bvModal.show('modalBuyingDownPayment')
		},
		openModalBuyingCopy(id) {
			this.getListDocCode()
			this.getBuyingDownPayment(id)
				.then((res) => {
					this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
					this.listAutonumber = []
					this.getListAutonumberFlexmod({
						id_flexmod: localStorage.getItem('flexmodActive'),
						id_module: 25
					})
					console.log('OKEEE')

					this.getListTerm()
						.then((res) => {
							if (this.dataSingleBuyingDownPayment.id_currency !== this.dataDefaultCurrency.id) {
								this.showRate = true
							} else {
								this.showRate = false
							}
							this.dataBuyingCopy.term = this.dataSingleBuyingDownPayment.term
							this.dataBuyingCopy.number = ''
							this.dataBuyingCopy.numberText = ''
							this.dataBuyingCopy.invoice = ''
							this.dataBuyingCopy.vendor = this.dataSingleBuyingDownPayment.id_vendor
							this.dataBuyingCopy.date = ''
							this.dataBuyingCopy.ppn = ''
							this.dataBuyingCopy.docCode = ''
							this.dataBuyingCopy.taxType = ''
							this.dataBuyingCopy.commercialRate = ''
							this.dataBuyingCopy.fiscalRate = ''
							this.dataBuyingCopy.currency = this.dataSingleBuyingDownPayment.id_currency
							this.dataBuyingCopy.inclusiveTax = false
							this.dataBuyingCopy.id_dp = id

							this.itemBuyingCopy = [
								{
									item: '',
									price: '',
									qty: '',
									itemDescription: '',
									truck: '',
									isTruck: 0,
								}
							]

							this.$bvModal.show('modalBuyingCopy')
						})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		submitBuying(bvModalEvent) {
			bvModalEvent.preventDefault()
			console.log(this.dataBuyingDownPayment, 'THIS DATA Selling')
			console.log(this.itemBuying, 'THIS DATA ITEM Selling')
			console.log('OKEE')

			if (this.dataBuyingDownPayment.number === "" && this.dataBuyingDownPayment.numberText === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Number",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.vendor === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Vendor",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.invoice === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Invoice No",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			// this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id

			if (this.dataBuyingDownPayment.currency !== this.dataDefaultCurrency.id) {
				if (this.dataBuyingDownPayment.commercialRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Commercial Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
				if (this.dataBuyingDownPayment.fiscalRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Fiscal Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
			}

			if (parseInt(this.dataBuyingDownPayment.taxable) === 0) {
				this.dataBuyingDownPayment.ppn = 0
				this.dataBuyingDownPayment.inclusiveTax = 'false'
			}

			let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuyingDownPayment,
				itemBuying: this.itemBuying
			}

			console.log(mergeData, 'THIS MERGE DATA');

			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveBuyingDownPayment({
				payload: mergeData,
				headers: headers
			})
				.then((res) => {
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPayment')
						})

						let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						this.getListBuyingDownPayment(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		submitBuyingCopy(bvModalEvent) {
			bvModalEvent.preventDefault()

			if (this.dataBuyingCopy.number === "" && this.dataBuyingCopy.numberText === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Number",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingCopy.customer === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Vendor",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingCopy.po === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field PO NO",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingCopy.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			// this.dataBuyingCopy.currency = this.dataDefaultCurrency.id

			if (this.dataBuyingCopy.currency !== this.dataDefaultCurrency.id) {
				if (this.dataBuyingCopy.commercialRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Commercial Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
				if (this.dataBuyingCopy.fiscalRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Fiscal Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
			}

			let itemIsValid = true;

			this.itemBuyingCopy.forEach((dt, idx) => {
				if (dt.item === "" || dt.price === "" || dt.qty === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field item, price, and qty in item row " + (idx + 1),
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					itemIsValid = false;
				}
			})

			if (!itemIsValid) {
				return false;
			}

			let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuyingCopy,
				itemBuying: this.itemBuyingCopy
			}

			console.log(mergeData);

			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveBuyingCopy({
				payload: mergeData,
				headers: headers
			})
				.then((res) => {
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingCopy')
						})

						let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						this.getListBuyingDownPayment(idJoborder)
						this.getListBuying(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		triggerManualNumberBuying() {
			this.isManualNumberBuyingDownPayment = !this.isManualNumberBuyingDownPayment
		},
		cekIsTruck(idx) {
			// if(this.itemBuying[idx].isTruck) {
			//   this.itemBuying[idx].isTruck = false
			// } else {
			//   this.itemBuying[idx].isTruck = true
			// }

			console.log(this.itemBuying[idx].isTruck)
		},
		changeVendor() {
			let getVendor = this.listVendor.find(x => x.id === this.dataBuyingDownPayment.vendor)
			this.getListTerm()
				.then((res) => {
					if (getVendor !== undefined) {
						this.dataBuyingDownPayment.term = getVendor.id_term
					}
				})
		},
		changeVendorCopy() {
			let getVendor = this.listVendor.find(x => x.id === this.dataBuyingCopy.customer)
			// this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
			this.getListTerm()
				.then((res) => {
					if (getVendor !== undefined) {
						this.dataBuyingCopy.term = getVendor.id_term
					}
					// this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
					// let termDefault = this.listTerm.find(x => x.id === this.dataBuyingDownPayment.customer.id_term)

					// if(termDefault !== undefined) {
					//   this.dataBuyingDownPayment.term = termDefault
					// }
				})
		},
		changePpn() {
			console.log(parseInt(this.dataBuyingDownPayment.ppn))
			if (parseInt(this.dataBuyingDownPayment.ppn) > 0) {
				this.showTaxType = true;
			} else {
				this.showTaxType = false;
			}
			console.log(this.showTaxType)
			this.getItemByPpn({
				ppn: this.dataBuyingDownPayment.ppn,
				itemCategory: 1
			})
		},
		changePpnCopy() {
			console.log(parseInt(this.dataBuyingCopy.ppn))
			if (parseInt(this.dataBuyingCopy.ppn) > 0) {
				this.showTaxType = true;
			} else {
				this.showTaxType = false;
			}
			console.log(this.showTaxType)
			this.getItemByPpn({
				ppn: this.dataBuyingCopy.ppn,
				itemCategory: 1
			})
		},
		changeDocCode() {
			if (this.dataBuyingDownPayment.docCode === 'DIGUNGGUNG') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}
			this.dataBuyingDownPayment.taxType = ''
			this.getListTaxTypeBuying(this.dataBuyingDownPayment.docCode)
		},
		changeDocCodeCopy() {
			if (this.dataBuyingCopy.docCode === 'DIGUNGGUNG') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}
			this.dataBuyingCopy.taxType = ''
			this.getListTaxTypeBuying(this.dataBuyingCopy.docCode)
		},
		changeDocCodeEdit() {
			if (this.dataSingleBuyingDownPayment.doc_code === 'DIGUNGGUNG') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}
			this.dataSingleBuyingDownPayment.tax_type = ''
			this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
		},
		changeCurrency(value) {
			// this.dataBuyingDownPayment.currency = value

			if (value !== this.dataDefaultCurrency.id) {
				this.showRate = true
			} else {
				this.showRate = false
			}

			// this.dataDefaultCurrency = this.dataBuyingDownPayment.currency
			// console.log(this.dataBuyingDownPayment.currency)
		},
		changeTerm(value) {
			console.log(this.dataBuyingDownPayment.term)
		},
		formatRupiah(angka, prefix) {
			// console.log(angka, 'THIS ANGKAA')
			angka = angka.replace(/\./g, ',')
			let numberString = angka.replace(/[^,\d]/g, '').toString()
			// console.log(numberString, 'THIS ANGKAA 2')
			let split = numberString.split(',')
			let sisa = split[0].length % 3
			let rupiah = split[0].substr(0, sisa)
			let ribuan = split[0].substr(sisa).match(/\d{3}/gi)

			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if (ribuan) {
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}

			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
		editInfoBuying(id) {
			try {
				this.getBuyingDownPayment(id)
					.then((res) => {
						this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
						this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
							.then((res) => {
								this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
									.then((res) => {
										// console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
									})
								this.$bvModal.show('modalBuyingDownPaymentEdit')
							})
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		submitInfoBuying(bvModalEvent) {
			// this.$bvModal.hide('modalBuyingDownPaymentEdit')
			bvModalEvent.preventDefault()

			// this.$nextTick(() => {
			//   this.$bvModal.hide('modalBuyingDownPaymentEdit')
			// })

			if (this.dataSingleBuyingDownPayment.invoice_no === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Invoice No",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataSingleBuyingDownPayment.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (parseInt(this.dataSingleBuyingDownPayment.taxable) === 0) {
				this.dataSingleBuyingDownPayment.ppn = 0
				this.dataSingleBuyingDownPayment.inclusiveTax = 'false'
			}

			let headers = {
				'Content-Type': 'application/json'
			}

			this.updateBuyingDownPayment({
				payload: this.dataSingleBuyingDownPayment,
				headers: headers
			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPaymentEdit')
						})

						let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						this.getListBuyingDownPayment(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		deleteBuyingData(id) {
			this.getBuyingDownPayment(id)
				.then((res) => {
					// this.dataSingleBuyingDownPayment.is_copied = new Date(this.dataSingleBuyingDownPayment.date)
					if (this.dataSingleBuyingDownPayment.is_copied === 0) {
						Swal.fire({
							title: 'Are you sure?',
							text: 'You will not be able to recover this data!',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: 'rgb(221, 107, 85)',
							cancelButtonColor: '#C1C1C1',
							reverseButtons: true,
							confirmButtonText: 'Yes, delete it!',
						}).then((result) => {
							if (result.isConfirmed) {
								let headers = {
									'Content-Type': 'application/json'
								}

								this.deleteBuyingDownPayment({
									payload: id,
									headers: headers
								})
									.then((res) => {
										console.log(res.data, 'datanya')
										if (res.status) {
											const Toast = Swal.mixin({
												toast: true,
												position: 'bottom-end',
												showConfirmButton: false,
												timer: 2000,
												timerProgressBar: true,
												onOpen: (toast) => {
													toast.addEventListener('mouseenter', Swal.stopTimer)
													toast.addEventListener('mouseleave', Swal.resumeTimer)
												}
											})

											Toast.fire({
												icon: 'success',
												title: 'successfully'
											})

											let idJoborder = window.location.href.split("/").splice(0, 6)[5];
											this.getListBuyingDownPayment(idJoborder)
										} else {
											Swal.fire({
												title: "Failed!",
												text: res.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										}
									})
									.catch((err) => {
										console.log(err.response.data)
										Swal.fire({
											title: "Failed!",
											text: err.response.data.message,
											icon: 'warning',
											confirmButtonColor: 'rgb(221, 107, 85)',
											confirmButtonText: 'Ok'
										})
									})
							}
						})
					} else {
						Swal.fire({
							title: 'Warning',
							text: 'You cannot delete the down payment if the Buying transaction has not been deleted!',
							icon: 'warning',
							type: "warning",
							showCancelButton: true,
							showDenyButton: true,
							confirmButtonColor: 'rgb(221, 107, 85)',
							cancelButtonColor: '#C1C1C1',
							reverseButtons: true,
							closeOnConfirm: false,
							confirmButtonText: 'Down Payment Only!',
							denyButtonText: 'Down Payment and Buying!',
							customClass: {
								denyButton: 'button-gamb',
								cancelButton: 'button-gamb',
								confirmButton: 'button-gamb'
							}
						}).then((result) => {
							if (result.isConfirmed) {
								let headers = {
									'Content-Type': 'application/json'
								}

								this.deleteBuyingDownPayment({
									payload: id,
									headers: headers
								})
									.then((res) => {
										console.log(res.data, 'datanya')
										if (res.status) {
											const Toast = Swal.mixin({
												toast: true,
												position: 'bottom-end',
												showConfirmButton: false,
												timer: 2000,
												timerProgressBar: true,
												onOpen: (toast) => {
													toast.addEventListener('mouseenter', Swal.stopTimer)
													toast.addEventListener('mouseleave', Swal.resumeTimer)
												}
											})

											Toast.fire({
												icon: 'success',
												title: 'successfully'
											})

											let idJoborder = window.location.href.split("/").splice(0, 6)[5];
											this.getListBuyingDownPayment(idJoborder)
											this.getListBuying(idJoborder)
										} else {
											Swal.fire({
												title: "Failed!",
												text: res.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										}
									})
									.catch((err) => {
										console.log(err.response.data)
										Swal.fire({
											title: "Failed!",
											text: err.response.data.message,
											icon: 'warning',
											confirmButtonColor: 'rgb(221, 107, 85)',
											confirmButtonText: 'Ok'
										})
									})
							} else if (result.isDenied) {
								let headers = {
									'Content-Type': 'application/json'
								}

								this.deleteBuyingDownPaymentWithBuying({
									payload: id,
									headers: headers
								})
									.then((res) => {
										console.log(res.data, 'datanya')
										if (res.status) {
											const Toast = Swal.mixin({
												toast: true,
												position: 'bottom-end',
												showConfirmButton: false,
												timer: 2000,
												timerProgressBar: true,
												onOpen: (toast) => {
													toast.addEventListener('mouseenter', Swal.stopTimer)
													toast.addEventListener('mouseleave', Swal.resumeTimer)
												}
											})

											Toast.fire({
												icon: 'success',
												title: 'successfully'
											})

											let idJoborder = window.location.href.split("/").splice(0, 6)[5];
											this.getListBuyingDownPayment(idJoborder)
											this.getListBuying(idJoborder)
										} else {
											Swal.fire({
												title: "Failed!",
												text: res.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										}
									})
									.catch((err) => {
										console.log(err.response.data)
										Swal.fire({
											title: "Failed!",
											text: err.response.data.message,
											icon: 'warning',
											confirmButtonColor: 'rgb(221, 107, 85)',
											confirmButtonText: 'Ok'
										})
									})
							}
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		submitToAol(id) {
			let headers = {
				'Content-Type': 'application/json'
			}

			this.submitAOL({
				payload: id,
				headers: headers
			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data === 1) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						this.getListBuyingDownPayment(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		openModalFavBuyingDownPayment() {
			this.$bvModal.show('modalFavBuyingDownPayment')
		},
		doFilter() {
			this.onFilterSet(this.filterText)
		},
		/**
		 * Table Length Change
		 *
		 * @return  {[type]}  [return description]
		 */
		onChangeTableLength() {
			// this.$refs.vuetable.reload()
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Pagination page change
		 */
		onChangePage(page) {
			this.$refs.vuetable.changePage(page)
		},
		/**
		 * Pagination info and pagination show
		 */
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData)
			this.$refs.paginationInfo.setPaginationData(paginationData)
		},
		/**
		 * [onFilterSet description]
		 *
		 * @param   {[type]}  filterText  [filterText description]
		 *
		 * @return  {[type]}              [return description]
		 */
		onFilterSet(filterText) {
			this.moreParams = {
				search: filterText,
			}
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Show Table Loading
		 *
		 * @return  {[type]}  [return description]
		 */
		loadingTable() {
			this.isLoadingTable = true
		},

		/**
		 * Hide Loading if Success
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableSuccess() {
			this.isLoadingTable = false
		},
		/**
		 * Hide Loading if Failed
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableFailed(error) {
			console.log(error)

			this.isLoadingTable = false
			this.$swal({
				title: 'Terjadi Kesalahan',
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
			// }
		},
		submitFavBuying(bvModalEvent) {
			bvModalEvent.preventDefault();
			if (this.nameFavorite === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field name",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let dataPost = {
				name: this.nameFavorite,
				type: 4,
				id_job_order: idJoborder
			}

			let headers = {
				'Content-Type': 'application/json'
			}

			this.saveNewFavorite({
				payload: dataPost,
				headers: headers
			})
				.then((res) => {
					if (res.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalAddFavDPBuying')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())

						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuyingDownPayment(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		openModalAddFavDPBuying() {
			this.$bvModal.show('modalAddFavDPBuying')
		},
		deleteFav(data) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You will not be able to recover this data!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: 'rgb(221, 107, 85)',
				cancelButtonColor: '#C1C1C1',
				reverseButtons: true,
				confirmButtonText: 'Yes, delete it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteFavJobOrder(data.id)
						.then((res) => {
							const Toast = Swal.mixin({
								toast: true,
								position: 'bottom-end',
								showConfirmButton: false,
								timer: 2000,
								timerProgressBar: true,
								onOpen: (toast) => {
									toast.addEventListener('mouseenter', Swal.stopTimer)
									toast.addEventListener('mouseleave', Swal.resumeTimer)
								}
							})

							Toast.fire({
								icon: 'success',
								title: 'successfully'
							})

							this.$refs.vuetable.refresh()
						})
						.catch((err) => {
							this.errorAlert()
							console.log(err.response.message)

							// this.isLoadingTable = false;
						})
				}
			})
		},
		dateFormating(date) {
			return date ? moment(date).format('DD MMM YYYY') : ''
		},
		loadFav(data) {
			let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let dataPost = {
				id_job_order: data.id_job_order,
				type: 'buying_down_payment',
				id_job_order_new: idJoborder
			}

			let headers = {
				'Content-Type': 'application/json'
			}

			this.loadFavorite({
				payload: dataPost,
				headers: headers
			})
				.then((res) => {
					if (res.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalFavBuyingDownPayment')
						})

						// this.$nextTick(() => this.$refs.vuetable.refresh())

						let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						this.getListBuyingDownPayment(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		addDetail(k) {
			this.itemBuyingCopy.push({
				item: '',
				price: '',
				qty: '',
				itemDescription: '',
				truck: '',
				isTruck: 0,
			});
		},
		removeDetail(d) {
			this.itemBuyingCopy.splice(d, 1);
		},
		// checkIsAol() {
		//   try {
		//     this.isAol()
		//       .then((res) => {
		//       })
		//       .catch((err) => {
		//         console.log(err)
		//       })
		//   } catch (error) {
		//     console.log(error)
		//   }
		// },
	},
}
</script>
<style>
.created-field svg {
	color: #51c1ad;
}

.modal-xl {
	max-width: 95% !important;
}

.mx-datepicker .mx-input-wrapper .mx-input {
	height: 31px !important;
}

.container-item {
	min-height: 105px;
	height: 100%;
	background: #D8F8EB;
	border-radius: 3px;
	border: 1px dashed #DADADA;
}

.container-downpayment {
	height: 40px;
	background: #E2F4F1;
	border-radius: 3px;
	display: flex;
	justify-content: start;
	align-items: center;
}

.my-actions {
	margin: 0 2em;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.right-gap {
	margin-right: auto;
}

.button-gamb {
	font-size: 10px !important;
}
</style>

