<template>
  <div>
    <b-button
      class="btn btn-create btn-sm"
      style="background: #51C1AD; border: 1px; height: 35px;"
      @click="openModalBuying"
      v-can="'JOB_ORDER_BUYING_CREATE'"
    >
      <i class="mdi mdi-plus mr-1"></i>
      New Transaction
    </b-button>

    <b-button
      class="btn btn-create btn-sm ml-2"
      style="background: #51C1AD; border: 1px; height: 35px;"
      @click="openModalFav"
      v-can="'JOB_ORDER_FAVORITE_ENABLE'"
    >
      <i class="mdi mdi-bookmark"></i>
    </b-button>

    <b-modal
        id="modalBuying"
        size="xl"
        title="Create New Transaction buying"
        style="max-width: 90% !important;"
        @ok="submitBuying"
        ok-title="Submit"
        no-close-on-backdrop
      >
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <v-select v-show="!isManualNumber" v-model="dataBuying.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumber" v-model="dataBuying.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumber"
                    >
                      <i v-show="!isManualNumber" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumber" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Vendor</label>
                  <v-select v-model="dataBuying.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendor">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Invoice</label>
                  <input v-model="dataBuying.invoice" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataBuying.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Currency</label>
                  <v-select v-model="dataBuying.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataBuying.date" :format="momentFormat" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTgl" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">PPN</label>
                  <v-select v-model="dataBuying.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpn">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataBuying.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCode" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataBuying.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataBuying.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                </div>
              </div>
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataBuying.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                </div>
              </div>
              <!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataBuying.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataBuying.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="mt-3">
          <div v-for="(isiDetail, d) in itemBuying" :key="d" class="row">
            <!-- <div class="row"> -->

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Remark</label>
                <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modalBuyingEdit"
        size="lg"
        title="Edit Info Buying"
        style="max-width: 90% !important;"
        @ok="submitInfoBuying"
        ok-title="Save"
        no-close-on-backdrop
      >
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-4">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleBuying.date" :format="momentFormatEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglEdit" >
                </date-picker>
              </div>
              <div class="col-md-4">
                <label for="">Number</label>
                <div class="input-group">
                  <input v-model="dataSingleBuying.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Invoice</label>
                  <input v-model="dataSingleBuying.invoice_no" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataSingleBuying.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4" v-show="(dataSingleBuying.ppn !== undefined && dataSingleBuying.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataSingleBuying.doc_code" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeEdit" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4" v-show="(dataSingleBuying.ppn !== undefined && dataSingleBuying.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataSingleBuying.tax_type" :taggable="false" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4" v-show="(dataSingleBuying.ppn !== undefined && dataSingleBuying.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSingleBuying.inclusive_tax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Currency</label>
                  <input v-model="dataSingleBuying.symbol" type="text" class="form-control form-control-sm" placeholder="Invoice" required disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSingleBuying.price_kurs" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSingleBuying.fiscal_kurs" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modalBuyingEditItem"
        size="lg"
        title="Edit Item Buying"
        style="max-width: 90% !important;"
        @ok="submitItemBuying"
        ok-title="Save"
        no-close-on-backdrop
      >
      <div>
        <div class="row mt-3">
          <div class="col-md-6">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="dataItemBuying.id_item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required disabled>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="dataItemBuying.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="dataItemBuying.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Remark</label>
                <input v-model="dataItemBuying.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-model="dataItemBuying.id_department" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modalBuyingItemNew"
        size="xl"
        title="Add Item Buying"
        style="max-width: 90% !important;"
        @ok="submitNewItemBuying"
        ok-title="Save"
        no-close-on-backdrop
      >
      <div class="mt-3">
          <div v-for="(isiDetail, d) in newItemBuying" :key="d" class="row">
            <!-- <div class="row"> -->

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Remark</label>
                <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && newItemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeNewDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === newItemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addNewDetail(isiDetail.idJobOrderBuying)"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div>
          </div>
        </div>
    </b-modal>
    <b-modal
      id="modalFav"
      size="lg"
      title="Favorite Job Order"
      style="max-width: 90% !important;"
      ok-title="Save"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <div class="mt-3">
        <div class="row">
          <div class="col-12 float-right">
            <b-button
              class="btn btn-create btn-sm float-right"
              style="background: #51C1AD; border: 1px; height: 35px;"
              @click="openModalAddFav"
              v-can="'JOB_ORDER_FAVORITE_CREATE'"
            >
              Add
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-9">
            <div class="float-left">
              <div class="form-inline">
                <label class="mb-2 mr-sm-2">Search :</label>
                <input
                  v-model="filterText"
                  type="text"
                  class="form-control form-control-sm mb-2"
                  placeholder="Search . . ."
                  @keyup="doFilter"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <vuetable
            ref="vuetable"
            :per-page="perPage"
            :fields="fields"
            :api-url="apiUrl"
            :api-mode="true"
            :http-options="httpOptions"
            :show-sort-icons="true"
            :append-params="moreParams"
            pagination-path
            class="table table-centered table-striped"
            data-path="data"
            @vuetable:loading="loadingTable"
            @vuetable:load-success="loadTableSuccess"
            @vuetable:load-error="loadTableFailed"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="actions" slot-scope="props">
              <div class="table-button-container">
                <a
                  href="javascript:void(0);"
                  class="action-icon mr-1"
                  @click="loadFav(props.rowData)"
                >
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#93EDC7" fill-opacity="0.2"/>
                    <g clip-path="url(#clip0)">
                    <path d="M11.1376 10.2636C11.1734 10.2037 11.1929 10.1499 11.1929 10.1055C11.1929 5.66606 13.4608 4.64258 14.0054 4.30508C14.1 4.24969 14.0943 4.22662 13.9858 4.22662C10.4764 4.22662 8.3083 6.74778 8.3083 10.1055C8.3083 10.1604 8.3098 10.213 8.31259 10.2636H6.28906L9.75061 14.6955L13.2122 10.2636H11.1376Z" fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.7127 13.1482L17.2511 8.71625L13.7896 13.1482H15.8638C15.8283 13.2078 15.8088 13.2614 15.8088 13.3057C15.8088 17.7457 13.5409 18.7685 12.9963 19.106C12.9017 19.162 12.9075 19.1851 13.0159 19.1851C16.5253 19.1851 18.6934 16.6634 18.6934 13.3057C18.6934 13.251 18.6919 13.1986 18.6892 13.1482H20.7127V13.1482Z" fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="15" height="19.4118" fill="white" transform="translate(6 2)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  href="javascript:void(0);"
                  class="action-icon  mr-1"
                  @click="deleteFav(props.rowData)"
                  v-can="'JOB_ORDER_FAVORITE_DELETE'"
                >
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#FFC7C2"/>
                    <path d="M18.654 8.75H8.34598C8.33535 8.87184 8.29707 8.22116 9.04341 18.9866C9.08273 19.5547 9.57769 20 10.1701 20H16.8298C17.4223 20 17.9173 19.5547 17.9566 18.9866C18.7036 8.21187 18.6644 8.86938 18.654 8.75ZM11.4652 17.9981C11.1766 18.0218 10.9269 17.8168 10.902 17.5434L10.382 11.7934C10.357 11.5181 10.5689 11.2759 10.8549 11.2519C11.1409 11.2281 11.3931 11.4316 11.4181 11.7066L11.9381 17.4566C11.9631 17.7319 11.7512 17.9741 11.4652 17.9981ZM14.02 17.5C14.02 17.7762 13.7873 18 13.5 18C13.2127 18 12.98 17.7762 12.98 17.5V11.75C12.98 11.4738 13.2127 11.25 13.5 11.25C13.7873 11.25 14.02 11.4738 14.02 11.75V17.5ZM16.6179 11.7934L16.098 17.5434C16.0746 17.8038 15.8474 18 15.5806 18C15.2733 18 15.0355 17.7474 15.0619 17.4566L15.5819 11.7066C15.6069 11.4316 15.8588 11.2281 16.1451 11.2519C16.4311 11.2759 16.643 11.5181 16.6179 11.7934ZM18.8704 5.75H16.356C16.3613 5.68422 16.3599 5.73338 16.3599 5.08594C16.3599 4.48719 15.8532 4 15.2306 4H11.7694C11.1467 4 10.6401 4.48719 10.6401 5.08594C10.6401 5.72088 10.6387 5.68397 10.644 5.75H8.12954C7.50686 5.75 7.0002 6.23719 7.0002 6.83594C7.0002 7.72916 6.99873 7.68681 7.00377 7.75H19.9962C20.0013 7.68584 19.9998 7.724 19.9998 6.83594C19.9998 6.23719 19.4931 5.75 18.8704 5.75ZM15.3199 5.66406C15.3199 5.71156 15.28 5.75 15.2306 5.75H11.7694C11.72 5.75 11.68 5.71156 11.68 5.66406V5.08594C11.68 5.03844 11.72 5 11.7694 5H15.2306C15.28 5 15.3199 5.03844 15.3199 5.08594V5.66406Z" fill="#EA4C40"/>
                  </svg>
                </a>
              </div>
            </template>
          </vuetable>
        </div>
        <!-- end table -->

        <!-- pagination -->
        <div class="row">
          <div class="col-md-5">
            <div class="row no-gutters">
              <div class="col-lg-3">
                <div class="form-group float-lg-left">
                  <select
                    v-model="perPage"
                    class="form-control form-control-sm"
                    @change="onChangeTableLength"
                  >
                    <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                      {{ opt }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8">
                <vuetable-pagination-info
                  ref="paginationInfo"
                  class="
                    justify-content-center justify-content-lg-start
                    text-center text-lg-left
                  "
                >
                </vuetable-pagination-info>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-7">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              class="
                pagination pagination-rounded
                justify-content-center justify-content-lg-end
              "
              @vuetable-pagination:change-page="onChangePage"
            >
            </vuetable-pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalAddFav"
      size="lg"
      title="Add Favorite Job Order"
      style="max-width: 90% !important;"
      ok-title="Save"
      no-close-on-backdrop
      @ok="submitFavBuying"
    >
      <div class="mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Name</label>
              <input v-model="nameFavorite" type="text" class="form-control form-control-sm" placeholder="Input your favorite Jobfile Name" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="form-row">
      <!-- form type quotation -->
      <div v-for="(item, k) in listBuying" :key="k" class="col-md-12 mt-2">
        <div class="card border">
          <div class="card-header" style="background: #d8f8eb;">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4">
                    <label for="">PI NUMBER: <span>{{item.number}}</span></label>
                  </div>
                  <div class="col-md-4">
                    <label for="">VENDOR: <span>{{item.vendor_name}}</span></label>
                  </div>
                  <div class="col-md-4">
                    <label for="">DATE: <span>{{item.date}}</span></label>
                  </div>
                </div>
                <b-collapse :id="`collapse-${item.id}`" class="mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label for="">COMMERCIAL RATE: <span>{{item.price_kurs}}</span></label>
                    </div>
                    <div class="col-md-4">
                      <label for="">FISCAL RATE: <span>{{item.fiscal_kurs}}</span></label>
                    </div>
                    <div class="col-md-4">
                      <label for="">INVOICE: <span>{{item.invoice_no}}</span></label>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="col-md-3 text-right">
                <a href="javascript:void(0)" class="mr-2" v-b-toggle="`collapse-${item.id}`">
                  <i class="fas fa-angle-down"></i>
                </a>&nbsp;
                <a href="javascript:void(0)" v-show="item.id_job_order === item.curr_joborder" class="btn btn-sm btn-create" @click="editInfoBuying(item.id)" v-can="'JOB_ORDER_BUYING_UPDATE'">
                  <i class="fas fa-edit"></i> Edit
                </a> &nbsp;
                <a href="javascript:void(0)" v-show="item.id_job_order === item.curr_joborder" class="btn btn-sm btn-danger" @click="deleteBuyingData(item.id)" v-can="'JOB_ORDER_BUYING_DELETE'"> <i class="fas fa-trash"></i> Delete
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <div v-for="(isiDetail, d) in item.item" :key="d" class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Item</label>
                  <select v-model="isiDetail.item_id" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                    <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                      {{isi.item_name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Currency</label>
                <select v-model="isiDetail.currency_id" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Currency" required>
                    <option v-for="(isi, key) in listCurrency" :key="key" :value="isi.id">
                      {{isi.code}}
                    </option>
                  </select>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Price</label>
                  <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Remark</label>
                  <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
                </div>
              </div>
              <div class="col-md-2 pt-1">
                <br>
                <a v-show="d || (!d && types[k].item.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d, k)"> <i class="fas fa-trash"></i>
                </a> &nbsp;
                <a v-show="d === types[k].item.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail(k)"><i class="fas fa-plus"></i></a>
                &nbsp;
              </div>
            </div> -->
            <a href="javascript:void(0)" v-show="item.id_job_order === item.curr_joborder" class="btn btn-sm" style="background: #4BC7E2;" @click="addNewItem(item.id)" v-can="'JOB_ORDER_BUYING_UPDATE'"> 
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.50004 0.708344C6.959 0.708344 5.45256 1.16532 4.17123 2.02148C2.88989 2.87764 1.89122 4.09453 1.30148 5.51827C0.71175 6.94201 0.557449 8.50865 0.858092 10.0201C1.15874 11.5315 1.90082 12.9199 2.9905 14.0095C4.08019 15.0992 5.46853 15.8413 6.97997 16.142C8.4914 16.4426 10.058 16.2883 11.4818 15.6986C12.9055 15.1088 14.1224 14.1102 14.9786 12.8288C15.8347 11.5475 16.2917 10.0411 16.2917 8.50001C16.2893 6.43428 15.4676 4.45385 14.0069 2.99316C12.5462 1.53247 10.5658 0.710781 8.50004 0.708344ZM8.50004 14.875C7.23919 14.875 6.00665 14.5011 4.95828 13.8006C3.90992 13.1001 3.09282 12.1045 2.61031 10.9396C2.1278 9.77474 2.00156 8.49294 2.24754 7.25631C2.49352 6.01968 3.10068 4.88376 3.99224 3.9922C4.8838 3.10064 6.01971 2.49348 7.25634 2.2475C8.49297 2.00152 9.77477 2.12777 10.9397 2.61028C12.1045 3.09279 13.1002 3.90989 13.8007 4.95825C14.5012 6.00661 14.875 7.23915 14.875 8.50001C14.873 10.1901 14.2007 11.8104 13.0056 13.0055C11.8105 14.2006 10.1902 14.8729 8.50004 14.875Z" fill="white"/>
                <path d="M12.0417 7.79167H9.20833V4.95833C9.20833 4.77047 9.13371 4.5903 9.00087 4.45747C8.86803 4.32463 8.68786 4.25 8.5 4.25C8.31214 4.25 8.13197 4.32463 7.99913 4.45747C7.86629 4.5903 7.79167 4.77047 7.79167 4.95833V7.79167H4.95833C4.77047 7.79167 4.5903 7.86629 4.45747 7.99913C4.32463 8.13197 4.25 8.31214 4.25 8.5C4.25 8.68786 4.32463 8.86803 4.45747 9.00087C4.5903 9.13371 4.77047 9.20833 4.95833 9.20833H7.79167V12.0417C7.79167 12.2295 7.86629 12.4097 7.99913 12.5425C8.13197 12.6754 8.31214 12.75 8.5 12.75C8.68786 12.75 8.86803 12.6754 9.00087 12.5425C9.13371 12.4097 9.20833 12.2295 9.20833 12.0417V9.20833H12.0417C12.2295 9.20833 12.4097 9.13371 12.5425 9.00087C12.6754 8.86803 12.75 8.68786 12.75 8.5C12.75 8.31214 12.6754 8.13197 12.5425 7.99913C12.4097 7.86629 12.2295 7.79167 12.0417 7.79167Z" fill="white"/>
              </svg>
              Add Item
            </a>
            <table id="tcost1" class="table table-bordered table-striped small mt-2" width="100%">
              <thead>
                <tr style="background:#51C1AD;color:#fff;">
                  <th width="5%">No</th>
                  <th>Item</th>
                  <th>Remarks</th>
                  <th style="text-align: center">Truck</th>
                  <th>Qty</th>
                  <th style="text-align: right">Price</th>
                  <th style="text-align: right">Sub Total (IDR)</th>
                  <th style="text-align: center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dtItem, idx) in item.items" :key="idx">
                  <td>{{idx + 1}}</td>
                  <td>{{dtItem.item_name}}</td>
                  <td>{{dtItem.remarks}}</td>
                  <td style="text-align:center">{{dtItem.truck_no}}</td>
                  <td>{{dtItem.qty}}</td>
                  <td style="text-align:right">{{formatRupiah((dtItem.price).toString(), item.symbol)}}</td>
                  <td style="text-align:right">{{formatRupiah((dtItem.subTotal).toString(), item.symbol_default)}}</td>
                  <td style="text-align:center">
                    <a href="javascript:void(0)" v-show="item.id_job_order === item.curr_joborder" class="btn btn-sm btn-create" @click="editItemBuying(dtItem.id)" v-can="'JOB_ORDER_BUYING_UPDATE'">
                      <i class="fas fa-edit"></i>
                    </a> &nbsp;
                    <a href="javascript:void(0)" v-show="item.id_job_order === item.curr_joborder" class="btn btn-sm btn-danger" v-can="'JOB_ORDER_BUYING_DELETE'"> <i class="fas fa-trash" @click="deleteItemBuying(dtItem.id)"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr style="background:rgba(147, 237, 199, 0.2);">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="text-align:right">Total</td>
                  <td style="text-align:right">{{formatRupiah((item.total).toString(), item.symbol_default)}}</td>
                  <td></td>
                </tr>
                <tr style="background:rgba(147, 237, 199, 0.2);">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="text-align:right"><b v-show="item.inclusive_tax === '1'">Inclusive</b></td>
                  <td style="text-align:right">PPN {{item.ppn}}%</td>
                  <td style="text-align:right">{{formatRupiah((item.totalPPN).toString(), item.symbol_default)}}</td>
                  <td></td>
                </tr>
                <tr v-for="(dtItem, idx) in item.down_payments" :key="idx">
                  <td colspan="2" style="font-weight:bold;">{{ dtItem.number }}</td>
                  <td>{{dtItem.description}}</td>
                  <td></td>
                  <td style="text-align:right"></td>
                  <td style="text-align:right;font-weight:bold;">{{formatRupiah((dtItem.grandTotal).toString(), item.symbol_default)}}</td>
                  <td style="text-align:right;font-weight:bold;">{{formatRupiah((dtItem.grandTotal).toString(), item.symbol_default)}}</td>
                  <td></td>
                </tr>
                <tr style="background:rgba(147, 237, 199, 0.2);">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="text-align:right">GRAND TOTAL</td>
                  <td style="text-align:right">{{formatRupiah((item.grandTotal).toString(), item.symbol_default)}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row" v-show="item.id_aol !== null && item.id_aol === '0'">
              <div class="col-12">
                <a href="javascript:void(0)" class="btn btn-sm" style="background: #009FC2;float: right;" @click="submitToAol(item.id)" v-show="companyIsAol === 1 && item.id_job_order === item.curr_joborder">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 0L3.36206 5.68966H6.20687V8.79311H8.79309V5.68966H11.6379L7.49998 0Z" fill="white"/>
                    <path d="M8.27582 9.82758H6.72408C6.43961 9.82758 6.20685 10.0603 6.20685 10.3448C6.20685 10.6293 6.43961 10.862 6.72408 10.862H8.27582C8.5603 10.862 8.79306 10.6293 8.79306 10.3448C8.79306 10.0603 8.5603 9.82758 8.27582 9.82758Z" fill="white"/>
                    <path d="M8.27582 11.8965H6.72408C6.43961 11.8965 6.20685 12.1293 6.20685 12.4138C6.20685 12.6983 6.43961 12.931 6.72408 12.931H8.27582C8.5603 12.931 8.79306 12.6983 8.79306 12.4138C8.79306 12.1293 8.5603 11.8965 8.27582 11.8965Z" fill="white"/>
                    <path d="M8.27582 13.9655H6.72408C6.43961 13.9655 6.20685 14.1983 6.20685 14.4828C6.20685 14.7672 6.43961 15 6.72408 15H8.27582C8.5603 15 8.79306 14.7672 8.79306 14.4828C8.79306 14.1983 8.5603 13.9655 8.27582 13.9655Z" fill="white"/>
                  </svg>
                  Submit AOL
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2'
// import DatePicker from 'vue2-datepicker'
// import Vue from 'vue'

// var url = '/quotation'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    // url = '/quotation?admin=true'
  }
}
// Vue.component('my-detail-row', DetailRow)

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // filterText: '',
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + "/FavoriteJobOrder/2",
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Quotation',
          href: '/quotation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      fields: [
        {
          name: 'name',
          title: 'Name',
          // sortField: 'number',
        },
        {
          name: 'id_number',
          title: 'Job Order No',
          // sortField: 'subject',
        },
        {
          name: 'created_at_format',
          title: 'Created Date',
          // sortField: 'customer_id',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      momentFormat: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuying.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuying.date).format('YYYY-MM-DD') : ''
        },
      },
      moreParams: {},
      filterText: '',
      deleteData: {},
      totalPrice: 0,
      dataBuying: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        invoice: '',
        vendor: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        commercialRate: '',
        fiscalRate: '',
        inclusiveTax: false
      },
      types: [{
        type: '',
        item: [{
          item: '',
          price: '',
          // qty: '',
          remarks: '',
          item_id: '',
          curr:'',
          currency_id:''
        }]
      }],
      showTaxType: true,
      itemBuying: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0
      }],
      newItemBuying: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0
      }],
      inclusiveTax: 0,
      detail: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        item_id: '',
        curr:''
      }],
      isManualNumber: false,
      isTruck: false,
      isCopyTransaction: "0",
      typeCopy: 'Selling',
      joborderCopy: '',
      numberCopy: '',
      remarksCopy: '',
      listJobOrderCopy: [],
      listNumberCopy: [],
      listType: [],
      // isLoadingTable: false,
      // httpOptions: {
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   },
      // },
      // apiUrl: this.$store.state.rootApi + url,
      // urlPdf: '#',
      // items: [
      //   {
      //     text: 'Oprational',
      //     href: '/',
      //   },
      //   {
      //     text: 'job_order',
      //     href: '/job_order',
      //   },
      //   {
      //     text: 'List',
      //     active: true,
      //   },
      // ],
      // lengthMenu: [10, 25, 50, 100],
      // perPage: 10,
      // fields: [
      //   {
      //     name: 'no',
      //     title: '#',
      //   },
      //   {
      //     name: 'id',
      //     title: 'No',
      //   },
      //   {
      //     name: 'number',
      //     title: 'Job No.',
      //   },
      //   {
      //     name: 'subject',
      //     title: 'Customer',
      //   },
      //   {
      //     name: '-',
      //     title: 'BL Number',
      //   },
      //   {
      //     name: '-',
      //     title: 'DO Number',
      //   },
      //   {
      //     name: '-',
      //     title: 'Suffing',
      //   },
      //   {
      //     name: '-',
      //     title: 'Marketing',
      //   },
      //   {
      //     name: 'customer.number',
      //     title: 'Created By',
      //   },
      //   {
      //     name: 'status',
      //     title: 'Status',
      //   },
      //   {
      //     name: 'info',
      //     title: 'Info',
      //   },
      //   {
      //     name: 'action',
      //     title: 'Action',
      //   },
      // ],
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      marketingData: [],
      rangeData: 'customData',
      topCustomerData: 'Select Number',
      isJobOrderNo: true,
      compareData: false,
      isManualTopCustData: false,
      monthData: '01',
      showRate: false,
      yearData: null,
      nameFavorite: '',
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      customerData: [],
    }
  },
  computed: {
    ...mapState('jobOrder', ['createdField']),
    ...mapState('customer', ['listCustomerQuo']),
    ...mapState('port', ['listPort']),
    ...mapState('vendor', ['listVendor']),
    ...mapState('truck', ['listTruck']),
    ...mapState('autonumber', ['listAutonumber']),
    ...mapState('currency', ['listCurrency','dataCurrency', 'dataDefaultCurrency']),
    ...mapState('item', ['listItem']),
    ...mapState('term', ['listTerm']),
    ...mapState('company', ['companyIsAol']),
    ...mapState('jobOrderBuying', ['listDocCode', 'listTaxTypeBuying', 'listBuying', 'dataSingleBuying', 'dataItemBuying']),
    ...mapState('percentage', ['listPercentage']),
  },
  mounted() {
    this.getCreatedField()
    this.getListCustomerQuo()
    this.getListPort()
    this.getListVendor()
    // this.getListAutonumber(25)
    this.p_getDefaultCurrency()
    this.initCurrency()
    // this.getListTerm()
    // this.getListItem()
    this.getListPercentage()
    this.getListTruck()
    this.getListDocCode()
    let idJoborder = window.location.href.split("/").splice(0, 6)[5];
    this.getListBuying(idJoborder)
    this.isAol();
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    ...mapActions('jobOrder', ['getCreatedField']),
    ...mapActions('customer', ['getListCustomerQuo']),
    ...mapActions('port', ['getListPort']),
    ...mapActions('vendor', ['getListVendor']),
    ...mapActions('truck', ['getListTruck']),
    ...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod']),
    ...mapActions('currency', ['getListCurrency', 'getDefaultCurrency']),
    ...mapActions('item', ['getItemByPpn']),
    ...mapActions('term', ['getListTerm']),
    ...mapActions('quotation', ['saveQuotation']),
    ...mapActions('percentage', ['getListPercentage']), 
    ...mapActions('company', ['isAol']), 
    ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
    ...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
    ...mapActions('jobOrderBuying', ['getListDocCode', 'getListTaxTypeBuying', 'saveBuying', 'getListBuying', 'getBuying', 'updateBuying', 'getItemBuying', 'updateBuyingItem', 'saveNewBuyingItem', 'deleteBuyingItem', 'deleteBuying', 'submitAOL']), 
    setJson(payload) {
      this.json = JSON.parse(payload)
    },
    firstCapital(payload) {
      return payload[0].toUpperCase() + payload.slice(1)
    },
    getTgl() {
      this.dataBuying.date = moment(this.dataBuying.date).format('YYYY-MM-DD')
    },
    getTglEdit() {
      this.dataSingleBuying.date = moment(this.dataSingleBuying.date).format('YYYY-MM-DD')
    },
    // doFilter() {
    //   this.onFilterSet(this.filterText)
    // },
    // doFilterMultipleKeywords() {
    //   this.onFilterSetMultipleKeywords()
    // },
    // /**
    //  * Table Length Change
    //  *
    //  * @return  {[type]}  [return description]
    //  */
    // onChangeTableLength() {
    //   // this.$refs.vuetable.reload()
    //   this.$nextTick(() => this.$refs.vuetable.refresh())
    // },
    // /**
    //  * Pagination page change
    //  */
    // onChangePage(page) {
    //   this.$refs.vuetable.changePage(page)
    // },
    // /**
    //  * Pagination info and pagination show
    //  */
    // onPaginationData(paginationData) {
    //   this.$refs.pagination.setPaginationData(paginationData)
    //   this.$refs.paginationInfo.setPaginationData(paginationData)
    // },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    // onFilterSet(filterText) {
    //   this.moreParams = {
    //     search: filterText,
    //   }
    //   this.$nextTick(() => this.$refs.vuetable.refresh())
    // },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      let strMarketing = []
      this.marketingData.forEach((data, idx) => {
        strMarketing.push(data.id_user)
      })

      let strCustomer = []
      this.customerData.forEach((data, idx) => {
        strCustomer.push(data.id)
      })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }

      this.moreParams = {
        branch: localStorage.getItem('default_branch'),
        customer: strCustomer,
        jobfileType: this.jobfileTypeData,
        startDate: tempStartDateData,
        endDate: tempEndDateData,
        sales: strMarketing,
        status: this.statusData,
        perPage: 10,
        filter: true,
      }
      // console.log(this.moreParams, 'THIS PARAMSSS')
      // console.log(filterText)
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    // loadingTable() {
    //   this.isLoadingTable = true
    // },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    // loadTableSuccess() {
    //   this.isLoadingTable = false
    // },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    // loadTableFailed(error) {
    //   console.log(error)

    //   this.isLoadingTable = false
    //   this.$swal({
    //     title: 'Terjadi Kesalahan',
    //     type: 'error',
    //     timer: '2000',
    //     toast: true,
    //     position: 'bottom-end',
    //   })
    //   // }
    // },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },
    // getListTerm() {
    //   try {
    //     // let strBranch = ''
    //     // this.branchData1.forEach((data, idx) => {
    //     //   if (idx === 0) {
    //     //     strBranch += 'id_branch[]=' + data.id_branch
    //     //   } else {
    //     //     strBranch += '&id_branch[]=' + data.id_branch
    //     //   }
    //     // })
    //     this.getListTerm()
    //       .then((res) => {
    //         console.log(res, 'res user by branch')
    //         // this.user_id = res.result[1].id
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    initCurrency() {
      try {
        this.getListCurrency()
          .then((res) => {
            // this.dataBuying.currency = 2
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    p_getDefaultCurrency() {
      try {
        this.getDefaultCurrency()
          .then((res) => {
            this.dataBuying.currency = this.dataDefaultCurrency.id
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    openModalCreated() {
      console.log('OKEEE')
      this.$bvModal.show('my-modal')
    },
    hideModalCreated() {
      this.$bvModal.hide('my-modal')
    },

    openModalSubJobCreated() {
      this.$bvModal.show('my-modal-subJob')
    },
    hideModalSubJobCreated() {
      this.$bvModal.hide('my-modal-subJob')
    },

    changeJobOrderNo() {
      this.isManualJobOrderNo = !this.isManualJobOrderNo
      this.isJobOrderNo = !this.isJobOrderNo
      if (this.isJobOrderNo) {
        this.autoJobOrderNo = 15
      }
    },

    onCellClicked(data, field, event) {
      console.log('cellClicked: ', field.customer.name)
      this.$refs.vuetable.toggleDetailRow(data.id)
    },

    onAction(action, rowData, rowIndex) {
      if (action === 'details') {
        this.$router.push({
          name: 'JobOrder Details',
          params: {
            id: rowData.id,
          },
        })
      }
    },
    openModalBuying() {
      console.log('OKEEE')
      // this.dataBuying = {
      //   currency: '',
      //   term: '',
      //   number: '',
      //   numberText: '',
      //   invoice: '',
      //   vendor: '',
      //   date: '',
      //   ppn: '',
      //   docCode: '',
      //   taxType: '',
      //   commercialRate: '',
      //   fiscalRate: '',
      //   inclusiveTax: false
      // }

      this.listAutonumber = []
      this.getListAutonumberFlexmod({
        id_flexmod: localStorage.getItem('flexmodActive'),
        id_module: 25
      })

      this.dataBuying.term = ''
      this.dataBuying.number = ''
      this.dataBuying.numberText = ''
      this.dataBuying.invoice = ''
      this.dataBuying.vendor = ''
      this.dataBuying.date = ''
      this.dataBuying.ppn = ''
      this.dataBuying.docCode = ''
      this.dataBuying.taxType = ''
      this.dataBuying.commercialRate = ''
      this.dataBuying.fiscalRate = ''
      this.dataBuying.inclusiveTax = false

      this.itemBuying = [
        {
          item: '',
          price: '',
          qty: '',
          remarks: '',
          truck: '',
          isTruck: 0,
        }
      ]

      this.$bvModal.show('modalBuying')
    },
    addDetail(k) {
      this.itemBuying.push({
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0,
      });
    },
    addNewDetail(id) {
      this.newItemBuying.push({
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0,
        idJobOrderBuying: id
      });
    },
    removeDetail(d) {
      this.itemBuying.splice(d, 1);
    },
    removeNewDetail(d) {
      this.newItemBuying.splice(d, 1);
    },
    submitBuying(bvModalEvent) {
      bvModalEvent.preventDefault()
      console.log(this.dataBuying, 'THIS DATA BUYINGG')
      console.log(this.itemBuying, 'THIS DATA ITEM BUYINGG')
      console.log('OKEE')

      if(this.dataBuying.number === "" && this.dataBuying.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.vendor === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.invoice === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      // this.dataBuying.currency = this.dataDefaultCurrency.id

      if(this.dataBuying.currency !== this.dataDefaultCurrency.id) {
        if(this.dataBuying.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataBuying.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      let itemIsValid = true;

      this.itemBuying.forEach((dt, idx) => {
        if(dt.item === "" || dt.price === "" || dt.qty === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field item, price, and qty in item row " + (idx+1),
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          itemIsValid = false;
        } 
      })

      if(!itemIsValid) {
        return false;
      }

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        buying: this.dataBuying,
        itemBuying: this.itemBuying
      }

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveBuying({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {      
                this.$bvModal.hide('modalBuying')
              })

              let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              this.getListBuying(idJoborder)
            } else {
              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    triggerManualNumber() {
      this.isManualNumber = !this.isManualNumber
    },
    cekIsTruck(idx) {
      // if(this.itemBuying[idx].isTruck) {
      //   this.itemBuying[idx].isTruck = false
      // } else {
      //   this.itemBuying[idx].isTruck = true
      // }

      console.log(this.itemBuying[idx].isTruck)
    },
    changeVendor() {
      let getVendor = this.listVendor.find(x => x.id === this.dataBuying.vendor)
      // this.dataBuying.term = this.dataBuying.vendor.id_term
      this.getListTerm()
        .then((res) => {
          if(getVendor !== undefined) {
            this.dataBuying.term = getVendor.id_term
          }
          // this.dataBuying.term = this.dataBuying.vendor.id_term
          // let termDefault = this.listTerm.find(x => x.id === this.dataBuying.vendor.id_term)
    
          // if(termDefault !== undefined) {
          //   this.dataBuying.term = termDefault
          // }
        })
    },
    changePpn() {
      console.log(parseInt(this.dataBuying.ppn))
      if(parseInt(this.dataBuying.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }
      console.log(this.showTaxType)
      this.getItemByPpn({
        ppn: this.dataBuying.ppn,
        itemCategory: 1
      })
    },
    changeDocCode() {
      if(this.dataBuying.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
      } else {
        this.showTaxType = true;
      }
      this.getListTaxTypeBuying(this.dataBuying.docCode)
    },
    changeDocCodeEdit() {
      if(this.dataSingleBuying.doc_code === 'DIGUNGGUNG') {
        this.showTaxType = false;
      } else {
        this.showTaxType = true;
      }
      this.dataSingleBuying.tax_type = ''
      this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
    },
    changeCurrency(value) {
      // this.dataBuying.currency = value

      if(value !== this.dataDefaultCurrency.id) {
        this.showRate = true
      } else {
        this.showRate = false
      }

      // this.dataDefaultCurrency = this.dataBuying.currency
      // console.log(this.dataBuying.currency)
    },
    changeTerm(value) {
      console.log(this.dataBuying.term)
    },
    formatRupiah(angka, prefix){
      // console.log(angka, 'THIS ANGKAA')
      angka = angka.replace(/\./g, ',')
			let numberString = angka.replace(/[^,\d]/g, '').toString()
      // console.log(numberString, 'THIS ANGKAA 2')
			let split   		= numberString.split(',')
			let sisa     		= split[0].length % 3
			let rupiah     		= split[0].substr(0, sisa)
			let ribuan     		= split[0].substr(sisa).match(/\d{3}/gi)
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
    editInfoBuying(id) {
      try {
        this.getBuying(id)
          .then((res) => {
            this.dataSingleBuying.date = new Date(this.dataSingleBuying.date)
            this.getListTerm(this.dataSingleBuying.id_vendor)
              .then((res) => {
                this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
                  .then((res) => {
                    // console.log(this.dataSingleBuying.date.replace(/-/g, '/'))
                    this.$bvModal.show('modalBuyingEdit')
                  })
              })
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    submitInfoBuying(bvModalEvent) {
      // this.$bvModal.hide('modalBuyingEdit')
      bvModalEvent.preventDefault()

      // this.$nextTick(() => {      
      //   this.$bvModal.hide('modalBuyingEdit')
      // })

      if(this.dataSingleBuying.invoice_no === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSingleBuying.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.updateBuying({
          payload: this.dataSingleBuying,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalBuyingEdit')
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    editItemBuying(id) {
      try {
        this.getItemBuying(id)
          .then((res) => {
            this.getItemByPpn({
              ppn: this.dataItemBuying.ppn,
              itemCategory: 1
            })
              .then((res) => {
                // this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
                //   .then((res) => {
                //     // console.log(this.dataSingleBuying.date.replace(/-/g, '/'))
                    this.$bvModal.show('modalBuyingEditItem')
                //   })
              })
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    submitItemBuying(bvModalEvent) {
      // this.$bvModal.hide('modalBuyingEdit')
      bvModalEvent.preventDefault()

      if(this.dataItemBuying.price === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field price",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.updateBuyingItem({
          payload: this.dataItemBuying,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalBuyingEditItem')
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    addNewItem(id) {
      console.log(id)
      let getBuyingData = this.listBuying.find(x => x.id === id)

      this.newItemBuying = [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0,
        idJobOrderBuying: id
      }]

      this.getItemByPpn({
        ppn: getBuyingData.ppn,
        itemCategory: 1
      })
        .then((res) => {
          this.$bvModal.show('modalBuyingItemNew')
        })
    },
    submitNewItemBuying(bvModalEvent) {
      bvModalEvent.preventDefault();
      console.log(this.newItemBuying)

      let itemIsValid = true;

      this.newItemBuying.forEach((dt, idx) => {
        if(dt.item === "" || dt.price === "" || dt.qty === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field item, price, and qty in item row " + (idx+1),
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          itemIsValid = false;
        } 
      })

      if(!itemIsValid) {
        return false;
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.saveNewBuyingItem({
          payload: {
            items: this.newItemBuying
          },
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalBuyingItemNew')
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    deleteItemBuying(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            'Content-Type': 'application/json'
          }

          this.deleteBuyingItem({
              payload: id,
              headers: headers
            })
            .then((res) => {
              console.log(res.data, 'datanya')
              if (res.status) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })

                Toast.fire({
                  icon: 'success',
                  title: 'successfully'
                })

                let idJoborder = window.location.href.split("/").splice(0, 6)[5];
                this.getListBuying(idJoborder)
              } else {
                Swal.fire({
                  title: "Failed!",
                  text: res.message,
                  icon: 'warning',
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  confirmButtonText: 'Ok'
                })
              }
            })
            .catch((err) => {
              console.log(err.response.data)
              Swal.fire({
                title: "Failed!",
                text: err.response.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            })
        }
      })
    },
    deleteBuyingData(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            'Content-Type': 'application/json'
          }

          this.deleteBuying({
              payload: id,
              headers: headers
            })
            .then((res) => {
              console.log(res.data, 'datanya')
              if (res.status) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })

                Toast.fire({
                  icon: 'success',
                  title: 'successfully'
                })

                let idJoborder = window.location.href.split("/").splice(0, 6)[5];
                this.getListBuying(idJoborder)
              } else {
                Swal.fire({
                  title: "Failed!",
                  text: res.message,
                  icon: 'warning',
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  confirmButtonText: 'Ok'
                })
              }
            })
            .catch((err) => {
              console.log(err.response.data)
              Swal.fire({
                title: "Failed!",
                text: err.response.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            })
        }
      })
    },
    submitToAol(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOL({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    openModalFav() {
      this.$bvModal.show('modalFav')
    },
    doFilter() {
      this.onFilterSet(this.filterText)
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    submitFavBuying(bvModalEvent) {
      bvModalEvent.preventDefault();
      if(this.nameFavorite === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field name",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let dataPost = {
        name: this.nameFavorite,
        type: 2,
        id_job_order: idJoborder
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.saveNewFavorite({
          payload: dataPost,
          headers: headers
        })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalAddFav')
            })

            this.$nextTick(() => this.$refs.vuetable.refresh())

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    openModalAddFav() {
      this.$bvModal.show('modalAddFav')
    },
    deleteFav(data) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFavJobOrder(data.id)
            .then((res) => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
    
              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })
    
              this.$refs.vuetable.refresh()
            })
            .catch((err) => {
              this.errorAlert()
              console.log(err.response.message)
    
              // this.isLoadingTable = false;
            })
        }
      })
    },
    loadFav(data) {
      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let dataPost = {
        id_job_order: data.id_job_order,
        type: 'buying',
        id_job_order_new: idJoborder
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.loadFavorite({
          payload: dataPost,
          headers: headers
        })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalFav')
            })

            // this.$nextTick(() => this.$refs.vuetable.refresh())

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    // checkIsAol() {
    //   try {
    //     this.isAol()
    //       .then((res) => {
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
  },
}
</script>
<style>
.created-field svg {
  color: #51c1ad;
}
.modal-xl {
  max-width: 95% !important;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 31px !important;
}
</style>

