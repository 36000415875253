<template >

    <div class="childTable">
        <vuetable ref="vuetable" :fields="fields" :api-url="apiUrl" :api-mode="true" :http-options="httpOptions"
            class="table table-centered table-striped" data-path="data" detail-row-component="my-detail-row">
            <div slot="action" slot-scope="props" v-show="props.rowData.count === (props.rowIndex + 1)">
                <b-dropdown size="md" variant="link" toggle-class="text-decoration-none" no-caret
                    style=" margin-top: -15px; margin-bottom: -15px; margin-right: -10px;">
                    <template slot="button-content">
                        <button class="btn" style=" background-color: #CFFDF5; width: 35px; height: 35px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-three-dots" viewBox="0 0 16 16"
                                style=" position: absolute; right: 22px; bottom: 15px;">
                                <path
                                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                            </svg>
                        </button>
                        <!-- </button> -->
                    </template>
                    <b-dropdown-item href="#" @click.prevent="toDetailTransaction(parentId)">
                        <i class="fa fa-search"></i> Detail transaction
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="onEditTransaction(parentId)"><i class="fa fa-edit"></i>
                        Edit
                        Transaction
                    </b-dropdown-item>


                    <b-dropdown-item href="#" @click.prevent="showBackupModalEdit(props)"><i class="fa fa-edit"></i>
                        Edit
                        Swap Truck Transaction
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="showConfirmDelete(props.rowData.id)">
                        <i class="fa fa-trash-alt"></i> Delete Swap Truck
                    </b-dropdown-item>
                </b-dropdown>

                <button class="btn" @click="showBackupModalItem(props)"
                    style=" background-color: #FFF2D9; width: 35px; height: 35px; margin-top: -15px; margin-bottom: -15px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down-up" viewBox="0 0 16 16"
                        style=" color: #F9A707; position: absolute; margin-left: -7px; margin-top: -7px; ">
                        <path fill-rule="evenodd"
                            d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </button>

                <!-- jika ada suwap mobil -->
                <a class="text-dark" @mouseenter="previewImage(props)" @mouseleave="leavePreviewImage"
                    style="margin-left: -10px;">
                    <i class="fa fa-question-circle" style="font-size: 20px; margin-left: 1em;"></i>
                </a>
            </div>
        </vuetable>

        <b-modal :id="idModal" size="md" centered hide-header hide-footer>
            <div class="text-center">
                <img :src="imageUrl" alt="" width="400px" height="300px">
            </div>
            <div class="mx-4 mt-2">
                <b>Notes</b>
                <br>
                <p>{{ memoItem }}</p>
            </div>
        </b-modal>


        <!-- modal backup trucking -->
        <b-modal :id="idModalAddTruck" size="lg" title="Add Backup Trucking" hide-footer>
            <!-- {{selectedItem.type1.no}} -->
            <div class="row">
                <div class="col-4">
                    <input type="hidden" id="id_parent" v-modal="form.id_job_distribution">
                    <label>Truck Type
                        <span style="color: red">*</span>
                    </label>
                    <v-select :options="truckType" item-text="name" item-value="id" v-model="form.truck_type"
                        :reduce="(option) => option.id" label="name" :class="formRequired.truck_type">
                    </v-select>

                </div>
                <div class="col-4">
                    <label>Choose Truck <span class="text-danger">*</span></label>
                    <v-select :options="chooseTruck" item-text="name" item-value="id" v-model="form.choose_truck"
                        :reduce="(option) => option.id" :class="formRequired.choose_truck" label="name">
                    </v-select>
                </div>
                <div class="col-4">
                    <label for="">Seal Number <span class="text-danger">*</span></label>
                    <input v-model="form.seal_number" :class="formRequired.seal_number" type="text" name="" id=""
                        class="form-control">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <label>Driver Name <span class="text-danger">*</span></label>
                    <v-select :options="driverName" item-text="name" item-value="id" v-model="form.driver_name"
                        :reduce="(option) => option.id" :class="formRequired.driver_name" label="name">
                    </v-select>
                </div>
                <div class="col-4">
                    <label for="">Upload Image</label>
                    <b-form-file type="file" ref="file" v-model="form.file" :class="formRequired.file"
                        @change="uploadFile" />
                </div>
                <div class="col-4">
                    <label for="">Note</label>
                    <input v-model="form.note" :class="formRequired.note" type="text" name="" id=""
                        class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6 text-left">
                    <button style="
                                        background-color: #C4C4C4;
                                        color: white;
                                        width: 100px;
                                        height: 40px;
                                        border: none;
                                        border-radius: 3px;
                                        " @click.prevent="$bvModal.hide(idModalAddTruck)">Cancel</button>
                </div>
                <div class="col-6 text-right">
                    <button style="
                                        background-color: #51C1AD;
                                        color: white;
                                        width: 100px;
                                        height: 40px;
                                        border: none;
                                        border-radius: 3px;
                                        " @click.prevent="onSaveBackupTruck">Save</button>
                </div>
            </div>
        </b-modal>
        <!-- end modal backup trucking -->

        <!-- modal delete trucking -->
        <b-modal id="modal-delete-transaction" hide-header hide-footer>
            <div class="text-center" style="margin: 2rem;">
                <p class="mx-4">Are you sure you want to delete it ?</p>
                <br>

                <button style="
                                    background-color: #C4C4C4;
                                    color: white;
                                    width: 100px;
                                    height: 40px;
                                    border: none;
                                    border-radius: 3px;
                                    margin-right: 5px;
                                    " @click.prevent="$bvModal.hide('modal-delete-transaction')">No</button>

                <button style="
                                    background-color: #51C1AD;
                                    color: white;
                                    width: 100px;
                                    height: 40px;
                                    border: none;
                                    border-radius: 3px;
                                    margin-left: 5px
                                    " @click.prevent="$bvModal.hide('modal-delete-transaction')">Yes</button>
            </div>
        </b-modal>
        <!-- end modal delete trucking -->
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import { mapActions } from 'vuex'

import Swal from 'sweetalert2'
import axios from 'axios'

var url = '/distribution/buckup-truck-item/'


export default {
    components: {
        Vuetable,
    },
    props: {
        rowData: {
            type: Object,
            required: true,
        },
        rowIndex: {
            type: Number,
        },
    },
    mounted() {
        this.idModal = 'imageModal' + this.rowData.id
        this.idModalAddTruck = 'idModalAddTruck' + this.rowData.id
        this.dataChill = this.rowData.backup_truck
        this.parentId = this.rowData.id
        this.form.id_job_distribution = this.rowData.id
        this.listTruckType()
        this.listChooseTruck()
        this.listDriverName()
    },
    data() {
        return {
            parentId: '',
            idModal: '',
            dataChill: [],
            imageUrl: '',
            memoItem: '',
            httpOptions: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },
            apiUrl: this.$store.state.rootApi + url + this.rowData.id,
            lengthMenu: [10, 25, 50, 100],
            perPage: 10,
            fields: [
                {
                    name: 'chill',
                    title: '#',
                },
                {
                    name: 'no',
                    title: 'No',
                },
                {
                    name: 'no_transaction',
                    title: 'No Transaction',
                    sortField: '',
                    dataClass: 'color-transparent noselect',
                    width: "13%",
                },
                {
                    name: 'type_truck',
                    title: 'Type Truck',
                    sortField: '',

                },
                {
                    name: 'no_plat',
                    title: 'No Plat',
                    sortField: '',

                },
                {
                    name: 'driver',
                    title: 'Driver',
                    sortField: '',

                },
                {
                    name: 'loading',
                    title: 'Loading',
                    sortField: '',

                },
                {
                    name: 'unloading',
                    title: 'Unloading',
                    sortField: '',

                },
                {
                    name: 'measurment',
                    title: 'Measurment',
                    sortField: '',

                },
                {
                    name: 'weight',
                    title: 'Weight',
                    sortField: '',

                },
                {
                    name: 'item',
                    title: 'Item',
                    sortField: '',

                },
                {
                    name: 'sub_total',
                    title: 'SubTotal',
                    sortField: '',

                },

                {
                    name: 'action',
                    title: 'Action',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                    width: "14%",

                },
            ],
            isBackupTruck: false,
            selectedItem: '',
            truckType: [],
            chooseTruck: [],
            driverName: [],
            form: {
                truck_type: '',
                choose_truck: '',
                seal_number: '',
                driver_name: '',
                file: '',
                note: '',
                id_job_distribution: '',
                id: ''
            },
            formRequired: {
                truck_type: false,
                choose_truck: false,
                seal_number: false,
                driver_name: false,
                file: false,
                note: false,
            },
            typeModal: ''
        }
    },

    methods: {
        ...mapActions('jobOrderDistribution', [
            'deleteJobOrderDistribution',
            'submitAOL',
            'getTypeOptions',
            'saveDistributionBackupTruck'
        ]),
        loadingTable() {
            this.isLoadingTable = true
        },

        loadTableSuccess() {
            this.isLoadingTable = false
        },

        loadTableFailed(error) {
            console.log(error)

            this.isLoadingTable = false
            this.$swal({
                title: 'Terjadi Kesalahan',
                type: 'error',
                timer: '2000',
                toast: true,
                position: 'bottom-end',
            })
            // }
        },

        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },

        onAction(action) {
            if (action === 'addDistribution') {
                this.$router.push({
                    name: 'JobOrder Add Distribution',
                    params: {
                        id: window.location.href.split("/").splice(0, 6)[5]
                    },
                })
            }
        },

        toDetailTransaction(data) {
            this.$router.push({
                name: 'JobOrder Detail Distribution', params: {
                    id: data
                },
            })
        },
        onEditTransaction(data) {
            // console.log(data.rowData)
            this.$router.push({
                name: 'JobOrder Edit Distribution',
                params: {
                    id: data
                },
            })
        },
        listTruckType() {
            try {
                this.getTypeOptions('data_type_truck')
                    .then((res) => {
                        this.truckType = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listChooseTruck() {
            try {
                this.getTypeOptions('data_truck')
                    .then((res) => {
                        this.chooseTruck = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listDriverName() {
            try {
                this.getTypeOptions('data_driver_name')
                    .then((res) => {
                        this.driverName = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        uploadFile(event) {
            let files = event.target.files[0];

            console.log(files)
            return files;

        },


        onPrintTransaction(data) {
            axios({
                url: this.$store.state.rootApi + '/delivery-letter-pdf',
                method: 'GET',
                responseType: 'blob',
                params: {
                    tipe: data.tipe
                }
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                var fURL = document.createElement('a')

                fURL.href = fileURL
                fURL.setAttribute('download', 'delivery-letter.pdf')
                document.body.appendChild(fURL)

                fURL.click()
            })
        },
        showBackupModalEdit(props) {
            this.form.truck_type = props.rowData.type_truck
            this.form.choose_truck = props.rowData.no_plat_id
            this.form.seal_number = props.rowData.seal_number
            this.form.driver_name = props.rowData.driver_id
            this.form.note = props.rowData.note
            this.form.id = props.rowData.id
            this.$bvModal.show(this.idModalAddTruck)
            this.typeModal = 'edit'
        },

        showBackupModalItem() {
            this.form.truck_type = ''
            this.form.choose_truck = ''
            this.form.seal_number = ''
            this.form.driver_name = ''
            this.form.file = ''
            this.form.note = ''
            this.form.id_job_distribution = this.parentId
            this.$bvModal.show(this.idModalAddTruck)
            this.typeModal = 'add'
        },

        previewImage(props) {
            // console.log(props)
            this.memoItem = props.rowData.note
            this.imageUrl = props.rowData.url
            this.$bvModal.show(this.idModal)
        },

        leavePreviewImage() {
            this.$bvModal.hide(this.idModal)
        },
        onSaveBackupTruck() {

            if (this.form.truck_type === "") {
                this.formRequired.truck_type = 'style-chooser'
            } else {
                this.formRequired.truck_type = false
            }

            if (this.form.choose_truck === '') {
                this.formRequired.choose_truck = 'style-chooser'
            } else {
                this.formRequired.choose_truck = false
            }

            if (this.form.seal_number === '') {
                this.formRequired.seal_number = 'is-invalid'
            } else {
                this.formRequired.seal_number = false
            }

            if (this.form.driver_name === '') {
                this.formRequired.driver_name = 'style-chooser'
            } else {
                this.formRequired.driver_name = false
            }

            if (this.form.file === '') {
                this.formRequired.file = 'is-invalid'
            } else {
                this.formRequired.file = false
            }

            if (this.form.note === '') {
                this.formRequired.note = 'is-invalid'
            } else {
                this.formRequired.note = false
            }


            if (
                // Validasi Truck
                this.form.truck_type !== '' &&
                this.form.choose_truck !== '' &&
                this.form.seal_number !== '' &&
                this.form.driver_name !== '' &&
                this.form.file !== '' &&
                this.form.note !== ''
            ) {
                console.log(this.form)
                const formData = new FormData();

                // this.form.forEach(element => {
                formData.append('truck_type', this.form.truck_type)
                formData.append('choose_truck', this.form.choose_truck)
                formData.append('seal_number', this.form.seal_number)
                formData.append('driver_name', this.form.driver_name)
                formData.append('file', this.form.file)
                formData.append('note', this.form.note)
                formData.append('id_job_distribution', this.form.id_job_distribution)
                // });

                if (this.typeModal === 'add') {
                    axios.post(this.$store.state.rootApi + "/distribution/backup-truck", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            this.successAlert()
                            this.$refs.vuetable.refresh()
                            // window.history.back();

                        })
                        .catch((err) => {

                            if (err.response.status === 422) {
                                let title = []
                                for (let field of Object.values(err.response.data.message)) {
                                    title.push(field)
                                }
                                Swal.fire({
                                    title: 'Error',
                                    html: title.join("<br/><br/>"),
                                    icon: 'error',
                                    confirmButtonColor: 'rgb(221, 107, 85)',
                                    confirmButtonText: 'Ok',
                                })
                            } else {
                                this.errorAlert('Failed save data')
                            }
                        })
                } else {
                    formData.append('id', this.form.id)
                    axios.post(this.$store.state.rootApi + "/distribution/backup-truck/update", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            this.successAlert()
                            this.$refs.vuetable.refresh()
                            // window.history.back();

                        })
                        .catch((err) => {

                            if (err.response.status === 422) {
                                let title = []
                                for (let field of Object.values(err.response.data.message)) {
                                    title.push(field)
                                }
                                Swal.fire({
                                    title: 'Error',
                                    html: title.join("<br/><br/>"),
                                    icon: 'error',
                                    confirmButtonColor: 'rgb(221, 107, 85)',
                                    confirmButtonText: 'Ok',
                                })
                            } else {
                                this.errorAlert('Failed save data')
                            }
                        })

                }
                this.$bvModal.hide(this.idModalAddTruck)




            } else {
                this.$bvModal.show(this.idModalAddTruck)

            }


        },
        showConfirmDelete(id) {
            let self = this
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'rgb(221, 107, 85)',
                cancelButtonColor: '#C1C1C1',
                reverseButtons: true,
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    self.onDelete(id)
                    Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                }
            })
        }, onDelete(id) {

            axios.get(this.$store.state.rootApi + "/distribution/backup-truck/delete/" + id)
                .then((res) => {
                    this.successAlert()
                    this.$refs.vuetable.refresh()
                })
                .catch((err) => {
                    this.errorAlert()
                    console.log(err.response.message)
                })
        },
    },
}
</script>

<style>

</style>
