<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card new-card" style="
            margin-top: 30px;
            box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important;
            border-radius: 5px;
          ">
          <div class="card-body">
            <div class="row align-items-center" style="margin-bottom: 10px">
              <div class="col-6">
                <h3 style="color: #51c1ad"><b>Job Order Information</b></h3>
              </div>

              <div class="col-6 text-right">
                <label style="margin-top: 9px">
                  <b>
                    <span style="color: #51c1ad; font-weight: bold">Info Inputed</span>
                  </b>
                </label>
                <!-- <span id="finish_label" class="btn data-term" style="background-color: #51C1AD; color:#fff"></span> -->
                <a class="btn ml-2" style="background-color: #51c1ad; color: #fff"><svg width="20" height="20"
                    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.0049 18.1257C6.91486 18.126 4.06549 16.3554 2.7008 13.5685C1.31986 10.7476 1.73799 7.22948 3.74174 4.81417C5.7433 2.40136 8.99736 1.34854 12.0311 2.13198C12.3652 2.21823 12.5661 2.55917 12.4802 2.89354C12.3939 3.22792 12.0527 3.42854 11.7186 3.34261C9.15236 2.67979 6.39799 3.57042 4.70393 5.61261C3.00893 7.65604 2.65518 10.6326 3.82361 13.0195C4.98768 15.397 7.55549 16.9457 10.1911 16.8739C12.8264 16.802 15.218 15.1992 16.2839 12.7907C16.9099 11.3764 17.0424 9.77479 16.6564 8.28073C16.5702 7.94667 16.7711 7.60573 17.1055 7.51917C17.4389 7.43261 17.7805 7.63386 17.867 7.96823C18.3227 9.73354 18.1667 11.626 17.4274 13.2967C16.1674 16.1435 13.3402 18.0382 10.2255 18.1232C10.1517 18.1248 10.078 18.1257 10.0049 18.1257Z"
                      fill="white" />
                    <path
                      d="M10.0002 11.9501C9.84017 11.9501 9.68017 11.8892 9.55829 11.767C9.31423 11.5229 9.31423 11.1273 9.55829 10.8832L17.0583 3.38324C17.302 3.13918 17.6983 3.13918 17.942 3.38324C18.1861 3.6273 18.1861 4.02293 17.942 4.26699L10.442 11.767C10.3202 11.8889 10.1602 11.9501 10.0002 11.9501Z"
                      fill="white" />
                    <path
                      d="M10.0001 11.9498C9.84012 11.9498 9.68012 11.8889 9.55824 11.7667L6.90668 9.11514C6.66262 8.87107 6.66262 8.47545 6.90668 8.23139C7.15043 7.98732 7.54668 7.98732 7.79043 8.23139L10.442 10.8829C10.6861 11.127 10.6861 11.5226 10.442 11.7667C10.3201 11.8886 10.1601 11.9498 10.0001 11.9498Z"
                      fill="white" />
                  </svg>

                  <span>Finish Buying</span></a>
              </div>
            </div>

            <div class="card" style="
                border: 0px !important;
                background-color: #f9fafa;
                border-radius: 5px;
              ">
              <div class="card-header" style="
                  background-color: #51c1ad !important;
                  color: #fff;
                  font-size: 12px;
                  border-radius: 5px !important;
                ">
                <div class="row">
                  <div class="col-md-4 less-margin mb-2">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.19434" cy="6" r="5.5" stroke="white" />
                            <ellipse cx="6.19434" cy="3" rx="1" ry="1" fill="white" />
                            <ellipse cx="6.19434" cy="6" rx="1" ry="1" fill="white" />
                            <ellipse cx="6.19434" cy="9" rx="1" ry="1" fill="white" />
                          </svg>
                        </span>
                        <label>Job Number</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <input type="hidden" v-model="jobNumber" />
                        {{ jobNumber }}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 less-margin">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.65723" cy="6" r="6" fill="white" />
                            <g clip-path="url(#clip0_7183_10574)">
                              <path
                                d="M6.65723 1.15625C3.98145 1.15625 1.81348 3.32422 1.81348 6C1.81348 8.67578 3.98145 10.8438 6.65723 10.8438C9.33301 10.8438 11.501 8.67578 11.501 6C11.501 3.32422 9.33301 1.15625 6.65723 1.15625ZM8.46367 7.26953L8.07305 7.75781C8.04741 7.78986 8.01572 7.81655 7.97977 7.83635C7.94382 7.85615 7.90432 7.86867 7.86353 7.87321C7.82274 7.87774 7.78146 7.8742 7.74204 7.86277C7.70262 7.85135 7.66584 7.83228 7.63379 7.80664L6.3252 6.83555C6.23376 6.76234 6.15996 6.66951 6.10925 6.56393C6.05853 6.45835 6.03221 6.34271 6.03223 6.22559V3.1875C6.03223 3.10462 6.06515 3.02513 6.12376 2.96653C6.18236 2.90792 6.26185 2.875 6.34473 2.875H6.96973C7.05261 2.875 7.13209 2.90792 7.1907 2.96653C7.2493 3.02513 7.28223 3.10462 7.28223 3.1875V6L8.41504 6.83008C8.4471 6.85573 8.4738 6.88745 8.4936 6.92342C8.51339 6.95939 8.52591 6.99891 8.53043 7.03973C8.53494 7.08054 8.53137 7.12184 8.51992 7.16127C8.50846 7.2007 8.48935 7.23749 8.46367 7.26953Z"
                                fill="#51C1AD" />
                            </g>
                            <defs>
                              <clipPath id="clip0_7183_10574">
                                <rect width="10" height="10" fill="white" transform="translate(1.65723 1)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <label>ETD</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <input type="hidden" v-model="etd" />
                        {{ etd }}
                      </div>
                    </div>
                  </div>

                  <div class="col md-4 less-margin">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-5">
                          <span class="">
                            <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6.19434" cy="6" r="5.5" stroke="white" />
                              <g clip-path="url(#clip0_7183_10586)">
                                <path
                                  d="M6.19385 6.375C7.12549 6.375 7.88135 5.61914 7.88135 4.6875C7.88135 3.75586 7.12549 3 6.19385 3C5.26221 3 4.50635 3.75586 4.50635 4.6875C4.50635 5.61914 5.26221 6.375 6.19385 6.375ZM7.69385 6.75H7.04814C6.78799 6.86953 6.49854 6.9375 6.19385 6.9375C5.88916 6.9375 5.60088 6.86953 5.33955 6.75H4.69385C3.86533 6.75 3.19385 7.42148 3.19385 8.25V8.4375C3.19385 8.74805 3.4458 9 3.75635 9H8.63135C8.94189 9 9.19385 8.74805 9.19385 8.4375V8.25C9.19385 7.42148 8.52236 6.75 7.69385 6.75Z"
                                  fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_7183_10586">
                                  <rect width="6" height="6" fill="white" transform="translate(3.19385 3)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <label>Marketing</label>
                          <span class="float-right"> : </span>
                        </div>
                        <div class="col-md-7">
                          <input type="hidden" v-model="marketing" />
                          {{ marketing }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 less-margin mb-2">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="23" viewBox="0 0 15 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.3612 8C12.3612 11.0124 9.7753 13.5 6.52776 13.5C3.28023 13.5 0.694336 11.0124 0.694336 8C0.694336 4.98757 3.28023 2.5 6.52776 2.5C9.7753 2.5 12.3612 4.98757 12.3612 8Z"
                              stroke="white" />
                            <g clip-path="url(#clip0_7183_10551)">
                              <path
                                d="M9.50758 5.28311H7.43783V7.73311L6.60993 7.18951L5.78204 7.73311V5.28311H3.71229C3.48462 5.28311 3.29834 5.46686 3.29834 5.69144V10.5914C3.29834 10.816 3.48462 10.9998 3.71229 10.9998H9.50758C9.73525 10.9998 9.92153 10.816 9.92153 10.5914V5.69144C9.92153 5.46686 9.73525 5.28311 9.50758 5.28311Z"
                                fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_7183_10551">
                                <rect width="16.8891" height="14" fill="white" transform="translate(2.30493)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <label>JobType</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <span id="kind_of_shipment_head"> {{ type }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 less-margin mb-2">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.65723" cy="6" r="6" fill="white" />
                            <g clip-path="url(#clip0_7183_10574)">
                              <path
                                d="M6.65723 1.15625C3.98145 1.15625 1.81348 3.32422 1.81348 6C1.81348 8.67578 3.98145 10.8438 6.65723 10.8438C9.33301 10.8438 11.501 8.67578 11.501 6C11.501 3.32422 9.33301 1.15625 6.65723 1.15625ZM8.46367 7.26953L8.07305 7.75781C8.04741 7.78986 8.01572 7.81655 7.97977 7.83635C7.94382 7.85615 7.90432 7.86867 7.86353 7.87321C7.82274 7.87774 7.78146 7.8742 7.74204 7.86277C7.70262 7.85135 7.66584 7.83228 7.63379 7.80664L6.3252 6.83555C6.23376 6.76234 6.15996 6.66951 6.10925 6.56393C6.05853 6.45835 6.03221 6.34271 6.03223 6.22559V3.1875C6.03223 3.10462 6.06515 3.02513 6.12376 2.96653C6.18236 2.90792 6.26185 2.875 6.34473 2.875H6.96973C7.05261 2.875 7.13209 2.90792 7.1907 2.96653C7.2493 3.02513 7.28223 3.10462 7.28223 3.1875V6L8.41504 6.83008C8.4471 6.85573 8.4738 6.88745 8.4936 6.92342C8.51339 6.95939 8.52591 6.99891 8.53043 7.03973C8.53494 7.08054 8.53137 7.12184 8.51992 7.16127C8.50846 7.2007 8.48935 7.23749 8.46367 7.26953Z"
                                fill="#51C1AD" />
                            </g>
                            <defs>
                              <clipPath id="clip0_7183_10574">
                                <rect width="10" height="10" fill="white" transform="translate(1.65723 1)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <label>ETA</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <input type="hidden" v-model="eta" />
                        {{ eta }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 less-margin">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-5">
                          <span class="">
                            <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6.70508" cy="6" r="5.5" stroke="white" />
                              <path
                                d="M9.89062 4.23047L8.36094 3.08203C8.29063 3.0293 8.19531 3 8.09531 3H8V4.5H10V4.42852C10 4.35469 9.96094 4.2832 9.89062 4.23047ZM7.5 4.59375V3H4.375C4.16719 3 4 3.12539 4 3.28125V8.71875C4 8.87461 4.16719 9 4.375 9H9.625C9.83281 9 10 8.87461 10 8.71875V4.875H7.875C7.66875 4.875 7.5 4.74844 7.5 4.59375ZM5 3.84375C5 3.79195 5.05594 3.75 5.125 3.75H6.375C6.44406 3.75 6.5 3.79195 6.5 3.84375V4.03125C6.5 4.08305 6.44406 4.125 6.375 4.125H5.125C5.05594 4.125 5 4.08305 5 4.03125V3.84375ZM5 4.78125V4.59375C5 4.54195 5.05594 4.5 5.125 4.5H6.375C6.44406 4.5 6.5 4.54195 6.5 4.59375V4.78125C6.5 4.83305 6.44406 4.875 6.375 4.875H5.125C5.05594 4.875 5 4.83305 5 4.78125Z"
                                fill="white" />
                            </svg>
                          </span>
                          <label>Quotation</label>
                          <span class="float-right"> : </span>
                        </div>
                        <div class="col-md-7"> - </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 less-margin">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.70508" cy="6" r="5.5" stroke="white" />
                            <path
                              d="M9.89062 4.23047L8.36094 3.08203C8.29063 3.0293 8.19531 3 8.09531 3H8V4.5H10V4.42852C10 4.35469 9.96094 4.2832 9.89062 4.23047ZM7.5 4.59375V3H4.375C4.16719 3 4 3.12539 4 3.28125V8.71875C4 8.87461 4.16719 9 4.375 9H9.625C9.83281 9 10 8.87461 10 8.71875V4.875H7.875C7.66875 4.875 7.5 4.74844 7.5 4.59375ZM5 3.84375C5 3.79195 5.05594 3.75 5.125 3.75H6.375C6.44406 3.75 6.5 3.79195 6.5 3.84375V4.03125C6.5 4.08305 6.44406 4.125 6.375 4.125H5.125C5.05594 4.125 5 4.08305 5 4.03125V3.84375ZM5 4.78125V4.59375C5 4.54195 5.05594 4.5 5.125 4.5H6.375C6.44406 4.5 6.5 4.54195 6.5 4.59375V4.78125C6.5 4.83305 6.44406 4.875 6.375 4.875H5.125C5.05594 4.875 5 4.83305 5 4.78125Z"
                              fill="white" />
                          </svg>
                        </span>
                        <label>Sub Job Order</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <span id="customer_head">-</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 less-margin">
                    <div class="row">
                      <div class="col-md-5">
                        <span class="">
                          <svg width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6.19434" cy="6" r="5.5" stroke="white" />
                            <g clip-path="url(#clip0_7183_10586)">
                              <path
                                d="M6.19385 6.375C7.12549 6.375 7.88135 5.61914 7.88135 4.6875C7.88135 3.75586 7.12549 3 6.19385 3C5.26221 3 4.50635 3.75586 4.50635 4.6875C4.50635 5.61914 5.26221 6.375 6.19385 6.375ZM7.69385 6.75H7.04814C6.78799 6.86953 6.49854 6.9375 6.19385 6.9375C5.88916 6.9375 5.60088 6.86953 5.33955 6.75H4.69385C3.86533 6.75 3.19385 7.42148 3.19385 8.25V8.4375C3.19385 8.74805 3.4458 9 3.75635 9H8.63135C8.94189 9 9.19385 8.74805 9.19385 8.4375V8.25C9.19385 7.42148 8.52236 6.75 7.69385 6.75Z"
                                fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_7183_10586">
                                <rect width="6" height="6" fill="white" transform="translate(3.19385 3)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <label>Customer</label>
                        <span class="float-right"> : </span>
                      </div>
                      <div class="col-md-7">
                        <input type="hidden" v-model="customer" />
                        {{ customer }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 less-margin"> </div>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card new-card" style="
            margin-top: 30px;
            box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important;
            border-radius: 5px;
          ">
          <div class="card-body general-info">
            <b-tabs content-class="mt-3" v-model="tabIndex">
              <b-tab title="General Information" :title-link-class="linkClass(1)">
                <b-card-text>
                  <b-button class="btn btn-create btn-sm ml-2" style="background: #51c1ad; border: 1px; height: 35px"
                    @click="openModalFavGi">
                    <i class="mdi mdi-bookmark"></i>
                  </b-button>
                </b-card-text>
                <form-wizard style="margin-top: 30px" color="#51c1ad" stepSize="sm" @on-complete="onComplete"
                  @on-validate="handleValidation">
                  <tab-content v-for="(item, key) in generalInfo" :key="key" :title="item.title">
                    <div class="row">
                      <div v-for="(
                          items, keys
                        ) in item.company_setting_job_order_item" :key="keys" :json="setJson(items.meta)"
                        class="col-3">
                        <div v-if="json.type === 'textarea' && json.hidden !== '1'">
                          <b-form-group>
                            <label for="" class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>
                            <b-form-textarea :name="items.key" :id="items.key"
                              :placeholder="'Enter Your ' + items.title" v-model="form.parent_id[items.key]" required>
                            </b-form-textarea>
                          </b-form-group>
                        </div>
                        <div v-else-if="json.type === 'text'">
                          <b-form-group>
                            <label for="" class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>

                            <b-form-input :id="items.key" :placeholder="'Enter Your ' + items.title" :name="items.key"
                              v-model="form.parent_id[items.key]"></b-form-input>
                          </b-form-group>
                        </div>
                        <div v-else-if="json.type === 'date'">
                          <b-form-group class="created-field">
                            <label for="" class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>

                            <b-form-datepicker :name="items.key" :id="items.key" v-model="form.parent_id[items.key]"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }" locale="en"></b-form-datepicker>
                          </b-form-group>
                        </div>
                        <div v-else-if="json.type === 'dropdown'">
                          <label class="mt-2">
                            {{ firstCapital(items.title) }}
                            <span v-if="json.is_required === '1'" style="color: red">*</span>
                          </label>
                          <v-select :options="json.options_data" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'number'">
                          <b-form-group>
                            <label for="" class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>
                            <b-form-input :id="items.key" type="number" :name="items.key"
                              v-model="form.parent_id[items.key]" :placeholder="'Enter Your ' + items.title">
                            </b-form-input>
                          </b-form-group>
                        </div>
                        <div v-else-if="json.type === 'select_master_agent'">
                          <label class="mt-2">{{ firstCapital(items.title) }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>

                          <v-select :options="masterAgent" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_airlanes'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterAirlanes" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_currency'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterCurrency" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_depo'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterDepo" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_driver'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterDriver" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_item'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterItem" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_port'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterPort" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_reason'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterReason" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_shipping'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterShipping" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_term'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterTerm" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_truck'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterTruck" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_address'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterAddress" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_authorized'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterAuthorized" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_assignment'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterAssignment" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_ppjk'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterPPJK" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_tax'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterTax" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_vendor'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterVendor" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_branch'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterBranch" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_vessel'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterVessel" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_master_customer'">
                          <label class="mt-2">{{ items.title }}
                            <span style="color: red" v-if="json.required === '1'">*</span>
                          </label>
                          <v-select :options="masterCustomer" item-text="name" item-value="id"
                            v-model="form.parent_id[items.key]" @input="changeSelected(items.key)"
                            :reduce="(option) => option.id" label="name">
                          </v-select>
                        </div>
                        <div v-else-if="json.type === 'select_no_bl'">
                          <b-form-group>
                            <label for="" class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>

                            <b-form-input id="no_bl" :placeholder="'Enter Your ' + items.title" name="no_bl"
                              v-model="noBl"></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <!-- volume get -->

                    <div v-for="(
                        items, keys
                      ) in item.company_setting_job_order_item" :key="keys" :json="setJson(items.meta)">
                      <div v-if="
                        json.type === 'select_type_volume' &&
                        json.hidden !== '1'
                      ">
                        <!-- input hidden for required -->
                        <label for="" class="mt-2" hidden>
                          {{ firstCapital(items.title) }}
                          <span v-if="json.required === '1'" style="color: red">*</span>
                        </label>

                        <b-form-input hidden :id="items.key" :placeholder="'Enter Your ' + items.title"
                          :name="items.key" v-model="form.parent_id[items.key]"></b-form-input>
                        <!-- end input hidden for required -->

                        <div class="row">
                          <div class="col-3">
                            <label class="mt-2">
                              {{ firstCapital(items.title) }}
                              <span v-if="json.required === '1'" style="color: red">*</span>
                            </label>
                            <v-select :options="[
                              { id: 'fcl', name: 'FCL' },
                              { id: 'lcl', name: 'LCL' },
                              { id: 'breakbulk', name: 'Breakbulk' },
                              { id: 'roro', name: 'Roro' },
                            ]" item-text="name" item-value="id" v-model="chooseOption" @input="changeVolume(items.key)"
                              :reduce="(option) => option.id" label="name">
                            </v-select>
                          </div>
                          <div class="col-9" v-if="typeVolume === 'fcl'">
                            <div v-for="(item, k) in continer" :key="k" class="row mb-2">
                              <div class="col-3">
                                <b-form-group>
                                  <label for="" class="mt-2"> Quantity</label>
                                  <b-form-input id="quantity" type="number" name="quantity" v-model="item.qty"
                                    :placeholder="'Enter Your ' + items.title"></b-form-input>
                                </b-form-group>
                              </div>
                              <b-form-group class="col-3">
                                <label class="mt-2">
                                  Type Volume
                                  <span v-if="json.is_required === '1'" style="color: red">*</span>
                                </label>
                                <v-select :options="[
                                  {
                                    id: 'general_purposes',
                                    name: 'General Purposes',
                                  },
                                  {
                                    id: 'reefer_container',
                                    name: 'Reefer Container',
                                  },
                                  {
                                    id: 'flat_rack_container',
                                    name: 'Flat Rack Container',
                                  },
                                  { id: 'open_top', name: 'Open Top' },
                                  { id: 'truck', name: 'Truck' },
                                ]" item-text="name" item-value="id" v-model="item.volumeOption"
                                  @input="typeChangeVolume(k)" :reduce="(option) => option.id" label="name">
                                </v-select>
                              </b-form-group>
                              <b-form-group class="col-3">
                                <label class="mt-2">
                                  Container Size
                                  <span v-if="json.is_required === '1'" style="color: red">*</span>
                                </label>
                                <v-select :options="item.continerOption" item-text="name" item-value="id"
                                  v-model="item.containerSize" :reduce="(option) => option.id" label="name">
                                </v-select>
                              </b-form-group>
                              <div class="col-2">
                                <a v-show="k || (!k && continer.length > 1)" href="javascript:void(0)"
                                  class="btn btn-danger btn-sm" style="margin-top: 43px" @click="removeRemarks(k)">
                                  <i class="fas fa-trash"></i>
                                </a>
                                &nbsp;
                                <a v-show="k === continer.length - 1" href="javascript:void(0)"
                                  class="btn btn-create btn-sm" style="margin-top: 43px" @click="addRemarks"><i
                                    class="fas fa-plus"></i></a>
                                &nbsp;
                              </div>
                            </div>
                          </div>

                          <div class="col row" v-else-if="typeVolume === 'lcl'">
                            <b-form-group class="col-3">
                              <label for="" class="mt-2">
                                Quantity
                                <span v-if="json.required === '1'" style="color: red">*</span>
                              </label>
                              <b-form-input id="quantity" type="number" name="quantity" v-model="volumeLcl[0].qty"
                                :placeholder="'Enter Your ' + items.title"></b-form-input>
                            </b-form-group>
                            <div class="col-3">
                              <label class="mt-2">
                                Type Volume
                                <span v-if="json.is_required === '1'" style="color: red">*</span>
                              </label>
                              <v-select disabled :options="[{ id: 'volume', name: 'Volume' }]" item-text="name"
                                item-value="id" v-model="volumeLcl[0].volumeOption" :reduce="(option) => option.id"
                                label="name">
                              </v-select>
                            </div>
                          </div>
                          <div class="col row" v-else-if="typeVolume === 'breakbulk'">
                            <b-form-group class="col-3">
                              <label for="" class="mt-2">
                                Quantity
                                <span v-if="json.required === '1'" style="color: red">*</span>
                              </label>
                              <b-form-input id="quantity" type="number" name="quantity" v-model="volumeBreakbulk[0].qty"
                                :placeholder="'Enter Your ' + items.title"></b-form-input>
                            </b-form-group>
                            <div class="col-3">
                              <label class="mt-2">
                                Type Volume
                                <span v-if="json.is_required === '1'" style="color: red">*</span>
                              </label>
                              <v-select disabled :options="[{ id: 'mt', name: 'MT' }]" item-text="name" item-value="id"
                                v-model="volumeBreakbulk[0].volumeOption" :reduce="(option) => option.id" label="name">
                              </v-select>
                            </div>
                          </div>
                          <div class="col row" v-else-if="typeVolume === 'roro'">
                            <b-form-group class="col-3">
                              <label for="" class="mt-2">
                                Quantity
                                <span v-if="json.required === '1'" style="color: red">*</span>
                              </label>
                              <b-form-input id="quantity" type="number" name="quantity" v-model="volumeRoro[0].qty"
                                :placeholder="'Enter Your ' + items.title"></b-form-input>
                            </b-form-group>
                            <div class="col-3">
                              <label class="mt-2">
                                Type Volume
                                <span v-if="json.is_required === '1'" style="color: red">*</span>
                              </label>
                              <v-select disabled :options="[{ id: 'unit', name: 'UNIT' }]" item-text="name"
                                item-value="id" v-model="volumeRoro[0].volumeOption" :reduce="(option) => option.id"
                                label="name">
                              </v-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                </form-wizard>
              </b-tab>
              <b-tab title="Buying" :title-item-class="
                checkPermission('JOB_ORDER_BUYING_ENABLE') ? '' : 'd-none'
              ">
                <b-card-text :title-link-class="linkClass(2)">
                  <BuyingList></BuyingList>
                </b-card-text>
              </b-tab>
              <b-tab title="Selling" :title-item-class="
                checkPermission('JOB_ORDER_SELLING_ENABLE') ? '' : 'd-none'
              ">
                <b-card-text :title-link-class="linkClass(3)">
                  <SellingList></SellingList>
                </b-card-text>
              </b-tab>
              <b-tab title="Buying Down Payment" :title-item-class="
                checkPermission('JOB_ORDER_BUYING_ENABLE') ? '' : 'd-none'
              ">
                <b-card-text :title-link-class="linkClass(4)">
                  <BuyingDownPaymentList></BuyingDownPaymentList>
                </b-card-text>
              </b-tab>
              <b-tab title="Selling Down Payment" :title-item-class="
                checkPermission('JOB_ORDER_SELLING_ENABLE') ? '' : 'd-none'
              ">
                <b-card-text :title-link-class="linkClass(5)">
                  <SellingDownPaymentList></SellingDownPaymentList>
                </b-card-text>
              </b-tab>
              <b-tab title="Other Cost">
                <b-card-text :title-link-class="linkClass(6)">Tab contents 3</b-card-text>
              </b-tab>
              <b-tab title="Journal" :title-item-class="
              checkPermission('JOB_ORDER_BUYING_ENABLE') ? '' : 'd-none'">
                <b-card-text :title-link-class="linkClass(7)">
                  <JournalList></JournalList>
                </b-card-text>
              </b-tab>
              <!-- <b-tab title="Selling">
                <b-card-text :title-link-class="linkClass(7)">Tab contents 5</b-card-text>
              </b-tab> -->
              <b-tab title="Distribution">
                <b-card-text :title-link-class="linkClass(8)">
                  <Distribution></Distribution>
                </b-card-text>
              </b-tab>
              <b-tab title="Document" :title-item-class="
              checkPermission('JOB_ORDER_DOCUMENT_ENABLE') ? '' : 'd-none' ">
                <b-card-text :title-link-class="linkClass(9)">
                  <ContactManagement></ContactManagement>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class=" row">
      <div class="col-12">
        <div class="card new-card" style="
            margin-top: 30px;
            box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important;
            border-radius: 5px;
          ">
          <div class="card-body">
            <div class="row">
              <div class="col-2 pr-1">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Sales :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
              <div class="col-2 pr-1">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Cost :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
              <div class="col-2 pr-1">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Margin :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
              <div class="col-2 pr-1">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Refund :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
              <div class="col-2 pr-1">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Balance Sheet :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
              <div class="col-2">
                <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                  <label class="bold">Deposit :</label>
                  <label class="">IDR:000,000</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modalFavGi" size="lg" title="Favorite Job Order" style="max-width: 90% !important" ok-title="Save"
      no-close-on-backdrop :hide-footer="true">
      <vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
      <div class="mt-3">
        <div class="row">
          <div class="col-12 float-right">
            <b-button class="btn btn-create btn-sm float-right" style="background: #51c1ad; border: 1px; height: 35px"
              @click="openModalAddFavGi">
              Add
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-9">
            <div class="float-left">
              <div class="form-inline">
                <label class="mb-2 mr-sm-2">Search :</label>
                <input v-model="filterText" type="text" class="form-control form-control-sm mb-2"
                  placeholder="Search . . ." @keyup="doFilter" />
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
            :http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path
            class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable"
            @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed"
            @vuetable:pagination-data="onPaginationData">
            <template slot="actions" slot-scope="props">
              <div class="table-button-container">
                <a href="javascript:void(0);" class="action-icon mr-1" @click="loadFav(props.rowData)">
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#93EDC7" fill-opacity="0.2" />
                    <g clip-path="url(#clip0)">
                      <path
                        d="M11.1376 10.2636C11.1734 10.2037 11.1929 10.1499 11.1929 10.1055C11.1929 5.66606 13.4608 4.64258 14.0054 4.30508C14.1 4.24969 14.0943 4.22662 13.9858 4.22662C10.4764 4.22662 8.3083 6.74778 8.3083 10.1055C8.3083 10.1604 8.3098 10.213 8.31259 10.2636H6.28906L9.75061 14.6955L13.2122 10.2636H11.1376Z"
                        fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M20.7127 13.1482L17.2511 8.71625L13.7896 13.1482H15.8638C15.8283 13.2078 15.8088 13.2614 15.8088 13.3057C15.8088 17.7457 13.5409 18.7685 12.9963 19.106C12.9017 19.162 12.9075 19.1851 13.0159 19.1851C16.5253 19.1851 18.6934 16.6634 18.6934 13.3057C18.6934 13.251 18.6919 13.1986 18.6892 13.1482H20.7127V13.1482Z"
                        fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="15" height="19.4118" fill="white" transform="translate(6 2)" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="javascript:void(0);" class="action-icon mr-1" @click="deleteFav(props.rowData)">
                  <!-- v-can="'JOB_ORDER_FAVORITE_DELETE'" -->
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#FFC7C2" />
                    <path
                      d="M18.654 8.75H8.34598C8.33535 8.87184 8.29707 8.22116 9.04341 18.9866C9.08273 19.5547 9.57769 20 10.1701 20H16.8298C17.4223 20 17.9173 19.5547 17.9566 18.9866C18.7036 8.21187 18.6644 8.86938 18.654 8.75ZM11.4652 17.9981C11.1766 18.0218 10.9269 17.8168 10.902 17.5434L10.382 11.7934C10.357 11.5181 10.5689 11.2759 10.8549 11.2519C11.1409 11.2281 11.3931 11.4316 11.4181 11.7066L11.9381 17.4566C11.9631 17.7319 11.7512 17.9741 11.4652 17.9981ZM14.02 17.5C14.02 17.7762 13.7873 18 13.5 18C13.2127 18 12.98 17.7762 12.98 17.5V11.75C12.98 11.4738 13.2127 11.25 13.5 11.25C13.7873 11.25 14.02 11.4738 14.02 11.75V17.5ZM16.6179 11.7934L16.098 17.5434C16.0746 17.8038 15.8474 18 15.5806 18C15.2733 18 15.0355 17.7474 15.0619 17.4566L15.5819 11.7066C15.6069 11.4316 15.8588 11.2281 16.1451 11.2519C16.4311 11.2759 16.643 11.5181 16.6179 11.7934ZM18.8704 5.75H16.356C16.3613 5.68422 16.3599 5.73338 16.3599 5.08594C16.3599 4.48719 15.8532 4 15.2306 4H11.7694C11.1467 4 10.6401 4.48719 10.6401 5.08594C10.6401 5.72088 10.6387 5.68397 10.644 5.75H8.12954C7.50686 5.75 7.0002 6.23719 7.0002 6.83594C7.0002 7.72916 6.99873 7.68681 7.00377 7.75H19.9962C20.0013 7.68584 19.9998 7.724 19.9998 6.83594C19.9998 6.23719 19.4931 5.75 18.8704 5.75ZM15.3199 5.66406C15.3199 5.71156 15.28 5.75 15.2306 5.75H11.7694C11.72 5.75 11.68 5.71156 11.68 5.66406V5.08594C11.68 5.03844 11.72 5 11.7694 5H15.2306C15.28 5 15.3199 5.03844 15.3199 5.08594V5.66406Z"
                      fill="#EA4C40" />
                  </svg>
                </a>
              </div>
            </template>
          </vuetable>
        </div>
        <!-- end table -->

        <!-- pagination -->
        <div class="row">
          <div class="col-md-5">
            <div class="row no-gutters">
              <div class="col-lg-3">
                <div class="form-group float-lg-left">
                  <select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
                    <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                      {{ opt }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8">
                <vuetable-pagination-info ref="paginationInfo" class="
                    justify-content-center justify-content-lg-start
                    text-center text-lg-left
                  ">
                </vuetable-pagination-info>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-7">
            <vuetable-pagination ref="pagination" :css="css.pagination" class="
                pagination pagination-rounded
                justify-content-center justify-content-lg-end
              " @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modalAddFavGi" size="lg" title="Add Favorite Job Order" style="max-width: 90% !important"
      ok-title="Save" no-close-on-backdrop @ok="submitFavBuying">
      <div class="mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Name</label>
              <input v-model="nameFavorite" type="text" class="form-control form-control-sm"
                placeholder="Input your favorite Jobfile Name" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import appConfig from '@src/app.config'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Layout from '@layouts/main'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapState, mapActions } from 'vuex'
import BuyingList from '@components/JobOrder/Buying/BuyingList.vue'
import JournalList from '@components/JobOrder/Journal/JournalList.vue'
import SellingList from '@components/JobOrder/Selling/SellingList.vue'
import SellingDownPaymentList from '@components/JobOrder/SellingDownPayment/SellingDownPaymentList.vue'
import BuyingDownPaymentList from '@components/JobOrder/BuyingDownPayment/BuyingDownPaymentList.vue'
import { authComputed } from '@state/helpers'
import Swal from 'sweetalert2'
import ContactManagement from '@components/JobOrder/Document/DocumentList.vue'
import { required } from 'vuelidate/lib/validators'
import Distribution from '@components/JobOrder/Distribution/Distribution.vue'

export default {
  page: {
    title: 'Leads Customer',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    FormWizard,
    TabContent,
    BuyingList,
    JournalList,
    SellingList,
    SellingDownPaymentList,
    BuyingDownPaymentList,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Distribution,
    ContactManagement
  },
  props: {},

  computed: {
    ...mapState('jobOrder', ['generalInfo', 'createdField', 'setListMaster']),
    stepperProgress() {
      return (100 / 1) * (this.step - 1) + '%'
    },
    ...authComputed,
  },
  data() {
    return {
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + '/FavoriteJobOrder/1',
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Quotation',
          href: '/quotation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      fields: [
        {
          name: 'name',
          title: 'Name',
          // sortField: 'number',
        },
        {
          name: 'id_number',
          title: 'Job Order No',
          // sortField: 'subject',
        },
        {
          name: 'created_at_format',
          title: 'Created Date',
          // sortField: 'customer_id',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      css: {
        pagination: {
          activeClass: 'btn-primary',
          pageClass: 'btn-sm btn-rounded',
          linkClass: 'btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      tabIndex: parseInt(localStorage.getItem('tabIndexActiveJobOrder')),
      validationRules: [{ email: { required } }],
      formData: {
        email: null,
      },
      idFlexmod: '',
      filterText: '',
      selected: null,
      jobNumber: '',
      etd: '',
      marketing: '',
      eta: '',
      customer: '',
      step: 1,
      form: {
        parent_id: [],
      },
      masterAgent: '',
      masterAirlanes: '',
      masterCurrency: '',
      masterDepo: '',
      masterDriver: '',
      masterItem: '',
      masterPort: '',
      masterReason: '',
      masterShipping: '',
      masterTerm: '',
      masterTruck: '',
      masterAddress: '',
      masterAuthorized: '',
      masterAssignment: '',
      masterPPJK: '',
      masterTax: '',
      masterBranch: '',
      masterVendor: '',
      masterVessel: '',
      masterCustomer: '',
      selectBranch: '',
      selectCustomer: '',
      selectSales: '',
      noBl: '',
      branch_id: '',
      typeVolume: '',
      chooseOption: '',
      quantity: '',
      continer: [
        {
          continerOption: '',
          volumeOption: '',
          containerSize: '',
          qty: '',
        },
      ],
      volumeLcl: [
        {
          qty: '',
          volumeOption: 'volume',
        },
      ],
      volumeBreakbulk: [
        {
          qty: '',
          volumeOption: 'mt',
        },
      ],
      volumeRoro: [
        {
          qty: '',
          volumeOption: 'unit',
        },
      ],
      remarks: [
        {
          value: '',
        },
      ],
      size_container: [
        { id: '20', name: '20' },
        { id: '20HC', name: '20HC' },
        { id: '21', name: '21' },
        { id: '40', name: '40' },
        { id: '40HC', name: '40 HC' },
        { id: '45', name: '45' },
        { id: '45HC', name: '45 HC' },
      ],
      loadingWizard: false,
      errorMsg: null,
      count: 0,
      nameFavorite: '',
      moreParams: '',
    }
  },
  mounted() {
    this.getInformation()
    this.getGeneralInfo(window.location.href.split('/').splice(0, 6)[5])
    this.getCreatedField()
    this.fetchData()
    this.getApiMaster()
  },
  methods: {
    ...mapActions('jobOrder', [
      'getGeneralInfo',
      'getCreatedField',
      'getListMaster',
      'detailsJobOrder',
      'storeJobOrderInfomation',
      'detailsJobOrderInfomation',
      'getListCustomer',
      'getListVendor',
    ]),
    ...mapActions('favoriteJobOrder', [
      'saveNewFavorite',
      'loadFavorite',
      'deleteFavJobOrder',
    ]),

    // checkActiveTab() {
    //   // if (localStorage.getItem('tabIndexActiveJobOrder') === idx) {
    //   //   return false
    //   // } else {
    //   //   return true
    //   // }
    //   let status = false;

    //   switch (localStorage.getItem('tabIndexActiveJobOrder')) {
    //     case 1:
    //       status = true
    //       break;
    //     case 7:
    //       status = true
    //       break;
    //     default:
    //       break;
    //   }
    //   return status
    // },
    linkClass(idx) {
      if (this.tabIndex !== 0) {
        if (idx === this.tabIndex) {
          // console.log(idx, 'oke')
          localStorage.setItem('tabIndexActiveJobOrder', idx)
        }
      } else {
        localStorage.setItem('tabIndexActiveJobOrder', 0)
      }
    },

    addRemarks() {
      if (!this.continer) {
        return
      }
      this.continer.push({
        containerSize: '',
        continerOption: '',
      })
    },
    hasError() {
      return this.formData.email === null ? 'true' : 'false'
    },
    removeRemarks(x) {
      console.log(x, 'index removeremarks')
      this.continer.splice(x, 1)
    },
    onComplete() {
      const volumeFcl = { volume: this.continer }
      const data = {
        id_job_order: this.$route.params.id,
        no_bl: this.noBl,
        choose_option: this.chooseOption,
      }
      this.generalInfo.forEach((item) => {
        item.company_setting_job_order_item.forEach((el) => {
          Object.assign(data, {
            [el.key]:
              this.form.parent_id[el.key] === undefined
                ? ''
                : this.form.parent_id[el.key],
          })
        })
      })
      console.log(data, 'data')

      let headers = {
        'Content-Type': 'application/json',
      }

      this.storeJobOrderInfomation({
        payload: data,
        volume: volumeFcl,
        volumeLcl: this.volumeLcl,
        volumeBb: this.volumeBreakbulk,
        volumeRoro: this.volumeRoro,
        headers: headers,
      })
        .then((res) => {
          if (res.data !== 0) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })
            Toast.fire({
              icon: 'success',
              title: res.message,
            })
            this.$router.go()
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            let title = []
            for (let field of Object.keys(err.response.data.message)) {
              title.push(field)
            }
            Swal.fire({
              title: 'Error',
              text: 'Please insert field ' + title.join(),
              icon: 'error',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          } else {
            console.log(err)
          }
        })
    },
    setLoading: function (value) {
      this.loadingWizard = value
    },
    handleValidation: function (isValid, tabIndex) {
      console.log('Tab: ' + tabIndex + ' valid: ' + isValid)
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg
    },
    validateAsync: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.count < 1) {
            this.count++
          } else {
            this.count = 0
            resolve(true)
          }
        }, 1000)
      })
    },
    setJson(payload) {
      this.json = JSON.parse(payload)
    },
    firstCapital(payload) {
      return payload[0].toUpperCase() + payload.slice(1)
    },
    hideClass(payload) {
      if (payload === '1') {
        return 'col-3'
      } else {
        return ''
      }
    },
    fetchData() {
      let id = this.$route.params.id
      this.detailsJobOrder(id)
        .then((res) => {
          this.jobNumber = res.id_number
          this.etd = res.meta_field.etd
          this.marketing = res.name_sales
          this.eta = res.meta_field.eta
          this.customer = res.name_customer
          this.type = res.type_job_order
          this.branch_id = res.id_branch
          this.idFlexmod = res.id_flexmod
          this.getApiMaster()
        })
        .catch((err) => {
          console.log(err)
        })
      // alert(id)
    },

    changeSelected(options) {
      let opt = options
      let num = this.form.parent_id[options]
      this.$delete(this.form.parent_id, options)
      this.form.parent_id[opt] = num
    },
    changeVolume(options) {
      let opt = options
      let num = this.chooseOption
      this.form.parent_id[opt] = num
      this.typeVolume = this.chooseOption
    },
    typeChangeVolume(options) {
      console.log(this.continer[options])
      this.continer[options].containerSize = ''
      // this.containerSize.name = null
      let dataSizeContainer = []
      this.size_container.forEach((dat, index) => {
        if (this.continer[options].volumeOption === 'general_purposes') {
          dataSizeContainer.push(dat)
        }
        if (this.continer[options].volumeOption === 'reefer_container') {
          if (
            dat.id === '20' ||
            dat.id === '20HC' ||
            dat.id === '40' ||
            dat.id === '40HC'
          ) {
            dataSizeContainer.push(dat)
          }
        }

        if (this.continer[options].volumeOption === 'flat_rack_container') {
          if (dat.id === '20' || dat.id === '21' || dat.id === '40') {
            dataSizeContainer.push(dat)
          }
        }

        if (this.continer[options].volumeOption === 'open_top') {
          if (dat.id === '20' || dat.id === '40') {
            dataSizeContainer.push(dat)
          }
        }

        if (this.continer[options].volumeOption === 'truck') {
          if (dat.id === '20' || dat.id === '40' || dat.id === '40HC') {
            dataSizeContainer.push(dat)
          }
        }
      })
      this.continer[options].continerOption = dataSizeContainer
    },
    checkPermission(permission) {
      let permissions = JSON.parse(localStorage.userPermissions)

      if (permissions.includes(permission)) {
        return true
      } else {
        return false
      }
    },
    listMasterAgent() {
      try {
        this.getListMaster('select_master_agent')
          .then((res) => {
            this.masterAgent = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAirlanes() {
      try {
        this.getListMaster('select_master_airlanes')
          .then((res) => {
            this.masterAirlanes = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterCurrency() {
      try {
        this.getListMaster('select_master_currency')
          .then((res) => {
            this.masterCurrency = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterDepo() {
      try {
        this.getListMaster('select_master_depo')
          .then((res) => {
            this.masterDepo = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterDriver() {
      try {
        this.getListMaster('select_master_driver')
          .then((res) => {
            this.masterDriver = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterItem() {
      try {
        this.getListMaster('select_master_item')
          .then((res) => {
            this.masterItem = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterPort() {
      try {
        this.getListMaster('select_master_port')
          .then((res) => {
            this.masterPort = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterReason() {
      try {
        this.getListMaster('select_master_reason')
          .then((res) => {
            this.masterReason = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterShipping() {
      try {
        this.getListMaster('select_master_shipping')
          .then((res) => {
            this.masterShipping = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTerm() {
      try {
        this.getListMaster('select_master_term')
          .then((res) => {
            this.masterTerm = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTruck() {
      try {
        this.getListMaster('select_master_truck')
          .then((res) => {
            this.masterTruck = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAddress() {
      try {
        this.getListMaster('select_master_address')
          .then((res) => {
            this.masterAddress = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAuthorized() {
      try {
        this.getListMaster('select_master_authorized')
          .then((res) => {
            this.masterAuthorized = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterAssignment() {
      try {
        this.getListMaster('select_master_assignment')
          .then((res) => {
            this.masterAssignment = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterPPJK() {
      try {
        this.getListMaster('select_master_ppjk')
          .then((res) => {
            this.masterPPJK = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterTax() {
      try {
        this.getListMaster('select_master_tax')
          .then((res) => {
            this.masterTax = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterBranch() {
      try {
        this.getListMaster('select_master_branch')
          .then((res) => {
            this.masterBranch = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterVendor() {
      try {
        let strBranch = ''
        strBranch += 'id_branch=' + this.branch_id
        this.getListVendor(strBranch)
          .then((res) => {
            this.masterVendor = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterVessel() {
      try {
        this.getListMaster('select_master_vessel')
          .then((res) => {
            this.masterVessel = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    listMasterCustomer() {
      try {
        let strBranch = ''
        strBranch += 'id_branch=' + this.branch_id
        this.getListCustomer(strBranch)
          .then((res) => {
            this.masterCustomer = res
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    getApiMaster() {
      this.generalInfo.forEach((item) => {
        item.company_setting_job_order_item.forEach((el) => {
          const json = JSON.parse(el.meta)
          if (json.type === 'select_master_agent') {
            this.listMasterAgent()
          } else if (json.type === 'select_master_airlanes') {
            this.listMasterAirlanes()
          } else if (json.type === 'select_master_currency') {
            this.listMasterCurrency()
          } else if (json.type === 'select_master_depo') {
            this.listMasterDepo()
          } else if (json.type === 'select_master_driver') {
            this.listMasterDriver()
          } else if (json.type === 'select_master_item') {
            this.listMasterItem()
          } else if (json.type === 'select_master_port') {
            this.listMasterPort()
          } else if (json.type === 'select_master_reason') {
            this.listMasterReason()
          } else if (json.type === 'select_master_shipping') {
            this.listMasterShipping()
          } else if (json.type === 'select_master_term') {
            this.listMasterTerm()
          } else if (json.type === 'select_master_truck') {
            this.listMasterTruck()
          } else if (json.type === 'select_master_address') {
            this.listMasterAddress()
          } else if (json.type === 'select_master_authorized') {
            this.listMasterAuthorized()
          } else if (json.type === 'select_master_assignment') {
            this.listMasterAssignment()
          } else if (json.type === 'select_master_ppjk') {
            this.listMasterPPJK()
          } else if (json.type === 'select_master_tax') {
            this.listMasterTax()
          } else if (json.type === 'select_master_vendor') {
            this.listMasterVendor()
          } else if (json.type === 'select_master_branch') {
            this.listMasterBranch()
          } else if (json.type === 'select_master_vessel') {
            this.listMasterVessel()
          } else if (json.type === 'select_master_customer') {
            this.listMasterCustomer()
          }
        })
      })
    },
    getInformation() {
      let id = this.$route.params.id
      this.detailsJobOrderInfomation(id)
        .then((res) => {
          if (res.no_bl != null) {
            this.noBl = res.no_bl
          }

          Object.entries(JSON.parse(res.meta_field)).forEach((entry) => {
            const [key, value] = entry
            this.form.parent_id[key] = value
          })
          if (res.job_order_volume.length > 0) {
            // console.log(res.job_order_volume.length, 'volume')
            this.chooseOption = res.job_order_volume[0].choose_type
            this.typeVolume = res.job_order_volume[0].choose_type
            if (res.job_order_volume[0].choose_type === 'fcl') {
              this.continer = res.job_order_volume
            } else if (res.job_order_volume[0].choose_type === 'lcl') {
              this.volumeLcl = res.job_order_volume
            } else if (res.job_order_volume[0].choose_type === 'breakbulk') {
              this.volumeBreakbulk = res.job_order_volume
            } else if (res.job_order_volume[0].choose_type === 'roro') {
              this.volumeRoro = res.job_order_volume
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    openModalFavGi() {
      this.$bvModal.show('modalFavGi')
    },
    openModalAddFavGi() {
      this.$bvModal.show('modalAddFavGi')
    },
    deleteFav(data) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFavJobOrder(data.id)
            .then((res) => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                },
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully',
              })

              this.$refs.vuetable.refresh()
            })
            .catch((err) => {
              this.errorAlert()
              console.log(err.response.message)

              // this.isLoadingTable = false;
            })
        }
      })
    },
    doFilter() {
      this.onFilterSet(this.filterText)
    },

    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },

    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },

    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },

    loadingTable() {
      this.isLoadingTable = true
    },

    loadTableSuccess() {
      this.isLoadingTable = false
    },

    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    submitFavBuying(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.nameFavorite === '') {
        Swal.fire({
          title: 'Failed!',
          text: 'Please insert field name',
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok',
        })

        return false
      }

      let idJoborder = window.location.href.split('/').splice(0, 6)[5]

      let dataPost = {
        name: this.nameFavorite,
        type: 1,
        id_job_order: idJoborder,
      }

      let headers = {
        'Content-Type': 'application/json',
      }

      this.saveNewFavorite({
        payload: dataPost,
        headers: headers,
      })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully',
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalAddFavGi')
            })

            this.$nextTick(() => this.$refs.vuetable.refresh())

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuying(idJoborder)
          } else {
            Swal.fire({
              title: 'Failed!',
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok',
          })
        })
    },
    loadFav(data) {
      let idJoborder = window.location.href.split('/').splice(0, 6)[5]

      let dataPost = {
        id_job_order: data.id_job_order,
        type: 'general_information',
        id_job_order_new: idJoborder,
      }

      let headers = {
        'Content-Type': 'application/json',
      }

      this.loadFavorite({
        payload: dataPost,
        headers: headers,
      })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully',
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalFav')
            })

            // this.$nextTick(() => this.$refs.vuetable.refresh())
            this.$router.go()
          } else {
            Swal.fire({
              title: 'Failed!',
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok',
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
<style>
.wizard-icon-container i {
  font-style: normal;
}

.wizard-icon-circle i {
  font-style: normal;
}

.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #51c1ad;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  background-color: #51c1ad;
  color: #fff;
}

.nav-tabs .nav-link {
  border: 0px;
  margin-right: 1px;
  color: #fff;
  background-color: #a6a5a5;
  border-radius: 0px;
}

.wizard-icon-container {
  border-radius: 50% !important;
}

.wizard-progress-bar li:first-child:after {
  content: none;
}

/* .general-info svg {
  color: #51c1ad;
} */

.wizard-progress-bar {
  width: (100 / 3) * (5 - 1) '%';
}
</style>
