<template>
  <div>
    <b-button
      class="btn btn-create btn-sm"
      style="background: #51C1AD; border: 1px; height: 35px;"
      @click="openModalSellingDownPayment"
      v-can="'JOB_ORDER_SELLING_CREATE'"
    >
      <i class="mdi mdi-plus mr-1"></i>
      New Transaction
    </b-button>

    <b-button
      class="btn btn-create btn-sm ml-2"
      style="background: #51C1AD; border: 1px; height: 35px;"
      @click="openModalFavSellingDownPayment"
      v-can="'JOB_ORDER_FAVORITE_ENABLE'"
    >
      <i class="mdi mdi-bookmark"></i>
    </b-button>

    <b-modal
        id="modalSellingDownPayment"
        size="xl"
        title="Create New Transaction"
        style="max-width: 90% !important;"
        @ok="submitSelling"
        ok-title="Submit"
        no-close-on-backdrop
      >
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <v-select v-show="!isManualNumberSellingDownPayment" v-model="dataSellingDownPayment.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumberSellingDownPayment" v-model="dataSellingDownPayment.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumberSelling"
                    >
                      <i v-show="!isManualNumberSellingDownPayment" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumberSellingDownPayment" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Customer</label>
                  <v-select v-model="dataSellingDownPayment.customer" :options="listCustomerQuo" :reduce="(option) => option.id" label="name" @input="changeCustomer">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataSellingDownPayment.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Currency</label>
                  <v-select v-model="dataSellingDownPayment.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSellingDownPayment.date" :format="momentFormat" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTgl" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">PO No</label>
                  <input v-model="dataSellingDownPayment.po" type="text" class="form-control form-control-sm" placeholder="PO No" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Down Payment</label>
                  <input v-model="dataSellingDownPayment.downPayment" type="text" class="form-control form-control-sm" placeholder="Down Payment" required />
                </div>
              </div>
              <div class="col-md-1">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="taxable" v-model="dataSellingDownPayment.taxable" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="taxable">Taxable</label>
                </div>
              </div>
              <div class="col-md-2" v-show="dataSellingDownPayment.taxable === '1'">
                <div class="form-group">
                  <label for="">PPN</label>
                  <v-select v-model="dataSellingDownPayment.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpn">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSellingDownPayment.ppn !== undefined && dataSellingDownPayment.ppn > 0 && dataSellingDownPayment.taxable === '1')">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSellingDownPayment.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>
              <!-- <div class="col-md-2" v-show="(dataSellingDownPayment.ppn !== undefined && dataSellingDownPayment.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataSellingDownPayment.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCode" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSellingDownPayment.ppn !== undefined && dataSellingDownPayment.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataSellingDownPayment.taxType" :options="listTaxType" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div> -->
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSellingDownPayment.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                </div>
              </div>
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSellingDownPayment.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Description</label>
                  <textarea v-model="dataSellingDownPayment.description" class="form-control form-control-sm" placeholder="Description" cols="30" rows="3"></textarea>
                </div>
              </div>
              <!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataSellingDownPayment.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="mt-3">
          <div v-for="(isiDetail, d) in itemSelling" :key="d" class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Item Description</label>
                <input v-model="isiDetail.itemDescription" type="text" class="form-control form-control-sm" placeholder="Item Description" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemSelling.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemSelling.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </b-modal>
    <b-modal
        id="modalSellingCopy"
        size="xl"
        title="Copy Transaction"
        style="max-width: 90% !important;"
        @ok="submitSellingCopy"
        ok-title="Submit"
        no-close-on-backdrop
      >
      <div>
        <div class="container-downpayment">
          <img src="@assets/images/bank.png" class="img-fluid ml-2" alt style="width: 20px" />
          <span class="ml-3" style="color: #A6A5A5;font-size: 12px;">Down Payment :</span>
          <span class="ml-2" style="color: #585858;font-size: 12px;font-weight: bold;">{{formatRupiah(((this.dataSingleSellingDownPayment.grandTotal === undefined ? 0 : this.dataSingleSellingDownPayment.grandTotal)).toString(), '')}}</span>
          <span class="ml-3" style="color: #A6A5A5;font-size: 12px;">Date :</span>
          <span class="ml-2" style="color: #585858;font-size: 12px;font-weight: bold;">{{ dateFormating(this.dataSingleSellingDownPayment.date) }}</span>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <v-select v-show="!isManualNumberSelling" v-model="dataSellingCopy.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumberSelling" v-model="dataSellingCopy.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumberSelling"
                    >
                      <i v-show="!isManualNumberSelling" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumberSelling" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Customer</label>
                  <v-select v-model="dataSellingCopy.customer" :options="listCustomerQuo" :reduce="(option) => option.id" label="name" @input="changeCustomerCopy" :disabled="true">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataSellingCopy.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm" :disabled="true">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Currency</label>
                  <v-select v-model="dataSellingCopy.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency" :disabled="true">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSellingCopy.date" :format="momentFormatSellingCopy" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTgl" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">PO/Contract No</label>
                  <input v-model="dataSellingCopy.po" type="text" class="form-control form-control-sm" placeholder="PO/Contract No" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">PPN</label>
                  <v-select v-model="dataSellingCopy.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpnCopy">
                  </v-select>
                </div>
              </div>
              <!-- <div class="col-md-2" v-show="(dataSellingCopy.ppn !== undefined && dataSellingCopy.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataSellingCopy.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCode" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSellingCopy.ppn !== undefined && dataSellingCopy.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataSellingCopy.taxType" :options="listTaxType" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div> -->
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSellingCopy.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                </div>
              </div>
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSellingCopy.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                </div>
              </div>
              <!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataSellingCopy.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
              <div class="col-md-2" v-show="(dataSellingCopy.ppn !== undefined && dataSellingCopy.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSellingCopy.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="mt-3">
          <div v-for="(isiDetail, d) in itemSellingCopy" :key="d" class="row">
            <!-- <div class="row"> -->

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Item</label>
                <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty</label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price</label>
                <input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Item Description</label>
                <input v-model="isiDetail.itemDescription" type="text" class="form-control form-control-sm" placeholder="Item Description" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Truck
                </label>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemSelling.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemSelling.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div>
          </div>
        </div>
        
        <!-- <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </b-modal>
    <b-modal
        id="modalSellingDownPaymentEdit"
        size="lg"
        title="Edit Selling Down Payment"
        style="max-width: 90% !important;"
        @ok="submitInfoSelling"
        ok-title="Save"
        no-close-on-backdrop
      >
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-4">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleSellingDownPayment.date" :format="momentFormatEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglEdit" >
                </date-picker>
              </div>
              <div class="col-md-4">
                <label for="">Number</label>
                <div class="input-group">
                  <input v-model="dataSingleSellingDownPayment.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">PO No</label>
                  <input v-model="dataSingleSellingDownPayment.po_no" type="text" class="form-control form-control-sm" placeholder="PO No" required />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataSingleSellingDownPayment.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                  </v-select>
                </div>
              </div>
               <div class="col-md-4">
                <div class="form-group">
                  <label for="">Down Payment</label>
                  <input v-model="dataSingleSellingDownPayment.down_payment" type="text" class="form-control form-control-sm" placeholder="Down Payment" required />
                </div>
              </div>
              <div class="col-md-2">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="taxable" v-model="dataSingleSellingDownPayment.taxable" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="taxable">Taxable</label>
                </div>
              </div>
              <div class="col-md-4" v-show="parseInt(dataSingleSellingDownPayment.taxable) === 1">
                <div class="form-group">
                  <label for="">PPN</label>
                  <v-select v-model="dataSingleSellingDownPayment.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpn">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSingleSellingDownPayment.ppn !== undefined && dataSingleSellingDownPayment.ppn > 0 && parseInt(dataSingleSellingDownPayment.taxable) === 1)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSingleSellingDownPayment.inclusive_tax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Description</label>
                  <textarea v-model="dataSingleSellingDownPayment.description" class="form-control form-control-sm" placeholder="Description" cols="30" rows="3"></textarea>
                </div>
              </div>
              <!-- <div class="col-md-4" v-show="(dataSingleSellingDownPayment.ppn !== undefined && dataSingleSellingDownPayment.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataSingleSellingDownPayment.doc_code" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeEdit" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4" v-show="(dataSingleSellingDownPayment.ppn !== undefined && dataSingleSellingDownPayment.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataSingleSellingDownPayment.tax_type" :taggable="false" :options="listTaxType" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div> -->
              <!-- <div class="col-md-4" v-show="(dataSingleSellingDownPayment.ppn !== undefined && dataSingleSellingDownPayment.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSingleSellingDownPayment.inclusive_tax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div> -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Currency</label>
                  <input v-model="dataSingleSellingDownPayment.symbol" type="text" class="form-control form-control-sm" placeholder="Currency" required disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSingleSellingDownPayment.price_kurs" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSingleSellingDownPayment.fiscal_kurs" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalFavSellingDownPayment"
      size="lg"
      title="Favorite Job Order"
      style="max-width: 90% !important;"
      ok-title="Save"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <div class="mt-3">
        <div class="row">
          <div class="col-12 float-right">
            <b-button
              class="btn btn-create btn-sm float-right"
              style="background: #51C1AD; border: 1px; height: 35px;"
              @click="openModalAddFavDP"
              v-can="'JOB_ORDER_FAVORITE_CREATE'"
            >
              Add
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-9">
            <div class="float-left">
              <div class="form-inline">
                <label class="mb-2 mr-sm-2">Search :</label>
                <input
                  v-model="filterText"
                  type="text"
                  class="form-control form-control-sm mb-2"
                  placeholder="Search . . ."
                  @keyup="doFilter"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <vuetable
            ref="vuetable"
            :per-page="perPage"
            :fields="fields"
            :api-url="apiUrl"
            :api-mode="true"
            :http-options="httpOptions"
            :show-sort-icons="true"
            :append-params="moreParams"
            pagination-path
            class="table table-centered table-striped"
            data-path="data"
            @vuetable:loading="loadingTable"
            @vuetable:load-success="loadTableSuccess"
            @vuetable:load-error="loadTableFailed"
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="actions" slot-scope="props">
              <div class="table-button-container">
                <a
                  href="javascript:void(0);"
                  class="action-icon mr-1"
                  @click="loadFav(props.rowData)"
                >
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#93EDC7" fill-opacity="0.2"/>
                    <g clip-path="url(#clip0)">
                    <path d="M11.1376 10.2636C11.1734 10.2037 11.1929 10.1499 11.1929 10.1055C11.1929 5.66606 13.4608 4.64258 14.0054 4.30508C14.1 4.24969 14.0943 4.22662 13.9858 4.22662C10.4764 4.22662 8.3083 6.74778 8.3083 10.1055C8.3083 10.1604 8.3098 10.213 8.31259 10.2636H6.28906L9.75061 14.6955L13.2122 10.2636H11.1376Z" fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.7127 13.1482L17.2511 8.71625L13.7896 13.1482H15.8638C15.8283 13.2078 15.8088 13.2614 15.8088 13.3057C15.8088 17.7457 13.5409 18.7685 12.9963 19.106C12.9017 19.162 12.9075 19.1851 13.0159 19.1851C16.5253 19.1851 18.6934 16.6634 18.6934 13.3057C18.6934 13.251 18.6919 13.1986 18.6892 13.1482H20.7127V13.1482Z" fill="#51C1AD" stroke="#51C1AD" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="15" height="19.4118" fill="white" transform="translate(6 2)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  href="javascript:void(0);"
                  class="action-icon  mr-1"
                  @click="deleteFav(props.rowData)"
                  v-can="'JOB_ORDER_FAVORITE_DELETE'"
                >
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="28" height="24" rx="2" fill="#FFC7C2"/>
                    <path d="M18.654 8.75H8.34598C8.33535 8.87184 8.29707 8.22116 9.04341 18.9866C9.08273 19.5547 9.57769 20 10.1701 20H16.8298C17.4223 20 17.9173 19.5547 17.9566 18.9866C18.7036 8.21187 18.6644 8.86938 18.654 8.75ZM11.4652 17.9981C11.1766 18.0218 10.9269 17.8168 10.902 17.5434L10.382 11.7934C10.357 11.5181 10.5689 11.2759 10.8549 11.2519C11.1409 11.2281 11.3931 11.4316 11.4181 11.7066L11.9381 17.4566C11.9631 17.7319 11.7512 17.9741 11.4652 17.9981ZM14.02 17.5C14.02 17.7762 13.7873 18 13.5 18C13.2127 18 12.98 17.7762 12.98 17.5V11.75C12.98 11.4738 13.2127 11.25 13.5 11.25C13.7873 11.25 14.02 11.4738 14.02 11.75V17.5ZM16.6179 11.7934L16.098 17.5434C16.0746 17.8038 15.8474 18 15.5806 18C15.2733 18 15.0355 17.7474 15.0619 17.4566L15.5819 11.7066C15.6069 11.4316 15.8588 11.2281 16.1451 11.2519C16.4311 11.2759 16.643 11.5181 16.6179 11.7934ZM18.8704 5.75H16.356C16.3613 5.68422 16.3599 5.73338 16.3599 5.08594C16.3599 4.48719 15.8532 4 15.2306 4H11.7694C11.1467 4 10.6401 4.48719 10.6401 5.08594C10.6401 5.72088 10.6387 5.68397 10.644 5.75H8.12954C7.50686 5.75 7.0002 6.23719 7.0002 6.83594C7.0002 7.72916 6.99873 7.68681 7.00377 7.75H19.9962C20.0013 7.68584 19.9998 7.724 19.9998 6.83594C19.9998 6.23719 19.4931 5.75 18.8704 5.75ZM15.3199 5.66406C15.3199 5.71156 15.28 5.75 15.2306 5.75H11.7694C11.72 5.75 11.68 5.71156 11.68 5.66406V5.08594C11.68 5.03844 11.72 5 11.7694 5H15.2306C15.28 5 15.3199 5.03844 15.3199 5.08594V5.66406Z" fill="#EA4C40"/>
                  </svg>
                </a>
              </div>
            </template>
          </vuetable>
        </div>
        <!-- end table -->

        <!-- pagination -->
        <div class="row">
          <div class="col-md-5">
            <div class="row no-gutters">
              <div class="col-lg-3">
                <div class="form-group float-lg-left">
                  <select
                    v-model="perPage"
                    class="form-control form-control-sm"
                    @change="onChangeTableLength"
                  >
                    <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                      {{ opt }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8">
                <vuetable-pagination-info
                  ref="paginationInfo"
                  class="
                    justify-content-center justify-content-lg-start
                    text-center text-lg-left
                  "
                >
                </vuetable-pagination-info>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-7">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              class="
                pagination pagination-rounded
                justify-content-center justify-content-lg-end
              "
              @vuetable-pagination:change-page="onChangePage"
            >
            </vuetable-pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalAddFavDP"
      size="lg"
      title="Add Favorite Job Order"
      style="max-width: 90% !important;"
      ok-title="Save"
      no-close-on-backdrop
      @ok="submitFavSelling"
    >
      <div class="mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Name</label>
              <input v-model="nameFavorite" type="text" class="form-control form-control-sm" placeholder="Input your favorite Jobfile Name" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div v-for="(item, k) in listSellingDownPayment" :key="k" class="form-row">
      <!-- Is Not Copy -->
      <div class="col-md-12 mt-2" v-show="item.is_copied === 0">
        <div class="container-item pl-2 pr-2" style="background: #D8F8EB;">
          <div class="row m-0 mt-1">
            <div class="col-md-6" style="display: flex;justify-content: start;align-items: center;">
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.46957 1.21071 0.960859 1.58579 0.585786C1.96086 0.210714 2.46957 0 3 0H11C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V14C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12H6C5.73478 12 5.48043 12.1054 5.29289 12.2929C5.10536 12.4804 5 12.7348 5 13V15C5 15.2652 4.89464 15.5196 4.70711 15.7071C4.51957 15.8946 4.26522 16 4 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14V2ZM4 3H6V5H4V3ZM6 7H4V9H6V7ZM8 3H10V5H8V3ZM10 7H8V9H10V7Z" fill="#F9A707"/>
              </svg>
              <span style="color: #585858;" class="ml-2">{{item.customer_name}}</span>
            </div>
            <div class="col-md-6" style="display: flex;justify-content: end;align-items: center;">
              <span style="color: #817F7F;" class="mr-2">Grand Total : </span>
              <span style="color: #0EA388;font-size: 20px;font-weight: 700;">{{formatRupiah((item.grandTotal).toString(), item.symbol_default)}}</span>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-8">
              <div class="row" style="font-size: 12px;">
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">DP No : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.number}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">PO No : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.po_no}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Date : </span>
                  <span style="color: #585858;font-weight: 700;">{{ dateFormating(item.date) }}</span>
                </div>
                <div class="col-md-3">
                </div>
              </div>
              <div class="row" style="font-size: 12px;">
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Price : </span>
                  <span style="color: #585858;font-weight: 700;">{{formatRupiah((item.down_payment).toString(), item.symbol)}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">PPN : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.ppn}}% ({{formatRupiah((item.totalPPN).toString(), item.symbol_default)}})</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Total : </span>
                  <span style="color: #585858;font-weight: 700;">{{formatRupiah((item.grandTotal).toString(), item.symbol_default)}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Desc : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.description}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4" style="display: flex;justify-content: end;align-items: center;">
              <b-button
                class="btn btn-create btn-sm mr-2"
                style="background: #51C1AD; border: 1px; height: 30px;"
                @click="editInfoSelling(item.id)"
                v-can="'JOB_ORDER_SELLING_CREATE'"
              >
                <i class="mdi mdi-pencil mr-1"></i>
                Edit
              </b-button>
              <b-button
                class="btn btn-create btn-sm mr-2"
                style="background: #EA4C40; border: 1px; height: 30px;"
                @click="deleteSellingData(item.id)"
                v-can="'JOB_ORDER_SELLING_CREATE'"
              >
                <i class="mdi mdi-delete mr-1"></i>
                Delete
              </b-button>
              <b-button
                class="btn btn-create btn-sm"
                style="background: #F9A707; border: 1px; height: 30px;"
                @click="openModalSellingCopy(item.id)"
                v-can="'JOB_ORDER_SELLING_CREATE'"
                v-show="item.is_copied === 0"
              >
                <i class="mdi mdi-clipboard mr-1"></i>
                Copy ?
              </b-button>
              <div class="ml-2" style="cursor: pointer;" @click="downloadInvoice(item.id)">
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73047 0C2.20004 0 1.69133 0.210714 1.31626 0.585786C0.941182 0.960859 0.730469 1.46957 0.730469 2V14C0.730469 14.5304 0.941182 15.0391 1.31626 15.4142C1.69133 15.7893 2.20004 16 2.73047 16H10.7305C11.2609 16 11.7696 15.7893 12.1447 15.4142C12.5198 15.0391 12.7305 14.5304 12.7305 14V5.414C12.7304 4.88361 12.5196 4.37499 12.1445 4L8.73047 0.586C8.35548 0.210901 7.84686 0.000113275 7.31647 0H2.73047ZM7.73047 6C7.73047 5.73478 7.62511 5.48043 7.43758 5.29289C7.25004 5.10536 6.99569 5 6.73047 5C6.46525 5 6.2109 5.10536 6.02336 5.29289C5.83583 5.48043 5.73047 5.73478 5.73047 6V9.586L4.43747 8.293C4.34522 8.19749 4.23488 8.12131 4.11287 8.0689C3.99087 8.01649 3.85965 7.9889 3.72687 7.98775C3.59409 7.9866 3.46241 8.0119 3.33951 8.06218C3.21662 8.11246 3.10497 8.18671 3.01107 8.28061C2.91718 8.3745 2.84293 8.48615 2.79265 8.60905C2.74237 8.73194 2.71706 8.86362 2.71822 8.9964C2.71937 9.12918 2.74696 9.2604 2.79937 9.3824C2.85178 9.50441 2.92796 9.61475 3.02347 9.707L6.02347 12.707C6.211 12.8945 6.4653 12.9998 6.73047 12.9998C6.99563 12.9998 7.24994 12.8945 7.43747 12.707L10.4375 9.707C10.6196 9.5184 10.7204 9.2658 10.7181 9.0036C10.7159 8.7414 10.6107 8.49059 10.4253 8.30518C10.2399 8.11977 9.98907 8.0146 9.72687 8.01233C9.46467 8.01005 9.21207 8.11084 9.02347 8.293L7.73047 9.586V6Z" fill="#009FC2"/>
                </svg>
              </div>
              <span v-show="item.is_copied === 1" style="color: #009FC2;font-weight: 700;font-size: 12px;">Copied !</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Is Copy -->
      <div class="col-md-12 mt-2" v-show="item.is_copied === 1">
        <div class="container-item pl-2 pr-2" style="background: #F6F6F6;">
          <div class="row m-0 mt-1">
            <div class="col-md-6" style="display: flex;justify-content: start;align-items: center;">
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2C1 1.46957 1.21071 0.960859 1.58579 0.585786C1.96086 0.210714 2.46957 0 3 0H11C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V14C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12H6C5.73478 12 5.48043 12.1054 5.29289 12.2929C5.10536 12.4804 5 12.7348 5 13V15C5 15.2652 4.89464 15.5196 4.70711 15.7071C4.51957 15.8946 4.26522 16 4 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14V2ZM4 3H6V5H4V3ZM6 7H4V9H6V7ZM8 3H10V5H8V3ZM10 7H8V9H10V7Z" fill="#F9A707"/>
              </svg>
              <span style="color: #585858;" class="ml-2">{{item.customer_name}}</span>
            </div>
            <div class="col-md-6" style="display: flex;justify-content: end;align-items: center;">
              <span style="color: #817F7F;" class="mr-2">Grand Total : </span>
              <span style="color: #0EA388;font-size: 20px;font-weight: 700;">{{formatRupiah((item.grandTotal).toString(), item.symbol_default)}}</span>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-8">
              <div class="row" style="font-size: 12px;">
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">DP No : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.number}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">PO No : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.po_no}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Date : </span>
                  <span style="color: #585858;font-weight: 700;">{{ dateFormating(item.date) }}</span>
                </div>
                <div class="col-md-3">
                </div>
              </div>
              <div class="row" style="font-size: 12px;">
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Price : </span>
                  <span style="color: #585858;font-weight: 700;">{{formatRupiah((item.down_payment).toString(), item.symbol)}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">PPN : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.ppn}}% ({{formatRupiah((item.totalPPN).toString(), item.symbol)}})</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Total : </span>
                  <span style="color: #585858;font-weight: 700;">{{formatRupiah((item.grandTotal).toString(), item.symbol)}}</span>
                </div>
                <div class="col-md-3">
                  <span style="color: #817F7F;font-weight: 400;">Desc : </span>
                  <span style="color: #585858;font-weight: 700;">{{item.description}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4" style="display: flex;justify-content: end;align-items: center;">
              <b-button
                class="btn btn-create btn-sm mr-2"
                style="background: #51C1AD; border: 1px; height: 30px;"
                @click="editInfoSelling(item.id)"
                v-can="'JOB_ORDER_SELLING_CREATE'"
              >
                <i class="mdi mdi-pencil mr-1"></i>
                Edit
              </b-button>
              <b-button
                class="btn btn-create btn-sm mr-2"
                style="background: #EA4C40; border: 1px; height: 30px;"
                @click="deleteSellingData(item.id)"
                v-can="'JOB_ORDER_SELLING_CREATE'"
              >
                <i class="mdi mdi-delete mr-1"></i>
                Delete
              </b-button>
              <b-button
                class="btn btn-create btn-sm"
                style="background: #F9A707; border: 1px; height: 30px;"
                @click="openModalSellingDownPayment"
                v-can="'JOB_ORDER_SELLING_CREATE'"
                v-show="item.is_copied === 0"
              >
                <i class="mdi mdi-clipboard mr-1"></i>
                Copy ?
              </b-button>
              <span v-show="item.is_copied === 1" style="color: #009FC2;font-weight: 700;font-size: 12px;">Copied !</span>
              <div class="ml-2" style="cursor: pointer;" @click="downloadInvoice(item.id)">
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73047 0C2.20004 0 1.69133 0.210714 1.31626 0.585786C0.941182 0.960859 0.730469 1.46957 0.730469 2V14C0.730469 14.5304 0.941182 15.0391 1.31626 15.4142C1.69133 15.7893 2.20004 16 2.73047 16H10.7305C11.2609 16 11.7696 15.7893 12.1447 15.4142C12.5198 15.0391 12.7305 14.5304 12.7305 14V5.414C12.7304 4.88361 12.5196 4.37499 12.1445 4L8.73047 0.586C8.35548 0.210901 7.84686 0.000113275 7.31647 0H2.73047ZM7.73047 6C7.73047 5.73478 7.62511 5.48043 7.43758 5.29289C7.25004 5.10536 6.99569 5 6.73047 5C6.46525 5 6.2109 5.10536 6.02336 5.29289C5.83583 5.48043 5.73047 5.73478 5.73047 6V9.586L4.43747 8.293C4.34522 8.19749 4.23488 8.12131 4.11287 8.0689C3.99087 8.01649 3.85965 7.9889 3.72687 7.98775C3.59409 7.9866 3.46241 8.0119 3.33951 8.06218C3.21662 8.11246 3.10497 8.18671 3.01107 8.28061C2.91718 8.3745 2.84293 8.48615 2.79265 8.60905C2.74237 8.73194 2.71706 8.86362 2.71822 8.9964C2.71937 9.12918 2.74696 9.2604 2.79937 9.3824C2.85178 9.50441 2.92796 9.61475 3.02347 9.707L6.02347 12.707C6.211 12.8945 6.4653 12.9998 6.73047 12.9998C6.99563 12.9998 7.24994 12.8945 7.43747 12.707L10.4375 9.707C10.6196 9.5184 10.7204 9.2658 10.7181 9.0036C10.7159 8.7414 10.6107 8.49059 10.4253 8.30518C10.2399 8.11977 9.98907 8.0146 9.72687 8.01233C9.46467 8.01005 9.21207 8.11084 9.02347 8.293L7.73047 9.586V6Z" fill="#009FC2"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2'
import axios from 'axios'
// import DatePicker from 'vue2-datepicker'
// import Vue from 'vue'

// var url = '/quotation'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    // url = '/quotation?admin=true'
  }
}
// Vue.component('my-detail-row', DetailRow)

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // filterText: '',
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + "/FavoriteJobOrder/3",
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Quotation',
          href: '/quotation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      fields: [
        {
          name: 'name',
          title: 'Name',
          // sortField: 'number',
        },
        {
          name: 'id_number',
          title: 'Job Order No',
          // sortField: 'subject',
        },
        {
          name: 'created_at_format',
          title: 'Created Date',
          // sortField: 'customer_id',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      momentFormat: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSellingDownPayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleSellingDownPayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatSellingCopy: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSellingCopy.date).format('YYYY-MM-DD') : ''
        },
      },
      moreParams: {},
      filterText: '',
      deleteData: {},
      totalPrice: 0,
      dataSellingCopy: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        po: '',
        customer: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        commercialRate: '',
        fiscalRate: '',
        inclusiveTax: false,
        id_dp: 0
      },
      itemSellingCopy: [{
        item: '',
        price: '',
        qty: '',
        itemDescription: '',
        truck: '',
        isTruck: 0
      }],
      dataSellingDownPayment: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        po: '',
        customer: '',
        date: '',
        ppn: '',
        // docCode: '',
        // taxType: '',
        commercialRate: '',
        fiscalRate: '',
        inclusiveTax: 0,
        downPayment: 0,
        taxable: 0
      },
      types: [{
        type: '',
        item: [{
          item: '',
          price: '',
          // qty: '',
          itemDescription: '',
          item_id: '',
          curr:'',
          currency_id:''
        }]
      }],
      showTaxType: true,
      itemSelling: [{
        item: '',
        price: '',
        qty: '',
        itemDescription: '',
        truck: '',
        isTruck: 0
      }],
      newItemSelling: [{
        item: '',
        price: '',
        qty: '',
        itemDescription: '',
        truck: '',
        isTruck: 0
      }],
      inclusiveTax: 0,
      detail: [{
        item: '',
        price: '',
        qty: '',
        itemDescription: '',
        item_id: '',
        curr:''
      }],
      isManualNumberSellingDownPayment: false,
      isManualNumberSelling: false,
      isTruck: false,
      isCopyTransaction: "0",
      typeCopy: 'Buying',
      joborderCopy: '',
      numberCopy: '',
      remarksCopy: '',
      listJobOrderCopy: [],
      listNumberCopy: [],
      listType: [],
      // isLoadingTable: false,
      // httpOptions: {
      //   headers: {
      //     Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //   },
      // },
      // apiUrl: this.$store.state.rootApi + url,
      // urlPdf: '#',
      // items: [
      //   {
      //     text: 'Oprational',
      //     href: '/',
      //   },
      //   {
      //     text: 'job_order',
      //     href: '/job_order',
      //   },
      //   {
      //     text: 'List',
      //     active: true,
      //   },
      // ],
      // lengthMenu: [10, 25, 50, 100],
      // perPage: 10,
      // fields: [
      //   {
      //     name: 'no',
      //     title: '#',
      //   },
      //   {
      //     name: 'id',
      //     title: 'No',
      //   },
      //   {
      //     name: 'number',
      //     title: 'Job No.',
      //   },
      //   {
      //     name: 'subject',
      //     title: 'Customer',
      //   },
      //   {
      //     name: '-',
      //     title: 'BL Number',
      //   },
      //   {
      //     name: '-',
      //     title: 'DO Number',
      //   },
      //   {
      //     name: '-',
      //     title: 'Suffing',
      //   },
      //   {
      //     name: '-',
      //     title: 'Marketing',
      //   },
      //   {
      //     name: 'customer.number',
      //     title: 'Created By',
      //   },
      //   {
      //     name: 'status',
      //     title: 'Status',
      //   },
      //   {
      //     name: 'info',
      //     title: 'Info',
      //   },
      //   {
      //     name: 'action',
      //     title: 'Action',
      //   },
      // ],
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      marketingData: [],
      rangeData: 'customData',
      topCustomerData: 'Select Number',
      isJobOrderNo: true,
      compareData: false,
      isManualTopCustData: false,
      monthData: '01',
      showRate: false,
      yearData: null,
      nameFavorite: '',
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      customerData: [],
    }
  },
  computed: {
    ...mapState('jobOrder', ['createdField']),
    ...mapState('customer', ['listCustomerQuo']),
    ...mapState('port', ['listPort']),
    ...mapState('customer', ['listCustomer']),
    ...mapState('truck', ['listTruck']),
    ...mapState('autonumber', ['listAutonumber']),
    ...mapState('currency', ['listCurrency','dataCurrency', 'dataDefaultCurrency']),
    ...mapState('item', ['listItem']),
    ...mapState('term', ['listTerm']),
    ...mapState('company', ['companyIsAol']),
    ...mapState('jobOrderSellingDownPayment', ['listSellingDownPayment', 'dataSingleSellingDownPayment']),
    ...mapState('jobOrderSelling', ['listDocCode', 'listTaxType']),
    ...mapState('percentage', ['listPercentage']),
  },
  mounted() {
    this.getCreatedField()
    this.getListCustomerQuo()
    this.getListPort()
    this.getListVendor()
    this.p_getDefaultCurrency()
    this.initCurrency()
    // this.getListTerm()
    // this.getListItem()
    this.getListPercentage()
    this.getListTruck()
    let idJoborder = window.location.href.split("/").splice(0, 6)[5];
    this.getListSellingDownPayment(idJoborder)
    this.isAol();
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    ...mapActions('jobOrder', ['getCreatedField']),
    ...mapActions('customer', ['getListCustomerQuo']),
    ...mapActions('port', ['getListPort']),
    ...mapActions('customer', ['getListVendor']),
    ...mapActions('truck', ['getListTruck']),
    ...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod']),
    ...mapActions('currency', ['getListCurrency', 'getDefaultCurrency']),
    ...mapActions('item', ['getItemByPpn']),
    ...mapActions('term', ['getListTerm']),
    ...mapActions('quotation', ['saveQuotation']),
    ...mapActions('percentage', ['getListPercentage']), 
    ...mapActions('company', ['isAol']), 
    ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
    ...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
    ...mapActions('jobOrderSellingDownPayment', ['saveSellingDownPayment', 'getListSellingDownPayment', 'getSellingDownPayment', 'updateSellingDownPayment', 'deleteSellingDownPayment', 'submitAOL', 'saveSellingCopy', 'deleteSellingDownPaymentWithSelling']), 
    ...mapActions('jobOrderSelling', ['getListDocCode', 'getListTaxType', 'getListSelling']),
    setJson(payload) {
      this.json = JSON.parse(payload)
    },
    firstCapital(payload) {
      return payload[0].toUpperCase() + payload.slice(1)
    },
    getTgl() {
      this.dataSellingDownPayment.date = moment(this.dataSellingDownPayment.date).format('YYYY-MM-DD')
    },
    getTglEdit() {
      this.dataSingleSellingDownPayment.date = moment(this.dataSingleSellingDownPayment.date).format('YYYY-MM-DD')
    },
    // doFilter() {
    //   this.onFilterSet(this.filterText)
    // },
    // doFilterMultipleKeywords() {
    //   this.onFilterSetMultipleKeywords()
    // },
    // /**
    //  * Table Length Change
    //  *
    //  * @return  {[type]}  [return description]
    //  */
    // onChangeTableLength() {
    //   // this.$refs.vuetable.reload()
    //   this.$nextTick(() => this.$refs.vuetable.refresh())
    // },
    // /**
    //  * Pagination page change
    //  */
    // onChangePage(page) {
    //   this.$refs.vuetable.changePage(page)
    // },
    // /**
    //  * Pagination info and pagination show
    //  */
    // onPaginationData(paginationData) {
    //   this.$refs.pagination.setPaginationData(paginationData)
    //   this.$refs.paginationInfo.setPaginationData(paginationData)
    // },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    // onFilterSet(filterText) {
    //   this.moreParams = {
    //     search: filterText,
    //   }
    //   this.$nextTick(() => this.$refs.vuetable.refresh())
    // },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      let strMarketing = []
      this.marketingData.forEach((data, idx) => {
        strMarketing.push(data.id_user)
      })

      let strCustomer = []
      this.customerData.forEach((data, idx) => {
        strCustomer.push(data.id)
      })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }

      this.moreParams = {
        branch: localStorage.getItem('default_branch'),
        customer: strCustomer,
        jobfileType: this.jobfileTypeData,
        startDate: tempStartDateData,
        endDate: tempEndDateData,
        sales: strMarketing,
        status: this.statusData,
        perPage: 10,
        filter: true,
      }
      // console.log(this.moreParams, 'THIS PARAMSSS')
      // console.log(filterText)
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    // loadingTable() {
    //   this.isLoadingTable = true
    // },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    // loadTableSuccess() {
    //   this.isLoadingTable = false
    // },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    // loadTableFailed(error) {
    //   console.log(error)

    //   this.isLoadingTable = false
    //   this.$swal({
    //     title: 'Terjadi Kesalahan',
    //     type: 'error',
    //     timer: '2000',
    //     toast: true,
    //     position: 'bottom-end',
    //   })
    //   // }
    // },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },
    // getListTerm() {
    //   try {
    //     // let strBranch = ''
    //     // this.branchData1.forEach((data, idx) => {
    //     //   if (idx === 0) {
    //     //     strBranch += 'id_branch[]=' + data.id_branch
    //     //   } else {
    //     //     strBranch += '&id_branch[]=' + data.id_branch
    //     //   }
    //     // })
    //     this.getListTerm()
    //       .then((res) => {
    //         console.log(res, 'res user by branch')
    //         // this.user_id = res.result[1].id
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    initCurrency() {
      try {
        this.getListCurrency()
          .then((res) => {
            // this.dataSellingDownPayment.currency = 2
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    p_getDefaultCurrency() {
      try {
        this.getDefaultCurrency()
          .then((res) => {
            this.dataSellingDownPayment.currency = this.dataDefaultCurrency.id
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    openModalCreated() {
      console.log('OKEEE')
      this.$bvModal.show('my-modal')
    },
    hideModalCreated() {
      this.$bvModal.hide('my-modal')
    },

    openModalSubJobCreated() {
      this.$bvModal.show('my-modal-subJob')
    },
    hideModalSubJobCreated() {
      this.$bvModal.hide('my-modal-subJob')
    },

    changeJobOrderNo() {
      this.isManualJobOrderNo = !this.isManualJobOrderNo
      this.isJobOrderNo = !this.isJobOrderNo
      if (this.isJobOrderNo) {
        this.autoJobOrderNo = 15
      }
    },

    onCellClicked(data, field, event) {
      console.log('cellClicked: ', field.customer.name)
      this.$refs.vuetable.toggleDetailRow(data.id)
    },

    onAction(action, rowData, rowIndex) {
      if (action === 'details') {
        this.$router.push({
          name: 'JobOrder Details',
          params: {
            id: rowData.id,
          },
        })
      }
    },
    openModalSellingDownPayment() {
      this.listAutonumber = []
      this.getListAutonumberFlexmod({
        id_flexmod: localStorage.getItem('flexmodActive'),
        id_module: 30
      })
      console.log('OKEEE')
      // this.dataSellingDownPayment = {
      //   currency: '',
      //   term: '',
      //   number: '',
      //   numberText: '',
      //   po: '',
      //   customer: '',
      //   date: '',
      //   ppn: '',
      //   docCode: '',
      //   taxType: '',
      //   commercialRate: '',
      //   fiscalRate: '',
      //   inclusiveTax: false
      // }

      this.dataSellingDownPayment.term = ''
      this.dataSellingDownPayment.number = ''
      this.dataSellingDownPayment.numberText = ''
      this.dataSellingDownPayment.po = ''
      this.dataSellingDownPayment.customer = ''
      this.dataSellingDownPayment.date = ''
      this.dataSellingDownPayment.ppn = ''
      // this.dataSellingDownPayment.docCode = ''
      // this.dataSellingDownPayment.taxType = ''
      this.dataSellingDownPayment.commercialRate = ''
      this.dataSellingDownPayment.fiscalRate = ''
      this.dataSellingDownPayment.downPayment = 0
      this.dataSellingDownPayment.taxable = '0'
      this.dataSellingDownPayment.description = ''
      this.dataSellingDownPayment.inclusiveTax = false

      this.itemSelling = [
        {
          item: '',
          price: '',
          qty: '',
          itemDescription: '',
          truck: '',
          isTruck: 0,
        }
      ]

      this.$bvModal.show('modalSellingDownPayment')
    },
    openModalSellingCopy(id) {
      this.getListDocCode()
      this.getSellingDownPayment(id)
        .then((res) => {
          this.dataSingleSellingDownPayment.date = new Date(this.dataSingleSellingDownPayment.date)
          this.listAutonumber = []
          this.getListAutonumberFlexmod({
            id_flexmod: localStorage.getItem('flexmodActive'),
            id_module: 24
          })
          console.log('OKEEE')

          this.getListTerm()
          .then((res) => {
            if(this.dataSingleSellingDownPayment.id_currency !== this.dataDefaultCurrency.id) {
              this.showRate = true
            } else {
              this.showRate = false
            }
            this.dataSellingCopy.term = this.dataSingleSellingDownPayment.term
            this.dataSellingCopy.number = ''
            this.dataSellingCopy.numberText = ''
            this.dataSellingCopy.po = ''
            this.dataSellingCopy.customer = this.dataSingleSellingDownPayment.id_customer
            this.dataSellingCopy.date = ''
            this.dataSellingCopy.ppn = ''
            this.dataSellingCopy.docCode = ''
            this.dataSellingCopy.taxType = ''
            this.dataSellingCopy.commercialRate = ''
            this.dataSellingCopy.fiscalRate = ''
            this.dataSellingCopy.currency = this.dataSingleSellingDownPayment.id_currency
            this.dataSellingCopy.inclusiveTax = false
            this.dataSellingCopy.id_dp = id

            this.itemSellingCopy = [
              {
                item: '',
                price: '',
                qty: '',
                itemDescription: '',
                truck: '',
                isTruck: 0,
              }
            ]

            this.$bvModal.show('modalSellingCopy')
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    downloadInvoice(id) {
      console.log(id)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      }
      axios({
        url: this.$store.state.rootApi + '/sellingDownPayment' + '/pdf/' + id,
        config,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fURL = document.createElement('a')

        fURL.href = fileURL
        fURL.setAttribute('download', 'Selling Down Payment - ' + id + '.pdf')
        document.body.appendChild(fURL)

        fURL.click()
      })
    },
    submitSelling(bvModalEvent) {
      bvModalEvent.preventDefault()
      console.log(this.dataSellingDownPayment, 'THIS DATA Selling')
      console.log(this.itemSelling, 'THIS DATA ITEM Selling')
      console.log('OKEE')

      if(this.dataSellingDownPayment.number === "" && this.dataSellingDownPayment.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingDownPayment.customer === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingDownPayment.po === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field PO NO",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingDownPayment.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      // this.dataSellingDownPayment.currency = this.dataDefaultCurrency.id

      if(this.dataSellingDownPayment.currency !== this.dataDefaultCurrency.id) {
        if(this.dataSellingDownPayment.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataSellingDownPayment.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      if(parseInt(this.dataSellingDownPayment.taxable) === 0) {
        this.dataSellingDownPayment.ppn = 0
        this.dataSellingDownPayment.inclusiveTax = 'false'
      }

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        selling: this.dataSellingDownPayment,
        itemSelling: this.itemSelling
      }

      console.log(mergeData);

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveSellingDownPayment({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {      
                this.$bvModal.hide('modalSellingDownPayment')
              })

              let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              this.getListSellingDownPayment(idJoborder)
            } else {
              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    submitSellingCopy(bvModalEvent) {
      bvModalEvent.preventDefault()

      if(this.dataSellingCopy.number === "" && this.dataSellingCopy.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingCopy.customer === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingCopy.po === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field PO NO",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSellingCopy.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      // this.dataSellingCopy.currency = this.dataDefaultCurrency.id

      if(this.dataSellingCopy.currency !== this.dataDefaultCurrency.id) {
        if(this.dataSellingCopy.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataSellingCopy.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      let itemIsValid = true;

      this.itemSellingCopy.forEach((dt, idx) => {
        if(dt.item === "" || dt.price === "" || dt.qty === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field item, price, and qty in item row " + (idx+1),
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          itemIsValid = false;
        } 
      })

      if(!itemIsValid) {
        return false;
      }

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        selling: this.dataSellingCopy,
        itemSelling: this.itemSellingCopy
      }

      console.log(mergeData);

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveSellingCopy({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {      
                this.$bvModal.hide('modalSellingCopy')
              })

              let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              this.getListSellingDownPayment(idJoborder)
              this.getListSelling(idJoborder)
            } else {
              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    triggerManualNumberSelling() {
      this.isManualNumberSellingDownPayment = !this.isManualNumberSellingDownPayment
    },
    cekIsTruck(idx) {
      // if(this.itemSelling[idx].isTruck) {
      //   this.itemSelling[idx].isTruck = false
      // } else {
      //   this.itemSelling[idx].isTruck = true
      // }

      console.log(this.itemSelling[idx].isTruck)
    },
    changeCustomer() {
      let getCustomer = this.listCustomer.find(x => x.id === this.dataSellingDownPayment.customer)
      // this.dataSellingDownPayment.term = this.dataSellingDownPayment.customer.id_term
      this.getListTerm()
        .then((res) => {
          if(getCustomer !== undefined) {
            this.dataSellingDownPayment.term = getCustomer.id_term
          }
          // this.dataSellingDownPayment.term = this.dataSellingDownPayment.customer.id_term
          // let termDefault = this.listTerm.find(x => x.id === this.dataSellingDownPayment.customer.id_term)
    
          // if(termDefault !== undefined) {
          //   this.dataSellingDownPayment.term = termDefault
          // }
        })
    },
    changeCustomerCopy() {
      let getCustomer = this.listCustomer.find(x => x.id === this.dataSellingCopy.customer)
      // this.dataSellingDownPayment.term = this.dataSellingDownPayment.customer.id_term
      this.getListTerm()
        .then((res) => {
          if(getCustomer !== undefined) {
            this.dataSellingCopy.term = getCustomer.id_term
          }
          // this.dataSellingDownPayment.term = this.dataSellingDownPayment.customer.id_term
          // let termDefault = this.listTerm.find(x => x.id === this.dataSellingDownPayment.customer.id_term)
    
          // if(termDefault !== undefined) {
          //   this.dataSellingDownPayment.term = termDefault
          // }
        })
    },
    changePpn() {
      console.log(parseInt(this.dataSellingDownPayment.ppn))
      if(parseInt(this.dataSellingDownPayment.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }
      console.log(this.showTaxType)
      this.getItemByPpn({
        ppn: this.dataSellingDownPayment.ppn,
        itemCategory: 1
      })
    },
    changePpnCopy() {
      console.log(parseInt(this.dataSellingCopy.ppn))
      if(parseInt(this.dataSellingCopy.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }
      console.log(this.showTaxType)
      this.getItemByPpn({
        ppn: this.dataSellingCopy.ppn,
        itemCategory: 1
      })
    },
    changeDocCode() {
      if(this.dataSellingCopy.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
      } else {
        this.showTaxType = true;
      }
      this.getListTaxType(this.dataSellingCopy.docCode)
    },
    changeDocCodeEdit() {
      if(this.dataSingleSellingDownPayment.doc_code === 'DIGUNGGUNG') {
        this.showTaxType = false;
      } else {
        this.showTaxType = true;
      }
      this.dataSingleSellingDownPayment.tax_type = ''
      this.getListTaxType(this.dataSingleSellingDownPayment.doc_code)
    },
    changeCurrency(value) {
      // this.dataSellingDownPayment.currency = value

      if(value !== this.dataDefaultCurrency.id) {
        this.showRate = true
      } else {
        this.showRate = false
      }

      // this.dataDefaultCurrency = this.dataSellingDownPayment.currency
      // console.log(this.dataSellingDownPayment.currency)
    },
    changeTerm(value) {
      console.log(this.dataSellingDownPayment.term)
    },
    formatRupiah(angka, prefix){
      // console.log(angka, 'THIS ANGKAA')
      angka = angka.replace(/\./g, ',')
			let numberString = angka.replace(/[^,\d]/g, '').toString()
      // console.log(numberString, 'THIS ANGKAA 2')
			let split   		= numberString.split(',')
			let sisa     		= split[0].length % 3
			let rupiah     		= split[0].substr(0, sisa)
			let ribuan     		= split[0].substr(sisa).match(/\d{3}/gi)
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
    editInfoSelling(id) {
      try {
        this.getSellingDownPayment(id)
          .then((res) => {
            this.dataSingleSellingDownPayment.date = new Date(this.dataSingleSellingDownPayment.date)
            this.getListTerm(this.dataSingleSellingDownPayment.id_customer)
              .then((res) => {
                // this.getListTaxType(this.dataSingleSellingDownPayment.doc_code)
                //   .then((res) => {
                //     // console.log(this.dataSingleSellingDownPayment.date.replace(/-/g, '/'))
                //   })
                  this.$bvModal.show('modalSellingDownPaymentEdit')
              })
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    submitInfoSelling(bvModalEvent) {
      // this.$bvModal.hide('modalSellingDownPaymentEdit')
      bvModalEvent.preventDefault()

      // this.$nextTick(() => {      
      //   this.$bvModal.hide('modalSellingDownPaymentEdit')
      // })

      if(this.dataSingleSellingDownPayment.po_no === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field PO NO",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSingleSellingDownPayment.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(parseInt(this.dataSingleSellingDownPayment.taxable) === 0) {
        this.dataSingleSellingDownPayment.ppn = 0
        this.dataSingleSellingDownPayment.inclusiveTax = 'false'
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.updateSellingDownPayment({
          payload: this.dataSingleSellingDownPayment,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalSellingDownPaymentEdit')
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListSellingDownPayment(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    deleteSellingData(id) {
      this.getSellingDownPayment(id)
        .then((res) => {
          // this.dataSingleSellingDownPayment.is_copied = new Date(this.dataSingleSellingDownPayment.date)
          if(this.dataSingleSellingDownPayment.is_copied === 0) {
            Swal.fire({
              title: 'Are you sure?',
              text: 'You will not be able to recover this data!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              cancelButtonColor: '#C1C1C1',
              reverseButtons: true,
              confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
              if (result.isConfirmed) {
                let headers = {
                  'Content-Type': 'application/json'
                }
  
                this.deleteSellingDownPayment({
                    payload: id,
                    headers: headers
                  })
                  .then((res) => {
                    console.log(res.data, 'datanya')
                    if (res.status) {
                      const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      })
  
                      Toast.fire({
                        icon: 'success',
                        title: 'successfully'
                      })
  
                      let idJoborder = window.location.href.split("/").splice(0, 6)[5];
                      this.getListSellingDownPayment(idJoborder)
                    } else {
                      Swal.fire({
                        title: "Failed!",
                        text: res.message,
                        icon: 'warning',
                        confirmButtonColor: 'rgb(221, 107, 85)',
                        confirmButtonText: 'Ok'
                      })
                    }
                  })
                  .catch((err) => {
                    console.log(err.response.data)
                    Swal.fire({
                      title: "Failed!",
                      text: err.response.data.message,
                      icon: 'warning',
                      confirmButtonColor: 'rgb(221, 107, 85)',
                      confirmButtonText: 'Ok'
                    })
                  })
              }
            })
          } else {
            Swal.fire({
              title: 'Warning',
              text: 'You cannot delete the down payment if the Selling transaction has not been deleted!',
              icon: 'warning',
              type: "warning",
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              cancelButtonColor: '#C1C1C1',
              reverseButtons: true,
              closeOnConfirm: false,
              confirmButtonText: 'Down Payment Only!',
              denyButtonText: 'Down Payment and Selling!',
              customClass: {
                denyButton: 'button-gamb',
                cancelButton: 'button-gamb',
                confirmButton: 'button-gamb'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                let headers = {
                  'Content-Type': 'application/json'
                }
  
                this.deleteSellingDownPayment({
                    payload: id,
                    headers: headers
                  })
                  .then((res) => {
                    console.log(res.data, 'datanya')
                    if (res.status) {
                      const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      })
  
                      Toast.fire({
                        icon: 'success',
                        title: 'successfully'
                      })
  
                      let idJoborder = window.location.href.split("/").splice(0, 6)[5];
                      this.getListSellingDownPayment(idJoborder)
                      this.getListSelling(idJoborder)
                    } else {
                      Swal.fire({
                        title: "Failed!",
                        text: res.message,
                        icon: 'warning',
                        confirmButtonColor: 'rgb(221, 107, 85)',
                        confirmButtonText: 'Ok'
                      })
                    }
                  })
                  .catch((err) => {
                    console.log(err.response.data)
                    Swal.fire({
                      title: "Failed!",
                      text: err.response.data.message,
                      icon: 'warning',
                      confirmButtonColor: 'rgb(221, 107, 85)',
                      confirmButtonText: 'Ok'
                    })
                  })
              } else if (result.isDenied) {
                let headers = {
                  'Content-Type': 'application/json'
                }
  
                this.deleteSellingDownPaymentWithSelling({
                    payload: id,
                    headers: headers
                  })
                  .then((res) => {
                    console.log(res.data, 'datanya')
                    if (res.status) {
                      const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      })
  
                      Toast.fire({
                        icon: 'success',
                        title: 'successfully'
                      })
  
                      let idJoborder = window.location.href.split("/").splice(0, 6)[5];
                      this.getListSellingDownPayment(idJoborder)
                      this.getListSelling(idJoborder)
                    } else {
                      Swal.fire({
                        title: "Failed!",
                        text: res.message,
                        icon: 'warning',
                        confirmButtonColor: 'rgb(221, 107, 85)',
                        confirmButtonText: 'Ok'
                      })
                    }
                  })
                  .catch((err) => {
                    console.log(err.response.data)
                    Swal.fire({
                      title: "Failed!",
                      text: err.response.data.message,
                      icon: 'warning',
                      confirmButtonColor: 'rgb(221, 107, 85)',
                      confirmButtonText: 'Ok'
                    })
                  })
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitToAol(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOL({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListSellingDownPayment(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    openModalFavSellingDownPayment() {
      this.$bvModal.show('modalFavSellingDownPayment')
    },
    doFilter() {
      this.onFilterSet(this.filterText)
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },
    submitFavSelling(bvModalEvent) {
      bvModalEvent.preventDefault();
      if(this.nameFavorite === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field name",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let dataPost = {
        name: this.nameFavorite,
        type: 3,
        id_job_order: idJoborder
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.saveNewFavorite({
          payload: dataPost,
          headers: headers
        })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalAddFavDP')
            })

            this.$nextTick(() => this.$refs.vuetable.refresh())

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListSellingDownPayment(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    openModalAddFavDP() {
      this.$bvModal.show('modalAddFavDP')
    },
    deleteFav(data) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFavJobOrder(data.id)
            .then((res) => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
    
              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })
    
              this.$refs.vuetable.refresh()
            })
            .catch((err) => {
              this.errorAlert()
              console.log(err.response.message)
    
              // this.isLoadingTable = false;
            })
        }
      })
    },
    dateFormating(date) {
        return date ? moment(date).format('DD MMM YYYY') : ''
    },
    loadFav(data) {
      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let dataPost = {
        id_job_order: data.id_job_order,
        type: 'selling_down_payment',
        id_job_order_new: idJoborder
      }

      let headers = {
        'Content-Type': 'application/json'
      }

      this.loadFavorite({
          payload: dataPost,
          headers: headers
        })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {      
              this.$bvModal.hide('modalFavSellingDownPayment')
            })

            // this.$nextTick(() => this.$refs.vuetable.refresh())

            let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            this.getListSellingDownPayment(idJoborder)
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    addDetail(k) {
      this.itemSellingCopy.push({
        item: '',
        price: '',
        qty: '',
        itemDescription: '',
        truck: '',
        isTruck: 0,
      });
    },
    removeDetail(d) {
      this.itemSellingCopy.splice(d, 1);
    },
    // checkIsAol() {
    //   try {
    //     this.isAol()
    //       .then((res) => {
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
  },
}
</script>
<style>
.created-field svg {
  color: #51c1ad;
}
.modal-xl {
  max-width: 95% !important;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 31px !important;
}
.container-item {
  min-height: 105px;
  height: 100%;
  background: #D8F8EB;
  border-radius: 3px;
  border: 1px dashed #DADADA;
}
.container-downpayment {
  height: 40px;
  background: #E2F4F1;
  border-radius: 3px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}

.button-gamb {
  font-size: 10px !important;
}
</style>

