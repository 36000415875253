<template>
    <div>
        <vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
        <div class="row">
            <div class="col-md-12 col-lg-6 mb-2">
                <button v-can="'JOB_ORDER_DOCUMENT_CREATE'" class="btn btn-create" @click="showFormInput"><i
                        class="mdi mdi-plus-circle mr-1"></i> Create New</button>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="float-lg-right float-left">
                    <div class="form-inline">
                        <label class="mb-2 mr-sm-2">Search :</label>
                        <input v-model="filterText" type="text" class="form-control form-control-sm mb-2 mr-sm-2"
                            placeholder="Search . . ." @keyup="doFilter" />
                    </div>
                </div>
            </div> -->
        </div>
        <!-- form modal input-->
        <modal name="inputCustomer" height="auto" width="900" :click-to-close="formClose">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h1 class="header-title"> Form Input Document </h1>

                            <form @submit.prevent="inputData">


                                <div v-for="(item, k) in dataNewDocument" :key="k" class="row mb-2">

                                    <div class="col-3">
                                        <div class="form-group">
                                            <input v-model="item.name" type="text" class="form-control form-control-sm"
                                                placeholder="Name*" required />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <b-form-file size="sm" type="file" ref="file" v-model="item.file"
                                            @change="uploadFile" />
                                    </div>
                                    <div class="col-3">
                                        <v-select :options="getGeneralDocument" item-text="name" item-value="id"
                                            v-model="item.document" :reduce="(option) => option.id" label="name">
                                        </v-select>
                                    </div>
                                    <div class="col-3">
                                        <div class="input-group">
                                            <b-form-input v-model="item.remark" type="remark"
                                                class="form-control form-control-sm" placeholder="Remark" required />
                                            <div class="input-group-append"
                                                style="background: #51c1ad; border-radius: 0px 3px 3px 0px">
                                                <a v-show="k || (!k && dataNewDocument.length > 1)"
                                                    href="javascript:void(0)" class="btn btn-danger btn-sm"
                                                    @click="removeRemarks(k)"> <i class="fas fa-trash"></i>
                                                </a> &nbsp;
                                                <a v-show="k === dataNewDocument.length - 1" href="javascript:void(0)"
                                                    class="btn btn-create btn-sm" @click="addRemarks"><i
                                                        class="fas fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <hr />
                                <div class="col-md-12 mt-1">
                                    <div class="float-right">
                                        <input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
                                        <button type="button" class="btn btn-cancel btn-sm"
                                            @click.prevent="hideFormInput">Cancel</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </modal>


        <b-modal id="inputConfirm" title="Edit Job Order" hide-footer>
            <div class="row justify-content-center">
                <div class="col-md-12">

                </div>
            </div>
        </b-modal>

        <!-- Table -->
        <div class="table-responsive">
            <vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
                :http-options="httpOptions" :append-params="moreParams" pagination-path
                class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable"
                @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed"
                @vuetable:pagination-data="onPaginationData">
                <template slot="doc" slot-scope="props">

                    <a href="javascript:void()" class="btn btn-sm btn-info"
                        @click="downloadFile(props.rowData.id, props.rowData.doc)" size="sm">Download</a>

                </template>

                <template slot="actions" slot-scope="props">
                    <div class="table-button-container">

                        <a v-can="'JOB_ORDER_DOCUMENT_DELETE'" href="javascript:void(0);" class="action-icon "
                            @click="onAction('delete-item', props.rowData, props.rowIndex)">
                            <i class="fas fa-trash-alt" style="color:red"></i>
                        </a>

                    </div>
                </template>
            </vuetable>
        </div>
        <!-- end table -->

        <!-- pagination -->
        <div class="row">
            <div class="col-sm-12 col-md-5">
                <div class="row no-gutters">
                    <!-- <div class="col-sm-12"> -->

                    <div class="col-lg-3">
                        <div class="form-group float-lg-left mx-1">
                            <select v-model="perPage" class="form-control form-control-sm"
                                @change="onChangeTableLength">
                                <option v-for="opt in lengthMenu" :key="opt" :value="opt">{{
                                opt
                                }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "></vuetable-pagination-info>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-7">
                <vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
                </vuetable-pagination>
            </div>
        </div>
        <!-- end pagination -->
    </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import {
    mapActions,
    mapState
} from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'

import axios from 'axios'

// import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS


var url = '/customer'
// var status = false

for (
    let i = 0; i < JSON.parse(localStorage.getItem('userPermissions')).length; i++
) {

    if (
        JSON.parse(localStorage.getItem('userPermissions'))[i] ===
        'CRM_ADMIN_ENABLE'
    ) {
        url = '/document?id_job_order=' + window.location.href.split("/").splice(0, 6)[5]
    }
}
// console.log(status, 'status')
// console.log(url, 'url')

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        // VueSimpleSuggest
    },
    props: {
        showActions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userName: '',
            moreParams: {},
            filterText: '',
            deleteData: {},
            search: '',
            httpOptions: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },
            isLoadingTable: false,
            // apiUrl: this.$store.state.rootApi + '/customer/list',
            apiUrl: this.$store.state.rootApi + url,
            urlCi: process.env.VUE_APP_CI_URL,
            items: [{
                text: 'Master',
                href: '/',
            },
            {
                text: 'ReservationRoom',
                href: '/ReservationRoom',
            },
            {
                text: 'List',
                active: true,
            },
            ],
            lengthMenu: [5, 10, 25, 50, 100],
            perPage: 5,
            fields: [
                {
                    name: 'no_document',
                    title: 'No',
                    width: '10px',

                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                    width: '300px',
                    sortField: 'name',
                },
                {
                    name: 'doc',
                    title: 'Attachment',
                    width: '200px',


                },
                {
                    name: 'remarks',
                    title: 'Remarks',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                    width: '200px',
                    sortField: 'remarks',

                },
                {
                    name: 'type',
                    title: 'Type',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                    width: '200px',
                    sortField: 'type',

                },
                {
                    name: 'actions',
                    title: 'Actions',
                    titleClass: 'text-left',
                    dataClass: 'text-left',
                    width: '170px',
                },
            ],
            sortOrder: [
                {
                    field: 'id',
                    direction: 'desc',
                },
            ],

            css: {
                pagination: {
                    activeClass: 'btn-primary',
                    pageClass: 'btn-sm btn-rounded',
                    linkClass: 'btn-sm',
                    icons: {
                        prev: 'fas fa-arrow-left',
                        next: 'fas fa-arrow-right',
                    },
                },
            },
            autoCompleteStyle: {
                vueSimpleSuggest: "position-relative",
                inputWrapper: "",
                defaultInput: "form-control form-control-sm",
                suggestions: "position-absolute list-group z-1000",
                suggestItem: "list-group-item"
            },
            user: [],
            dataHistoryCustomer: {},
            dataNewDocument: [{
                name: '',
                file: [],
                document: '',
                remark: '',
                select_file: null,
                id_job_order: window.location.href.split("/").splice(0, 6)[5]
            }],
            formClose: false,
            remarks: [{
                value: ''
            }],
            komentar: '',
            selectFile: null,
            status: null,
            reason: null,
            idReason: null,
            isiReason: null,
            getGeneralDocument: [],
            boxTwo: '',
            getList: [
                // {
                // 	id: 1,
                // 	name: 'Vue.js'
                // },
                // {
                // 	id: 2,
                // 	name: 'React.js'
                // },
                // {
                // 	id: 3,
                // 	name: 'Angular.js'
                // }
            ],
            listStatus: [{
                id: 0,
                name: 'Leads',
            },
            {
                id: 1,
                name: 'Visit',
            },
            {
                id: 2,
                name: 'Approved',
            },
            {
                id: 3,
                // name: 'Closing'
                name: 'Prospect',
            },
            {
                id: 4,
                // name: 'Closing'
                name: 'Reject',
            },
            ],
            isLoading: false,
            fileUpload: null,
            historyStatus: {
                height: 150,
            },
            plugins: ['code'],
            toolbars: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
        }
    },
    computed: {
        ...mapState('customer', ['dataCustomer', 'listCustomer']),
        ...mapState('customerHistory', ['dataCustomerHistory']),
        ...mapState('user', ['dataUser']),
        ...mapState('masterReason', ['listReason', 'dataReason']),

        resultQuery() {
            if (this.search) {
                return this.listCustomer.filter(item => {
                    return this.search
                        .toLowerCase()
                        .split(" ")
                        .every(v => item.name.toLowerCase().includes(v));
                });
            } else {
                return this.listCustomer;
            }
        },

    },

    mounted() {
        this.getListReason()
        this.userName = localStorage.getItem('name')
        let idJoborder = window.location.href.split("/").splice(0, 6)[5];
        this.getDocumetSetGeneral(idJoborder)

    },
    methods: {
        ...mapActions('customer', [
            'deleteCustomer',
            'getCustomer',
            'updateCustomer',
            'saveCustomer',
            'getListCustomer'
        ]),
        ...mapActions('customerHistory', [
            'saveCustomerHistory',
            'getCustomerHistory',
        ]),
        ...mapActions('user', ['getUsername']),
        ...mapActions('masterReason', ['getListReason', 'getReason']),
        ...mapActions('jobOrderDocument', ['getlistDocument', 'saveDocument', 'deleteDocument']),
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD  MMM YYYY, h:mm:ss a')
            }
        },
        format_date2(value) {
            if (value) {
                return moment(String(value)).format('DD  MMM YYYY')
            }
        },

        getDocumetSetGeneral(id) {
            try {
                this.getlistDocument(id)
                    .then((res) => {
                        this.getGeneralDocument = res
                        console.log(res, 'no joborder');

                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },

        detailLog: function (rowData, rowIndex) {
            console.log(rowData.id)
            this.$modal.show('logCustomer')
            this.getCustomer(rowData.id)
                .then((res) => {
                    console.log(this.dataCustomer)
                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert()
                })
            this.getCustomerHistory(rowData.id)
                .then((res) => {
                    console.log(res, 'respon customer history')
                    for (let i = 0; i < this.dataCustomerHistory.length; i++) {
                        this.getReason(this.dataCustomerHistory[i].id_reason)
                            .then((p) => {
                                this.isiReason = this.dataReason.reason
                            })
                            .catch((err) => {
                                console.log(err)
                            })

                        // this.getUsername(this.dataCustomerHistory[i].created_by)
                        // 	.then((resUser) => {
                        // 		var usernameUser = this.dataUser.username_user
                        // 		var username = usernameUser.match(/[a-zA-Z]+/g)
                        // 		this.dataCustomerHistory[i].created_by = username[0]
                        // 		console.log(username[0], 'username')
                        // 	})
                        // 	.catch((err) => {
                        // 		console.log(err)
                        // 	})
                        // this.dataCustomerHistory[i].created_by = 
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert()
                })
        },
        editShow(rowData, rowIndex) {
            this.$modal.show('editCustomer')
            this.getCustomer(rowData.id)
                .then((res) => { })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert()
                })
        },
        showComment(rowData, rowIndex) {
            this.$modal.show('commentCustomer')
            this.getCustomer(rowData.id)
                .then((res) => {

                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert()
                })
        },
        getData(rowData, rowIndex) {
            this.$modal.show('scheduleCustomer')
            this.getCustomer(rowData.id)
                .then((res) => {
                    for (let i = 0; i < this.listStatus.length; i++) {
                        if (i === this.dataCustomer.status) {
                            this.status = this.listStatus[i].name
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert()
                })
        },
        test() {
            axios.get(url).then((response) => {
                // console.log(response.data, 'data customer')
                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    this.getList.push({
                        id: element.id,
                        name: element.name + ' *customer already exists'
                    })
                }

            }, () => {
                console.log("gagal")
            });
        },
        getCustomerList() {

            return this.getList

        },

        doFilter() {
            this.onFilterSet(this.filterText)
        },
        /**
         * Table Length Change
         *
         * @return  {[type]}  [return description]
         */
        onChangeTableLength() {
            // this.$refs.vuetable.reload()
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
        /**
         * Pagination page change
         */
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        /**
         * Pagination info and pagination show
         */
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },
        /**
         * [onFilterSet description]
         *
         * @param   {[type]}  filterText  [filterText description]
         *
         * @return  {[type]}              [return description]
         */
        onFilterSet(filterText) {
            this.moreParams = {
                filter: filterText,
            }
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
        /**
         * Show Table Loading
         *
         * @return  {[type]}  [return description]
         */
        loadingTable() {
            this.isLoadingTable = true
        },

        /**
         * Hide Loading if Success
         *
         * @return  {[type]}  [return description]
         */
        loadTableSuccess() {
            this.isLoadingTable = false
        },
        /**
         * Hide Loading if Failed
         *
         * @return  {[type]}  [return description]
         */
        loadTableFailed(error) {
            console.log(error)

            this.isLoadingTable = false
            this.$swal({
                title: 'Terjadi Kesalahan',
                type: 'error',
                timer: '2000',
                toast: true,
                position: 'bottom-end',
            })
        },
        showFormInput() {
            this.$modal.show('inputCustomer')
            this.test()
        },
        hideFormInput() {
            this.isLoadingTable = false
            this.$modal.hide('inputCustomer')
        },



        hideFormSchedule() {
            this.isLoadingTable = false
            this.$modal.hide('scheduleCustomer')
            this.dataCustomer = {}
            this.$refs.vuetable.refresh()
        },
        onAction(action, rowData, rowIndex) {
            // if (action === 'edit-item') {
            //     this.$modal.show('scheduleCustomer')
            //     this.getCustomer(rowData.id)
            //         .then((res) => {
            //             this.dataCustomer = res
            //             // console.log('isi dataCustomer.status', this.dataCustomer.status)
            //             // if (this.dataCustomer.status === 0) {
            //             // 	this.dataCustomer.status = 1
            //             // } else if (this.dataCustomer.status === 1) {
            //             // 	this.dataCustomer.status = 2
            //             // }
            //             // console.log(this.dataCustomer)
            //         })
            //         .catch((err) => {
            //             console.log(err)
            //             this.errorAlert()
            //         })
            // } else if (action === 'view-item') {
            //     this.$router.push({
            //         name: 'ContactManagement View',
            //         params: {
            //             id: rowData.id,
            //         },
            //     })
            // } else if (action === 'delete-item') {
            this.showConfirmDelete(rowData.id)
            // }
        },

        showConfirmDelete(id) {
            this.deleteData.id = id
            // this.$bvModal.show('inputConfirm')
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    this.boxTwo = value
                    if (this.boxTwo === true) {
                        this.onDelete()
                    }
                })

            // this.showFormConfirm();
            // let self = this
            // Swal.fire({
            //     title: 'Are you sure?',
            //     text: 'You will not be able to recover this data!',
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: 'rgb(221, 107, 85)',
            //     cancelButtonColor: '#C1C1C1',
            //     confirmButtonText: 'Yes, delete it!',
            //     customClass: {
            //         container: 'my-swal'
            //     }
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
            //         self.onDelete()
            //     }
            // })
        },
        /**
         * Delete Brand
         *
         * @return  {[type]}  [return description]
         */
        onDelete() {
            // this.isLoadingTable = true
            this.deleteDocument(this.deleteData.id)
                .then((res) => {
                    this.$refs.vuetable.refresh()
                    this.successAlert()
                })
                .catch((err) => {
                    this.errorAlert()
                    console.log(err.response.message)
                })
        },


        uploadFile(event) {
            let files = event.target.files[0];

            console.log(files)
            return files;

        },
        inputData() {

            const formData = new FormData();

            this.dataNewDocument.forEach((element, key) => {
                formData.append('file[]', element.file)
                formData.append('select_file[]', element.file.name)
                formData.append('name[]', element.name)
                formData.append('remark[]', element.remark)
                formData.append('document[]', element.document)
                formData.append('id_job_order[]', window.location.href.split("/").splice(0, 6)[5])
            });

            axios.post(this.$store.state.rootApi + "/document", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'successfully',
                    })

                    this.dataNewDocument = [{
                        name: '',
                        file: [],
                        document: '',
                        remark: '',
                        select_file: null,
                        id_job_order: window.location.href.split("/").splice(0, 6)[5]
                    }]
                    this.$modal.hide('inputCustomer')
                    // console.log(res)
                    this.$refs.vuetable.refresh()

                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert('Failed save data')
                })

        },

        addRemarks() {
            if (!this.dataNewDocument) {
                return
            }
            this.dataNewDocument.push({
                name: '',
                file: [],
                document: '',
                remark: '',
                select_file: null,
                id_job_order: window.location.href.split("/").splice(0, 6)[5]
            });
        },
        removeRemarks(x) {
            console.log(x, 'index removeremarks')
            this.dataNewDocument.splice(x, 1)
            this.saveRemarks()
        },
        changeSelected(options) {
            let opt = options
            let num = this.formEdit.parent_id[options]
            this.$delete(this.formEdit.parent_id, options)
            this.formEdit.parent_id[opt] = num
        },
        downloadFile(id) {
            url = `${this.$store.state.rootApi}/document/download/` + id
            axios.get(url).then((response) => {
                this.download(response.data.uri, response.data.filename);
            }, () => {
                console.log("gagal")
            });
        },
        download(url, filename) {
            const link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = url;
            // link.download = fileName;
            link.setAttribute("download", filename);
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        },
        showFormConfirm() {
        },
    },
}

</script>
<style>
.my-swal {
    z-index: X;
}
</style>
