<template>
    <div>
        <div id="">
            <div class="row">
                <div class="col-12">
                    <div class="head">
                        <div class="row">
                            <div class="col-6 text-left">
                                <div class="row">
                                    <div class="col-3">
                                        <img src="../../../../src/assets/images/truck.png" alt="" class="image-truck">
                                    </div>
                                    <div class="col-9">
                                        <b>Data Loading & Unloading</b><br>
                                        Add your new transaction
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn btn-new-transaction mr-4" @click="onAction('addDistribution')" style="
                                margin-top: 1rem;
                                "><i class="fas fa-plus"></i> New Transaction</button>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                        <div class="">
                            <!-- Modal -->
                            <vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl"
                                :api-mode="true" :http-options="httpOptions" :append-params="moreParams" pagination-path
                                class="table table-centered table-striped" data-path="data"
                                detail-row-component="my-detail-row" @vuetable:loading="loadingTable"
                                @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed"
                                @vuetable:pagination-data="onPaginationData" @vuetable:cell-clicked="onCellClicked"
                                :sort-order="sortOrder">

                                <div slot="chill" slot-scope="props">
                                    <!-- v-if="props.rowData.child_job_orders_count > 0" -->
                                    <a href="javascript:void(0)" class="" style="color: #ff8d00"
                                        @click="onCellClicked(props.rowData)"
                                        v-show="props.rowData.backup_truck.length > 0">
                                        <i class="fas fa-angle-down"></i></a>
                                </div>

                                <div slot="action" slot-scope="props" v-show="props.rowData.backup_truck.length < 1">
                                    <button href="javascript:void(0)" class="btn"
                                        style="background: #009FC2;  width: 35px; height: 35px; margin-top: -15px; margin-bottom: -15px; margin-right: -10px;"
                                        v-show="props.rowData.id_aol === null || props.rowData.id_aol === ''"
                                        @click.prevent="submitToAol(props.rowData.id)">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            style=" color: #F9A707; position: absolute; margin-left: -7px; margin-top: -7px; "
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.49998 0L3.36206 5.68966H6.20687V8.79311H8.79309V5.68966H11.6379L7.49998 0Z"
                                                fill="white" />
                                            <path
                                                d="M8.27582 9.82758H6.72408C6.43961 9.82758 6.20685 10.0603 6.20685 10.3448C6.20685 10.6293 6.43961 10.862 6.72408 10.862H8.27582C8.5603 10.862 8.79306 10.6293 8.79306 10.3448C8.79306 10.0603 8.5603 9.82758 8.27582 9.82758Z"
                                                fill="white" />
                                            <path
                                                d="M8.27582 11.8965H6.72408C6.43961 11.8965 6.20685 12.1293 6.20685 12.4138C6.20685 12.6983 6.43961 12.931 6.72408 12.931H8.27582C8.5603 12.931 8.79306 12.6983 8.79306 12.4138C8.79306 12.1293 8.5603 11.8965 8.27582 11.8965Z"
                                                fill="white" />
                                            <path
                                                d="M8.27582 13.9655H6.72408C6.43961 13.9655 6.20685 14.1983 6.20685 14.4828C6.20685 14.7672 6.43961 15 6.72408 15H8.27582C8.5603 15 8.79306 14.7672 8.79306 14.4828C8.79306 14.1983 8.5603 13.9655 8.27582 13.9655Z"
                                                fill="white" />
                                        </svg>

                                    </button>

                                    <b-dropdown size="md" variant="link" toggle-class="text-decoration-none" no-caret
                                        style=" margin-top: -15px; margin-bottom: -15px; margin-right: -10px;
                                                    ">
                                        <template slot="button-content">
                                            <button class="btn"
                                                style=" background-color: #CFFDF5; width: 35px; height: 35px;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16"
                                                    style=" position: absolute; right: 22px; bottom: 15px;">
                                                    <path
                                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                </svg>
                                            </button>
                                        </template>
                                        <b-dropdown-item href="#" @click.prevent="toDetailTransaction(props)">
                                            <i class="fa fa-search"></i> Detail transaction
                                        </b-dropdown-item>
                                        <b-dropdown-item href="#" @click.prevent="onEditTransaction(props)"><i
                                                class="fa fa-edit"></i> Edit Transaction
                                        </b-dropdown-item>

                                        <b-dropdown-item href="#" @click.prevent="showConfirmDelete(props.rowData.id)">
                                            <i class="fa fa-trash-alt"></i> Delete
                                        </b-dropdown-item>
                                    </b-dropdown>

                                    <button class="btn" @click.prevent="showBackupModal(props)"
                                        style=" background-color: #FFF2D9; width: 35px; height: 35px; margin-top: -15px; margin-bottom: -15px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"
                                            style=" color: #F9A707; position: absolute; margin-left: -7px; margin-top: -7px; ">
                                            <path fill-rule="evenodd"
                                                d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                                        </svg>
                                    </button>

                                    <!-- jika ada suwap mobil  -->
                                    <a class="text-dark" style="margin-left: -10px; ">
                                        <i class="fa fa-question-circle"
                                            style="font-size: 20px; margin-left: 1em; color:transparent ;"></i>
                                    </a>
                                </div>
                            </vuetable>
                            <!-- pagination -->
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="row no-gutters">
                                        <div class="col-lg-3">
                                            <div class="form-group float-lg-left">
                                                <select v-model="perPage" class="form-control form-control-sm"
                                                    @change="onChangeTableLength">
                                                    <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                                                        {{ opt }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              ">
                                            </vuetable-pagination-info>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-7">
                                    <vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
                                    </vuetable-pagination>
                                </div>
                            </div>
                            <!-- end pagination -->

                            <!-- modal backup trucking -->
                            <b-modal id="modal-backup-trucking" size="lg" title="Add Backup Trucking" hide-footer>
                                <!-- {{selectedItem.type1.no}} -->
                                <div class="row">
                                    <div class="col-4">
                                        <input type="hidden" id="id_parent" v-modal="form.id_job_distribution">
                                        <label>Truck Type
                                            <span style="color: red">*</span>
                                        </label>
                                        <v-select :options="truckType" item-text="name" item-value="id"
                                            v-model="form.truck_type" :reduce="(option) => option.id" label="name"
                                            :class="formRequired.truck_type">
                                        </v-select>

                                    </div>
                                    <div class="col-4">
                                        <label>Choose Truck <span class="text-danger">*</span></label>
                                        <v-select :options="chooseTruck" item-text="name" item-value="id"
                                            v-model="form.choose_truck" :reduce="(option) => option.id"
                                            :class="formRequired.choose_truck" label="name">
                                        </v-select>
                                    </div>
                                    <div class="col-4">
                                        <label for="">Seal Number <span class="text-danger">*</span></label>
                                        <input v-model="form.seal_number" :class="formRequired.seal_number" type="text"
                                            name="" id="" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-4">
                                        <label>Driver Name <span class="text-danger">*</span></label>
                                        <v-select :options="driverName" item-text="name" item-value="id"
                                            v-model="form.driver_name" :reduce="(option) => option.id"
                                            :class="formRequired.driver_name" label="name">
                                        </v-select>
                                    </div>
                                    <div class="col-4">
                                        <label for="">Upload Image</label>
                                        <b-form-file type="file" ref="file" v-model="form.file"
                                            :class="formRequired.file" @change="uploadFile" />
                                    </div>
                                    <div class="col-4">
                                        <label for="">Note</label>
                                        <input v-model="form.note" :class="formRequired.note" type="text" name="" id=""
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6 text-left">
                                        <button style="
                                        background-color: #C4C4C4;
                                        color: white;
                                        width: 100px;
                                        height: 40px;
                                        border: none;
                                        border-radius: 3px;
                                        " @click.prevent="$bvModal.hide('modal-backup-trucking')">Cancel</button>
                                    </div>
                                    <div class="col-6 text-right">
                                        <button style="
                                        background-color: #51C1AD;
                                        color: white;
                                        width: 100px;
                                        height: 40px;
                                        border: none;
                                        border-radius: 3px;
                                        " @click.prevent="onSaveBackupTruck">Save</button>
                                    </div>
                                </div>
                            </b-modal>
                            <!-- end modal backup trucking -->

                            <!-- modal delete trucking -->
                            <b-modal id="modal-delete-transaction" hide-header hide-footer>
                                <div class="text-center" style="margin: 2rem;">
                                    <p class="mx-4">Are you sure you want to delete it ?</p>
                                    <br>

                                    <button style="
                                    background-color: #C4C4C4;
                                    color: white;
                                    width: 100px;
                                    height: 40px;
                                    border: none;
                                    border-radius: 3px;
                                    margin-right: 5px;
                                    " @click.prevent="$bvModal.hide('modal-delete-transaction')">No</button>

                                    <button style="
                                    background-color: #51C1AD;
                                    color: white;
                                    width: 100px;
                                    height: 40px;
                                    border: none;
                                    border-radius: 3px;
                                    margin-left: 5px
                                    " @click.prevent="$bvModal.hide('modal-delete-transaction')">Yes</button>
                                </div>
                            </b-modal>
                            <!-- end modal delete trucking -->


                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { mapActions } from 'vuex'

import Vue from 'vue'
import DetailRow from './RowDetails'

import Swal from 'sweetalert2'
import axios from 'axios'
var url = '/distribution'

Vue.component('my-detail-row', DetailRow)
export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
    },
    props: {},
    data() {
        return {
            isLoadingTable: false,
            httpOptions: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },
            apiUrl: this.$store.state.rootApi + url + "?id_job_order=" + window.location.href.split("/").splice(0, 6)[5],
            lengthMenu: [10, 25, 50, 100],
            perPage: 10,
            fields: [
                {
                    name: 'chill',
                    title: '#',
                    // sortField: 'id',
                },
                {
                    name: 'no',
                    title: 'No',
                    // sortField: 'id',
                },
                {
                    name: 'no_transaction',
                    title: 'No Transaction',
                    width: "13%",
                    sortField: 'number_delivery',
                },
                {
                    name: 'type_truck',
                    title: 'Type Truck',
                    sortField: 'truck_type',
                },
                {
                    name: 'no_plat',
                    title: 'No Plat',
                    sortField: 'choose_truck',

                },
                {
                    name: 'driver',
                    title: 'Driver',
                    sortField: 'choose_truck',
                },
                {
                    name: 'loading',
                    title: 'Loading',
                    sortField: 'job_order_distribution_loading_count'
                },
                {
                    name: 'unloading',
                    title: 'Unloading',
                    sortField: 'job_order_distribution_unloading_count'

                },
                {
                    name: 'measurment',
                    title: 'Measurment',
                    sortField: 'measurment'

                },
                {
                    name: 'weight',
                    title: 'Weight',
                    sortField: 'weight'

                },
                {
                    name: 'item',
                    title: 'Item',
                    sortField: 'job_order_distribution_unloading_item_count'

                },
                {
                    name: 'sub_total',
                    title: 'SubTotal',
                    sortField: 'sub_total_sort'

                },

                {
                    name: 'action',
                    title: 'Action',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                    width: "14%",
                },
            ],
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            css: {
                pagination: {
                    activeClass: 'btn-primary',
                    pageClass: ' btn-sm btn-rounded',
                    linkClass: ' btn-sm',
                    icons: {
                        prev: 'fas fa-arrow-left',
                        next: 'fas fa-arrow-right',
                    },
                },
            },
            rowData: [],
            isBackupTruck: false,
            selectedItem: '',
            truckType: [],
            chooseTruck: [],
            driverName: [],
            form: {
                truck_type: '',
                choose_truck: '',
                seal_number: '',
                driver_name: '',
                file: '',
                note: '',
                id_job_distribution: ''
            },
            formRequired: {
                truck_type: false,
                choose_truck: false,
                seal_number: false,
                driver_name: false,
                file: false,
                note: false,
            },
        }
    },
    mounted() {
        this.listTruckType()
        this.listChooseTruck()
        this.listDriverName()
    },
    methods: {
        ...mapActions('jobOrderDistribution', [
            'deleteJobOrderDistribution',
            'submitAOL',
            'getTypeOptions',
            'saveDistributionBackupTruck'
        ]),
        loadingTable() {
            this.isLoadingTable = true
        },

        loadTableSuccess() {
            this.isLoadingTable = false
        },

        loadTableFailed(error) {
            console.log(error)

            this.isLoadingTable = false
            this.$swal({
                title: 'Terjadi Kesalahan',
                type: 'error',
                timer: '2000',
                toast: true,
                position: 'bottom-end',
            })
            // }
        },

        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },

        onAction(action) {
            if (action === 'addDistribution') {
                this.$router.push({
                    name: 'JobOrder Add Distribution',
                    params: {
                        id: window.location.href.split("/").splice(0, 6)[5]
                    },
                })
            }
        },

        toDetailTransaction(data) {
            this.$router.push({
                name: 'JobOrder Detail Distribution', params: {
                    id: data.rowData.id
                },
            })
        },
        onEditTransaction(data) {
            // console.log(data.rowData)
            this.$router.push({
                name: 'JobOrder Edit Distribution',
                params: {
                    id: data.rowData.id
                },
            })
        },
        listTruckType() {
            try {
                this.getTypeOptions('data_type_truck')
                    .then((res) => {
                        this.truckType = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listChooseTruck() {
            try {
                this.getTypeOptions('data_truck')
                    .then((res) => {
                        this.chooseTruck = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listDriverName() {
            try {
                this.getTypeOptions('data_driver_name')
                    .then((res) => {
                        this.driverName = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        uploadFile(event) {
            let files = event.target.files[0];

            console.log(files)
            return files;

        },

        onCellClicked(data) {
            this.$refs.vuetable.toggleDetailRow(data.id)
        },
        onPrintTransaction(data) {
            // console.log(data.tipe)
            // window.open(this.$route.params.id + '/delivery-letter','_blank')
            // const router = this.$router.resolve({
            //     name: 'JobOrder Delivery Letter',
            //     params: {tipe : data.tipe}
            // })
            // window.open(router.href, '_blank')

            // const router = this.$router.resolve({
            //     name : 'JobOrder Delivery Letter'
            // })
            // window.open(router.href, '_blank')

            axios({
                url: this.$store.state.rootApi + '/delivery-letter-pdf',
                method: 'GET',
                responseType: 'blob',
                params: {
                    tipe: data.tipe
                }
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                var fURL = document.createElement('a')

                fURL.href = fileURL
                fURL.setAttribute('download', 'delivery-letter.pdf')
                document.body.appendChild(fURL)

                fURL.click()
            })
        },

        showBackupModal(props) {
            this.form.id_job_distribution = props.rowData.id
            this.$bvModal.show('modal-backup-trucking')
        },

        onSaveBackupTruck() {

            if (this.form.truck_type === "") {
                this.formRequired.truck_type = 'style-chooser'
            } else {
                this.formRequired.truck_type = false
            }

            if (this.form.choose_truck === '') {
                this.formRequired.choose_truck = 'style-chooser'
            } else {
                this.formRequired.choose_truck = false
            }

            if (this.form.seal_number === '') {
                this.formRequired.seal_number = 'is-invalid'
            } else {
                this.formRequired.seal_number = false
            }

            if (this.form.driver_name === '') {
                this.formRequired.driver_name = 'style-chooser'
            } else {
                this.formRequired.driver_name = false
            }

            if (this.form.file === '') {
                this.formRequired.file = 'is-invalid'
            } else {
                this.formRequired.file = false
            }

            if (this.form.note === '') {
                this.formRequired.note = 'is-invalid'
            } else {
                this.formRequired.note = false
            }


            if (
                // Validasi Truck
                this.form.truck_type !== '' &&
                this.form.choose_truck !== '' &&
                this.form.seal_number !== '' &&
                this.form.driver_name !== '' &&
                this.form.file !== '' &&
                this.form.note !== ''
            ) {
                console.log(this.form)
                const formData = new FormData();

                // this.form.forEach(element => {
                formData.append('truck_type', this.form.truck_type)
                formData.append('choose_truck', this.form.choose_truck)
                formData.append('seal_number', this.form.seal_number)
                formData.append('driver_name', this.form.driver_name)
                formData.append('file', this.form.file)
                formData.append('note', this.form.note)
                formData.append('id_job_distribution', this.form.id_job_distribution)
                // });


                axios.post(this.$store.state.rootApi + "/distribution/backup-truck", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        console.log(res, 'upload')

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                        })
                        Toast.fire({
                            icon: 'success',
                            title: res.message,
                        })
                        this.$refs.vuetable.refresh()

                        // window.history.back();
                    })
                    .catch((err) => {

                        if (err.response.status === 422) {
                            let title = []
                            for (let field of Object.values(err.response.data.message)) {
                                title.push(field)
                            }
                            Swal.fire({
                                title: 'Error',
                                html: title.join("<br/><br/>"),
                                icon: 'error',
                                confirmButtonColor: 'rgb(221, 107, 85)',
                                confirmButtonText: 'Ok',
                            })
                        } else {
                            this.errorAlert('Failed save data')
                        }
                    })
                this.$bvModal.hide('modal-backup-trucking')
            } else {
                this.$bvModal.show('modal-backup-trucking')

            }


        },
        showConfirmDelete(id) {
            let self = this
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this data!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'rgb(221, 107, 85)',
                cancelButtonColor: '#C1C1C1',
                reverseButtons: true,
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    self.onDelete(id)
                    Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                }
            })
        }, onDelete(id) {
            this.deleteJobOrderDistribution(id)
                .then((res) => {
                    this.successAlert()
                    this.$refs.vuetable.refresh()
                })
                .catch((err) => {
                    this.errorAlert()
                    console.log(err.response.message)
                })
        },
        submitToAol(id) {

            this.submitAOL(id)
                .then((res) => {
                    console.log(res.data, 'datanya')
                    if (res.data === 1) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'successfully'
                        })
                        this.$refs.vuetable.refresh()
                    } else {
                        Swal.fire({
                            title: "Warning !",
                            text: res.data,
                            icon: 'warning',
                            confirmButtonColor: 'rgb(221, 107, 85)',
                            confirmButtonText: 'Ok'
                        })
                        this.$refs.vuetable.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err.response.data)
                    Swal.fire({
                        title: "Failed!",
                        text: err.data,
                        icon: 'warning',
                        confirmButtonColor: 'rgb(221, 107, 85)',
                        confirmButtonText: 'Ok'
                    })
                })
        },

    },
}
</script>

<style>
.head {
    margin: 0;
    background-color: #FFF2D9;
    border-radius: 5px;
    padding: 1em;
}

.btn-new-transaction {
    background-color: #F9A707;
}

.btn-new-transaction:hover {
    color: white;
}

.body {
    margin-top: 1rem;
}


.image-truck {
    /* position: absolute; */
    width: 65px;
    margin-left: 40px;
}


/*  */
/*  */
/*  */
.mainmenubtn {
    background-color: skyblue;
    color: white;
    border: none;
    cursor: pointer;
    padding: 20px;
    margin-top: 20px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    border-color: #f1556c;
}
</style>
